import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { Tooltip } from "react-tooltip";

export const FormLabelControlTextFull = ({
  label,
  placeholder,
  name,
  value,
  handleChangeForm,
  type,
  icon,
  tooltip,
  tooltipId,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-2">
      <Form.Label column sm="2" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}&nbsp;<span className="star">*</span>&nbsp;&nbsp;
        <a id={tooltipId} style={{ textAlign: "right" }}>
          <FontAwesomeIcon icon={icon} />
        </a>
        <Tooltip anchorSelect={`#${tooltipId}`} content={tooltip} />
      </Form.Label>
      <Col sm="10">
        <Form.Control
          name={name}
          value={value}
          onChange={handleChangeForm}
          required
          type={type}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlText = ({
  label,
  placeholder,
  name,
  value,
  handleChangeForm,
  type,
  icon,
  tooltip,
  tooltipId,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3">
      <Form.Label column sm="4" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}&nbsp;<span className="star">*</span>
        &nbsp;&nbsp;
        <a id={tooltipId} style={{ textAlign: "right" }}>
          <FontAwesomeIcon icon={icon || ""} />
        </a>
        <Tooltip anchorSelect={`#${tooltipId}`} content={tooltip} />{" "}
      </Form.Label>
      <Col sm="8">
        <Form.Control
          name={name}
          value={value}
          onChange={handleChangeForm}
          required
          type={type}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlTextFullNoIcon = ({
  label,
  placeholder,
  name,
  value,
  handleChangeForm,
  type,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-2">
      <Form.Label column sm="2" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}&nbsp;<span className="star">*</span>
      </Form.Label>
      <Col sm="10">
        <Form.Control
          name={name}
          value={value}
          onChange={handleChangeForm}
          required
          type={type}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlTextNoIcon = ({
  label,
  placeholder,
  name,
  value,
  handleChangeForm,
  type,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3">
      <Form.Label column sm="4" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}&nbsp;<span className="star">*</span>
      </Form.Label>
      <Col sm="8">
        <Form.Control
          name={name}
          value={value}
          onChange={handleChangeForm}
          required
          type={type}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelSelectFull = ({
  label,
  name,
  value,
  options,
  handleChange,
  isDisabled,
  placeholder,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3" style={{ textAlign: "left" }}>
      <Form.Label column sm="2" style={{ fontSize: "15px" }}>
        {label}&nbsp;<span className="star">*</span>
      </Form.Label>
      <Col sm="10" className="SelectOption">
        <Select
          name={name}
          value={value}
          options={options}
          onChange={(selectedOption) => handleChange(name, selectedOption)}
          isDisabled={isDisabled}
          placeholder="Please Select"
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelSelect = ({
  label,
  name,
  value,
  options,
  handleChange,
  isDisabled,
  placeholder,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3" style={{ textAlign: "left" }}>
      <Form.Label column sm="4" style={{ fontSize: "15px" }}>
        {label}&nbsp;<span className="star">*</span>
      </Form.Label>
      <Col sm="8" className="SelectOption">
        <Select
          name={name}
          value={value}
          options={options}
          onChange={(selectedOption) => handleChange(name, selectedOption)}
          isDisabled={isDisabled}
          placeholder="Please Select"
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlSalary = ({
  label,
  name,
  value,
  handleChangeForm,
  type,
  inputText,
  placeholder,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3" style={{ textAlign: "left" }}>
      <Form.Label column sm="4" style={{ fontSize: "15px" }}>
        {label}&nbsp;<span className="star">*</span>
      </Form.Label>
      <Col sm="8">
        <InputGroup>
          <InputGroup.Text>{inputText}</InputGroup.Text>
          <Form.Control
            name={name}
            value={value}
            onChange={handleChangeForm}
            required
            type={type}
            placeholder={placeholder}
          />
          <InputGroup.Text>.00</InputGroup.Text>
        </InputGroup>
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlAppendRight = ({
  label,
  name,
  value,
  handleChangeForm,
  type,
  inputText,
  placeholder,
  appendLabel,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3" style={{ textAlign: "left" }}>
      <Form.Label column sm="4" style={{ fontSize: "15px" }}>
        {label}&nbsp;<span className="star">*</span>
      </Form.Label>
      <Col sm="8">
        <InputGroup>
          <Form.Control
            name={name}
            value={value}
            onChange={handleChangeForm}
            required
            type={type}
            placeholder={placeholder}
          />
          <InputGroup.Text>{appendLabel}</InputGroup.Text>
        </InputGroup>
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlTextArea = ({
  label,
  placeholder,
  name,
  value,
  handleChangeForm,
  type,
  rows,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3">
      <Form.Label column sm="4" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}&nbsp;<span className="star">*</span>
        &nbsp;&nbsp;
      </Form.Label>
      <Col sm="8">
        <Form.Control
          name={name}
          value={value}
          onChange={handleChangeForm}
          required
          type={type}
          placeholder={placeholder}
          as="textarea"
          rows={rows}
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelCheckRadio = ({ label, name, value, onChange }) => {
  return (
    <Form.Group as={Row} className="px-2 py-3">
      <Form.Label column sm="4" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}&nbsp;<span className="star">*</span>
      </Form.Label>
      <Col sm="8">
        <div key="inline-radio" className="mb-3 formLabel">
          <Form.Check
            name={name}
            inline
            label="Yes"
            type="radio"
            value="Yes"
            checked={value === "Yes"}
            onChange={onChange}
          />
          <Form.Check
            inline
            name={name}
            label="No"
            type="radio"
            value="No"
            checked={value === "No"}
            onChange={onChange}
          />
        </div>
      </Col>
    </Form.Group>
  );
};

export const FormLabelDateSelector = ({
  label,
  monthName,
  monthValue,
  yearName,
  yearValue,
  monthOptions,
  onMonthChange,
  onYearChange,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3">
      <Form.Label column sm="4" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}&nbsp;<span className="star">*</span>
      </Form.Label>
      <Col md={4} sm={12}>
        <Select
          name={monthName}
          value={monthOptions.find((option) => option.value === monthValue)}
          options={monthOptions}
          onChange={onMonthChange}
          placeholder="Month"
        />
      </Col>
      <Col md={4} sm={12}>
        <Form.Control
          name={yearName}
          value={yearValue}
          onChange={onYearChange}
          placeholder="Year"
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelCheckBox = ({ label, name, value, onChange }) => {
  return (
    <Form.Group as={Row} className="px-2 py-3">
      <Col sm="12">
        <Form.Check
          name={name}
          label={label}
          value="Yes"
          checked={value === "agree"}
          onChange={onChange}
          style={{ textAlign: "justify" }}
          type="checkbox"
          required
        />
      </Col>
    </Form.Group>
  );
};
