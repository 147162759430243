import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../5.Services/apiServices";

const namespace = "SV Table List";

//Application List
export const getSVApplicationListPagination = createAsyncThunk(
  `${namespace}/getSVApplicationListPagination`,
  async ({ sv_id, page }, { rejectWithValue }) => {
    const api = "Pagination_GetSVApplication";
    const replace = {
      search: "sv_id",
      replace: sv_id, // Assuming 'sv_id' is a property in the 'body' object
    };
    const opt = {
      replace: replace,
      params: {
        pageNo: page,
        pageSize: 5,
      },
    };
    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.jobapplication?.date.split(",")[0];
        res.jobapplication.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSVApplicationListSearch = createAsyncThunk(
  `${namespace}/getSVApplicationListSearch`,
  async ({ sv_id, key }, { rejectWithValue }) => {
    const api = "Search_GetSVApplication";
    const replace = {
      search: "sv_id",
      replace: sv_id, // Assuming 'sv_id' is a property in the 'body' object
    };
    const opt = {
      replace: replace,
      params: {
        search: key,
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.jobapplication?.date.split(",")[0];
        res.jobapplication.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Assessment List
export const getSVInterviewListPagination = createAsyncThunk(
  `${namespace}/getSVInterviewListPagination`,
  async ({ sv_id, page }, { rejectWithValue }) => {
    const api = "Pagination_GetSVAssessment";
    const replace = {
      search: "sv_id",
      replace: sv_id, // Assuming 'sv_id' is a property in the 'body' object
    };
    const opt = {
      replace: replace,
      params: {
        pageNo: page,
        pageSize: 5,
      },
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getSVInterviewListSearch = createAsyncThunk(
  `${namespace}/getSVInterviewListSearch`,
  async ({ sv_id, key }, { rejectWithValue }) => {
    const api = "Search_GetSVAssessment";
    const replace = {
      search: "sv_id",
      replace: sv_id, // Assuming 'sv_id' is a property in the 'body' object
    };
    const opt = {
      replace: replace,
      params: {
        search: key,
      },
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  error: false,

  //Application List
  circulationBySvIdLists: [],
  currentSVApplicationPage: 1,
  totalCurrentSVApplicationPage: 0,
  searchCirculationBySvIdLists: [],
  searchKeyWordCirculationBySvIdLists: "",

  //Assessment List
  assessmentBySvIdLists: [],
  currentSVAssessmentPage: 1,
  totalCurrentSVAssessmentPage: 0,
  searchAssessmentBySvIdLists: [],
  searchKeyWordAssessmentBySvIdLists: "",
};

const SVTableListSlice = createSlice({
  name: "SV Table List",
  initialState,
  reducers: {
    //Application List
    updateCurrentSVApplicationPage(state, action) {
      return { ...state, currentSVApplicationPage: action.payload };
    },
    updateSearchSvApplicationList(state, action) {
      return { ...state, searchKeyWordCirculationBySvIdLists: action.payload };
    },
    //Assessment List
    updateCurrentSVAssessmentPage(state, action) {
      return { ...state, currentSVAssessmentPage: action.payload };
    },
    updateSearchSvAssessmentList(state, action) {
      return { ...state, searchKeyWordAssessmentBySvIdLists: action.payload };
    },
  },
  extraReducers: {
    //Application List
    [getSVApplicationListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getSVApplicationListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.circulationBySvIdLists = action.payload.data;
      state.totalCurrentSVApplicationPage = action.payload.totalPages;
    },
    [getSVApplicationListPagination.rejected]: (state, action) => {
      state.error = true;
      console.error(action.payload);
    },
    [getSVApplicationListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getSVApplicationListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchCirculationBySvIdLists = action.payload.data;
    },
    [getSVApplicationListSearch.rejected]: (state, action) => {
      state.error = true;
      console.error(action.payload);
    },
    //Assessment List
    [getSVInterviewListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getSVInterviewListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentBySvIdLists = action.payload.data;
      state.totalCurrentSVAssessmentPage = action.payload.totalPages;
    },
    [getSVInterviewListPagination.rejected]: (state, action) => {
      state.error = true;
      console.error(action.payload);
    },
    [getSVInterviewListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getSVInterviewListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchAssessmentBySvIdLists = action.payload.data;
    },
    [getSVInterviewListSearch.rejected]: (state, action) => {
      state.error = true;
      console.error(action.payload);
    },
  },
});
export const {
  updateCurrentSVApplicationPage,
  updateSearchSvApplicationList,
  updateCurrentSVAssessmentPage,
  updateSearchSvAssessmentList,
} = SVTableListSlice.actions;
export default SVTableListSlice.reducer;
