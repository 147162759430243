export const GenderList = [
  { label: "MALE", value: "Male" },
  { label: "FEMALE", value: "Female" },
];

export const HighestQualificationList = [
  { label: "SPM", value: "SPM" },
  {
    label: "STPM & EQUIVALENT",
    value: "STPM/Matriculation/Foundation/STAM",
  },
  { label: "CERTIFICATES", value: "Certificates" },
  { label: "DIPLOMA", value: "Diploma" },
  { label: "DEGREE", value: "Degree" },
  { label: "MASTER", value: "Master" },
  { label: "PHD", value: "PHD" },
  { label: "OTHERS", value: "Others" },
];

export const CurrentLocationList = [
  { label: "KUCHING", value: "Kuching" },
  { label: "SRI AMAN", value: "Sri Aman" },
  { label: "SIBU", value: "Sibu" },
  { label: "MIRI", value: "Miri" },
  { label: "LIMBANG", value: "Limbang" },
  { label: "SARIKEI", value: "Sarikei" },
  { label: "KAPIT", value: "Kapit" },
  { label: "SAMARAHAN", value: "Samarahan" },
  { label: "BINTULU", value: "Bintulu" },
  { label: "BETONG", value: "Betong" },
  { label: "MUKAH", value: "Mukah" },
  { label: "SERIAN", value: "Serian" },
  { label: "SABAH", value: "Sabah" },
  { label: "LABUAN", value: "Labuan" },
  { label: "WEST MALAYSIA", value: "West Malaysia" },
  { label: "OTHERS", value: "Others" },
];

export const FieldOfStudyList = [
  { label: "CIVIL ENGINEERING", value: "Civil Engineering" },
  { label: "QUANTITY SURVEYOR", value: "Quantity Surveyor" },
  { label: "SAFETY & HEALTH", value: "Safety & Health" },
  { label: "ACCOUNTING", value: "Accounting" },
  { label: "MARKETING", value: "Marketing" },
  { label: "BUSINESS ADMINISTRATION", value: "Business Administration" },
  { label: "HUMAN RESOURCES", value: "Human Resources" },
  { label: "SOFTWARE ENGINEERING", value: "Software Engineering" },
  { label: "OTHER", value: "Other" },
];

export const PositionList = [
  { label: "NETWORK ENGINEER - KUCHING", value: "Network Engineer - Kuching" },
  { label: "HR OFFICER - KUCHING", value: "Hr Officer - Kuching" },
  {
    label: "SAFETY AND HEALTH OFFICER - SARAWAK",
    value: "Safety And Health Officer - Sarawak",
  },
];

export const AdvertisementSourceList = [
  { label: "HSL WEBSITE", value: "HSL website" },
  { label: "JOBSTOCK", value: "Jobstock" },
  { label: "JOBSTREET", value: "Jobstreet" },
  { label: "LINKEDIN", value: "LinkedIn" },
  { label: "FACEBOOK", value: "FB" },
  { label: "INSTAGRAM", value: "Instagram" },
  { label: "NEWSPAPER", value: "Newspaper" },
  { label: "STAFF REFERRAL", value: "Staff Referral" },
  { label: "OTHER", value: "Other" },
];

export const ReligionList = [
  { label: "NONE", value: "None" },
  { label: "ISLAM", value: "Islam" },
  { label: "BUDDHISM", value: "Buddhism" },
  { label: "TAOISM", value: "Taoism" },
  { label: "CHRISTIAN", value: "Christian" },
  { label: "HINDU", value: "Hindu" },
  { label: "OTHERS", value: "Others" },
];

export const RaceList = [
  { label: "CHINESE", value: "Chinese" },
  { label: "MALAY", value: "Malay" },
  { label: "INDIAN", value: "Indian" },
  { label: "DAYAK", value: "Dayak" },
  { label: "OTHERS", value: "Others" },
];

export const DialectList = [
  { branch: "Chinese", label: "HOKKIEN", value: "Hokkien" },
  { branch: "Chinese", label: "CANTONESE", value: "Cantonese" },
  { branch: "Chinese", label: "HAKKA", value: "Hakka" },
  { branch: "Chinese", label: "TEOCHEW", value: "Teochew" },
  { branch: "Chinese", label: "HAINAN", value: "Hainan" },
  { branch: "Chinese", label: "FOOCHOW", value: "Foochow" },
  { branch: "Chinese", label: "OTHERS", value: "Others" },

  { branch: "Malay", label: "MELAYU SARAWAK", value: "Melayu Sarawak" },
  { branch: "Malay", label: "OTHERS", value: "Others" },

  { branch: "Indian", label: "TAMIL", value: "Tamil" },
  { branch: "Indian", label: "OTHERS", value: "Others" },

  { branch: "Dayak", label: "IBAN", value: "Iban" },
  { branch: "Dayak", label: "BIDAYUH", value: "Bidayuh" },
  { branch: "Dayak", label: "ORANG ULU", value: "Orang Ulu" },
  { branch: "Dayak", label: "OTHERS", value: "Others" },

  { branch: "Others", label: "OTHERS", value: "Others" },
];

export const YesNoList = [
  { label: "YES", value: "Yes" },
  { label: "NO", value: "No" },
];

export const MaritalStatusList = [
  { label: "SINGLE", value: "Single" },
  { label: "MARRIED", value: "Married" },
  { label: "DIVORCED", value: "Divorced" },
  { label: "WIDOWED", value: "Widowed" },
];

export const RateSkillList = [
  { label: "GOOD", value: "Good" },
  { label: "FAIR", value: "Fair" },
  { label: "LITTLE", value: "Little" },
  { label: "NONE", value: "None" },
];

export const MonthList = [
  { label: "JANUARY", value: "January" },
  { label: "FEBRUARY", value: "February" },
  { label: "MARCH", value: "March" },
  { label: "APRIL", value: "April" },
  { label: "MAY", value: "May" },
  { label: "JUNE", value: "June" },
  { label: "JULY", value: "July" },
  { label: "AUGUST", value: "August" },
  { label: "SEPTEMBER", value: "September" },
  { label: "OCTOBER", value: "October" },
  { label: "NOVEMBER", value: "November" },
  { label: "DECEMBER", value: "December" },
];

export const PreActionList = [
  { label: "Review Only", value: 100, isRed: true },
  { label: "Review Recommend", value: 200, isRed: true },
  { label: "Archive", value: 30 },
  { label: "KIV", value: 50 },
  // { label: "Internship", value: 10 },
  { label: "Duplicate", value: 1 },
];

export const FinalStatusList = [
  { label: "Hire", value: "Hire" },
  { label: "KIV", value: "KIV" },
  { label: "Not suitable", value: "Not suitable" },
  { label: "Reject IV", value: "Reject IV" },
  { label: "Reject offer", value: "Reject offer" },
  { label: "Blacklist", value: "Blacklist" },
];

export const ConfidenceRateList = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];

export const InterviewMethodList = [
  { label: "Face To Face", value: "Face To Face" },
  { label: "Google Meet", value: "Google Meet" },
  { label: "ZOOM", value: "ZOOM" },
];

export const InterviewRateList = [
  { label: "Good", value: "Good" },
  { label: "Average", value: "Average" },
  { label: "Poor", value: "Poor" },
];

export const IvDurationList = [
  { label: "30 minutes", value: 30 },
  { label: "1 hour", value: 1.0 },
  { label: "1 hour 30 minutes", value: 1.3 },
  { label: "2 hour", value: 2.0 },
];

export const IvTimeList = [
  { label: "12:00", value: "12:00" },
  { label: "12:30", value: "12:30" },
  { label: "01:00", value: "01:00" },
  { label: "01:30", value: "01:30" },
  { label: "02:00", value: "02:00" },
  { label: "02:30", value: "02:30" },
  { label: "03:00", value: "03:00" },
  { label: "03:30", value: "03:30" },
  { label: "04:00", value: "04:00" },
  { label: "04:30", value: "04:30" },
  { label: "05:00", value: "05:00" },
  { label: "05:30", value: "05:30" },
  { label: "06:00", value: "06:00" },
  { label: "06:30", value: "06:30" },
  { label: "07:00", value: "07:00" },
  { label: "07:30", value: "07:30" },
  { label: "08:00", value: "08:00" },
  { label: "08:30", value: "08:30" },
  { label: "09:00", value: "09:00" },
  { label: "09:30", value: "09:30" },
  { label: "10:00", value: "10:00" },
  { label: "10:30", value: "10:30" },
  { label: "11:00", value: "11:00" },
  { label: "11:30", value: "11:30" },
];

export const ActionEmailList = [
  { label: "Email Candidate for Full Form", value: "emailFullForm" },
  { label: "Email Candidate for Interview", value: "emailInterview" },
  { label: "Email Candidate for Internship Rejection", value: "emailRejection" },
];

export const ActionStatusList = [
  { label: "Review Only", value: 100 },
  { label: "Review and Recommend", value: 200 },
  { label: "Cancel Interview", value: 130 },
  { label: "Candidate Accept Internship Offer", value: 20 },
  { label: "Candidate Turn Down Internship Offer", value: -20 },
];