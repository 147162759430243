import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  Container,
  Table,
  Form,
  Row,
  Col,
  Button,
  Breadcrumb,
} from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";

import NavbarMenu from "../../2.Common/NavbarMenu";

import {
  getLogEmails,
  updateEmail,
  resetEmail,
  getEmail,
  postCustomEmail,
} from "../../4.Store/LogSlice";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; // Import the classic editor build

const Email = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email, emails } = useSelector((state) => state.log);
  const [readonly, setReadonly] = useState(false);
  const [emailStatus, setEmailStatus] = useState(1);

  const ckEditorConfig = {
    // Set the height of the CKEditor
    height: "300px", // Adjust the height value as needed
  };

  useEffect(() => {
    // console.log(param._id);
    dispatch(getLogEmails(param._id));
  }, [param._id]);

  const handleFieldChange = (e) => {
    const entity = {
      ...email,
      data: {
        ...email.data,
        [e.target.name]: e.target.value,
      },
    };

    dispatch(updateEmail(entity));
    // console.log(email);
  };

  // const handleEditorChange = (e) => {
  //   const entity = { ...email, data: {
  //     text: e.target.value }
  //   };
  //   dispatch(updateEmail(entity));
  // };

  const handleEditorChange = (content, editor) => {
    const extractedText = editor.getContent();

    const entity = {
      ...email,
      data: {
        ...email.data,
        text: extractedText,
      },
    };
    dispatch(updateEmail(entity));
    // console.log(entity);
  };

  const handleResend = () => {
    const entity = {
      ...email,
      _id: "",
    };
    dispatch(updateEmail(entity));
    setReadonly(false);
    // console.log(entity);
  };

  const handleCreate = () => {
    const entity = { ...email, jobapplication_id: param._id };
    dispatch(postCustomEmail(entity))
      .unwrap()
      .then(() => {
        Swal.fire("Email Send Successfully.");
        dispatch(getLogEmails(param._id));
      })
      .then(() => {
        dispatch(resetEmail());
      })
      .catch((err) => {
        Swal.fire("Error Sending Email", err, "error", "OK");
        // console.log(err);
      });
  };

  const handleRowClick = (_id) => {
    dispatch(resetEmail()); // Dispatch the resetEmail action
    dispatch(getEmail(_id));
    setReadonly(true);
  };

  const handleNewEmailClick = () => {
    setReadonly(false);
    dispatch(resetEmail()); // Dispatch the resetEmail action
  };

  const handleClickBack = () => {
    navigate("/HRView");
  };

  const handleClickHome = () => {
    navigate("/Homepage");
  };

  return (
    <>
      <NavbarMenu />
      <Breadcrumb className="px-1">
        <Breadcrumb.Item onClick={handleClickHome}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={handleClickBack}>Main List</Breadcrumb.Item>
      </Breadcrumb>
      <Container className="px-3">
        <Row className="mx-md-3 my-3">
          <h2>Log Emails Table</h2>

          <div>
            {/* Create four buttons in a row */}
            <Button
              variant="primary"
              className="m-2"
              onClick={handleNewEmailClick}
            >
              New
            </Button>
          </div>

          <Col md={3}>
            <Table bordered responsive hover>
              <tbody
                style={{
                  backgroundColor: "#DDF4FC",
                }}
              >
                {emails.map((rowData, i) => (
                  <tr key={i} onClick={() => handleRowClick(rowData._id)}>
                    <td>
                      {rowData?.date} {rowData?.time} <br />
                      <span
                        style={{
                          color:
                            rowData?.message?.response === "250 OK"
                              ? "green"
                              : "red",
                        }}
                      >
                        ({rowData?.message?.response})
                      </span>
                      <br />
                      {/* <span style={{ whiteSpace: "nowrap", overflowX:"hidden", textOverflow:"ellipsis"}}>{rowData?.data?.subject}</span><br/> */}
                      To: {rowData?.data?.to} <br />
                      {/* <td>{rowData?.data?.from}</td>
                  <td>{rowData?.data?.to}</td>
                  <td>{rowData?.data?.subject}</td>
                  <td>{rowData?.date}</td>
                  <td>{rowData?.time}</td>
                  <td>{rowData?.message?.response}</td>
                  <td>{rowData?.message?.messageId}</td> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>

          <Col md={9}>
            <div className="bg-light px-3">
              {/* 
            <Form>

            <Form.Group className="d-flex align-items-center">
    <Form.Label>For HR:</Form.Label>
    <div className="input-group">
      <Form.Control
        name="hr"
        type="text"
        value={`https://jobapp.hslnet.my/HRViewForm/${email?.jobapplication_id}`}
        onChange={handleFieldChange}
        readOnly
      />
      <button
        className="btn btn-secondary"
        type="button"
        onClick={() => {
          const input = document.querySelector('[name="hr"]');
          input.select();
          document.execCommand("copy");
        }}
      >
        Copy
      </button>
    </div>
  </Form.Group>

  <Form.Group className="d-flex align-items-center">
    <Form.Label>For Supervisor:</Form.Label>
    <div className="input-group">
      <Form.Control
        name="supervisor"
        type="text"
        value={`https://jobapp.hslnet.my/SVViewForm/${email?.jobapplication_id}`}
        onChange={handleFieldChange}
        readOnly
      />
      <button
        className="btn btn-secondary"
        type="button"
        onClick={() => {
          const input = document.querySelector('[name="supervisor"]');
          input.select();
          document.execCommand("copy");
        }}
      >
        Copy
      </button>
    </div>
  </Form.Group>

  <Form.Group className="d-flex align-items-center">
    <Form.Label>For Candidate:</Form.Label>
    <div className="input-group">
      <Form.Control
        name="candidate"
        type="text"
        value={`https://jobapp.hslnet.my/applicationForm/${email?.jobapplication_id}`}
        onChange={handleFieldChange}
        readOnly
      />
      <button
        className="btn btn-secondary"
        type="button"
        onClick={() => {
          const input = document.querySelector('[name="candidate"]');
          input.select();
          document.execCommand("copy");
        }}
      >
        Copy
      </button>
    </div>
  </Form.Group>
</Form> */}

              {/* <b> */}
              {/* {email._id && (
              email.data?.subject
              )}

            {email._id == "" && (
                "New Email"
              )}
                </b> */}
              <Form>
                <Form.Group>
                  <Form.Label>From:</Form.Label>
                  <Form.Control
                    name="from"
                    type="text"
                    value={email.data?.from}
                    onChange={handleFieldChange}
                    readOnly
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>To:</Form.Label>
                  <Form.Control
                    name="to"
                    type="text"
                    value={email.data?.to}
                    onChange={handleFieldChange}
                    readOnly={readonly === true}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Subject:</Form.Label>
                  <Form.Control
                    name="subject"
                    type="text"
                    value={email.data?.subject}
                    onChange={handleFieldChange}
                    readOnly={readonly === true}
                  />
                </Form.Group>
                {email._id && (
                  <Form.Group>
                    <Form.Label>Date:</Form.Label>
                    <Form.Control
                      name="date"
                      type="text"
                      value={email.date}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                )}
                {email._id && (
                  <Form.Group>
                    <Form.Label>Time:</Form.Label>
                    <Form.Control
                      type="text"
                      value={email.time}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label>Message Body:</Form.Label>
                  <Editor
                    apiKey="i5jkor6zao7pkhjdr5e6obci4bmgslxh002nhwhedm4fxj05"
                    initialValue={email.data?.text}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists image",
                        "charmap print preview anchor help",
                        "searchreplace visualblocks code",
                        "insertdatetime media paste wordcount",
                        "table",
                        "link",
                      ],
                      toolbar:
                        "undo redo | table | link | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent  ",
                      readonly: readonly === true ? 1 : 0,
                    }}
                    onChange={handleEditorChange}
                  />
                </Form.Group>
                <br />
                <br />
                {email._id && (
                  <Button
                    type="button"
                    variant="warning"
                    onClick={handleResend}
                  >
                    Edit Content
                  </Button>
                )}

                {email._id == "" && (
                  <Button
                    type="button"
                    variant="primary"
                    onClick={handleCreate}
                  >
                    Send
                  </Button>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Email;
