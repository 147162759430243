import { Row, Col, Form } from "react-bootstrap";
import { styles } from "../1.Pages/ApplicationForm/ApplicationFormStyle";

export const ImageView = ({ src }) => {
  return (
    <Row className="justify-content-md-center mt-3 mb-3">
      <Col md="auto" sm="auto">
        <>
          <div style={styles.CardImg}>
            <img src={src} alt="Uploaded" style={styles.Image} />
          </div>
        </>
      </Col>
    </Row>
  );
};

export const FormLabelTextView = ({ label, value }) => {
  return (
    <Row className="px-2 py-2">
      <Col md={4}>
        <span style={{ textAlign: "left" }}>{label}:</span>
      </Col>
      <Col md={8}>
        <span style={{ textAlign: "left" }}>{value}</span>
      </Col>
    </Row>
  );
};

export const FormLabelTextViewBack = ({ label, value, text }) => {
  return (
    <Row className="px-2 py-2">
      <Col md={4}>
        <span style={{ textAlign: "left" }}>{label}:</span>
      </Col>
      <Col md={8}>
        <span style={{ textAlign: "left" }}>{value}</span>&nbsp;
        <span>{text}</span>
      </Col>
    </Row>
  );
};

export const FormLabelTextViewFront = ({ label, value, text }) => {
  return (
    <Row className="px-2 py-2">
      <Col md={4}>
        <span style={{ textAlign: "left" }}>{label}:</span>
      </Col>
      <Col md={8}>
        <span>{text}</span>&nbsp;
        <span style={{ textAlign: "left" }}>{value}</span>
      </Col>
    </Row>
  );
};

export const FormLabelTextViewEssay = ({ label, value, text }) => {
  return (
    <Form.Group className="px-2 py-2">
      <Form.Label
        style={{ fontSize: "15px", textAlign: "start", display: "flex" }}
      >
        {label}
      </Form.Label>
      <Form.Control value={value} readOnly type="text" as="textarea" rows="8" />
    </Form.Group>
  );
};

export const FormLabelTextViewAttachment = ({ label, value, handleClick }) => {
  return (
    <Form.Group className="px-2 py-2">
      <Form.Label
        style={{ fontSize: "15px", textAlign: "start", display: "flex" }}
      >
        {label}
      </Form.Label>
      <ul>
        <li>
          {value ? (
            <Form.Control
              plaintext
              readOnly
              style={{ cursor: "pointer" }}
              value={value.fileName1}
              onClick={() => handleClick(value.path, value.fileName2)}
            />
          ) : (
            "Not Uploaded"
          )}
        </li>
      </ul>
    </Form.Group>
  );
};
