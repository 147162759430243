import { useSelector } from "react-redux";
import { Col, Row, Card, Form } from "react-bootstrap";
import { styles } from "../SVFeedbackStyle";

const SVInterviewDetail = () => {
  const { ivDate, ivTime, ivMethod, ivAMPM } = useSelector(
    (state) => state.Circulation
  );

  return (
    <Row className="py-2">
      <Col md={12}>
        <Card>
          <Card.Header style={styles.TitleStyle}>
            Interview Details
            <Form.Text muted className="formLabel">
              Contact HR to amend Interview Details
            </Form.Text>
          </Card.Header>
          <Card.Body>
            <ul>
              <li>
                Interview Date and Time :&nbsp;
                <span style={{ color: "red" }}>{ivDate}</span>&nbsp;at&nbsp;
                <span style={{ color: "red" }}>
                  {ivTime?.label || ivTime} {ivAMPM?.label}
                </span>
              </li>
              <li>
                Method:&nbsp;
                <span style={{ color: "red" }}>{ivMethod?.label}</span>
              </li>
            </ul>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default SVInterviewDetail;
