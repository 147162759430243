import { Form, Button, Container } from "react-bootstrap";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import "./LoginStyle.css";

import UserService from "../../5.Services/UserService";

const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    UserService.doLogin(username, password)
      .then(() => {
        navigate("/Homepage");
      })
      .catch(() => {
        Swal.fire("Login Failed!");
      });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleLogin(event);
    }
  };

  return (
    <Container style={{ flex: 1 }}>
      <Container className="content">
        <div className="titleContainer">
          <h5 className="titleLabel">Job Application</h5>
        </div>

        <div className="formControlContainer">
          <div className="formControlWrapper">
            <Form.Control
              name="username"
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>

        <div className="formControlContainer">
          <div className="formControlWrapper">
            <Form.Control
              name="password"
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>

        <div>
          <Button className="btnLogin" onClick={handleLogin}>
            Log In
          </Button>
        </div>
      </Container>
    </Container>
  );
};
export default Login;
