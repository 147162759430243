import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  Table,
} from "react-bootstrap";

import { styles } from "./AdvertisementSourceReportStyle";
import NavbarMenu from "../../2.Common/NavbarMenu";

import {
  postListSource,
  updateDateStart,
  updateDateEnd,
  resetDateStart,
  resetDateEnd,
} from "../../4.Store/ReportSlice";

const AdvertisementSourceReport = () => {
  const dispatch = useDispatch();

  const [showList, setShowList] = useState([]);
  const { dateStart, dateEnd } = useSelector((state) => state.Report);

  const handleChangeDateStart = (e) => {
    const entity = { dateStart: e.target.value };
    dispatch(updateDateStart(entity));
    // console.log(entity);
  };

  const handleChangeDateEnd = (e) => {
    const entity = { dateEnd: e.target.value };
    dispatch(updateDateEnd(entity));
    // console.log(entity);
  };

  const handleClickSearch = () => {
    const entity = { dateStart, dateEnd };
    // console.log(entity);
    dispatch(postListSource(entity)).then((res) => {
      // console.log(res);
      // console.log(res.payload);
      setShowList(res.payload);
    });
  };

  const handleClickReset = () => {
    dispatch(resetDateStart());
    dispatch(resetDateEnd());
    setShowList([]);
  };

  return (
    <>
      <NavbarMenu />
      <Container fluid>
        <Card className="mt-5 mb-3">
          <h3 className="text-center">Advertisement Source Report</h3>

          <Row className="justify-content-md-center px-3 py-3">
            <InputGroup className="col-4 mb-3">
              <InputGroup.Text>Start Date</InputGroup.Text>
              <Form.Control
                name="dateStart"
                type="date"
                onChange={handleChangeDateStart}
                value={dateStart}
              />

              <InputGroup.Text>End Date</InputGroup.Text>
              <Form.Control
                name="dateEnd"
                type="date"
                onChange={handleChangeDateEnd}
                value={dateEnd}
              />

              <Button variant="outline-primary" onClick={handleClickSearch}>
                <FontAwesomeIcon icon={faSearch} style={{ color: "black" }} />
              </Button>
            </InputGroup>
          </Row>

          <Table>
            <thead style={styles.TableHeader}>
              <tr>
                <th>No</th>
                <th style={styles.ThText}>Source Type</th>
                <th style={styles.ThText}>Count</th>
                <th style={styles.ThText}>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {showList.map(({ _id, count, percentage }, i) => (
                <tr key={i}>
                  {/* <td style={styles.TdText}>{i+1}</td> */}
                  <td>{i + 1}</td>
                  <td style={styles.TdText}>{_id}</td>
                  <td style={styles.TdText}>{count}</td>
                  <td style={styles.TdText}>{percentage}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {showList.length > 0 && (
            <Row className="justify-content-md-end mb-2">
              <Col xs="auto">
                <Button
                  onClick={handleClickReset}
                  variant="outline-primary"
                  className="mx-3"
                >
                  Reset Search
                </Button>
              </Col>
            </Row>
          )}
        </Card>
      </Container>
    </>
  );
};
export default AdvertisementSourceReport;
