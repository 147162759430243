import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
// import ApplicationFormMasterSlice from "../component/ApplicationForm/ApplicationFormMasterSlice";
// import TabsSlice from "../component/TabList/TabsSlice";
// import CirculationSlice from "../component/Remarks/CirculationSlice";
// import AssessmentSlice from "../component/AssessmentForm/AssessmentSlice";
// import InternshipFormSlice from "../component/InternshipForm/InternshipFormSlice";

// New
import ApplicationFormSlice from "./ApplicationFormSlice";
import HRTableListSlice from "./HRTableListSlice";
import SVTableListSlice from "./SVTableListSlice";
import LogSlice from "./LogSlice";
import CirculationSlice2 from "./CirculationSlice";
import AssessmentSlice2 from "./AssessmentSlice";
import ReportSlice from "./ReportSlice";
import NotificationSlice from "./NotificationSlice"

export default configureStore({
  reducer: {
    // applicationForm: ApplicationFormMasterSlice,
    // assessmentForm: AssessmentSlice,
    // tab: TabsSlice,
    // circulation: CirculationSlice,
    // internship: InternshipFormSlice,

    // New
    ApplicationForm: ApplicationFormSlice,
    HRTableList: HRTableListSlice,
    SVTableList: SVTableListSlice,
    log: LogSlice,
    Circulation: CirculationSlice2,
    Assessment: AssessmentSlice2,
    Report: ReportSlice,
    Notification: NotificationSlice,
  },
  middleware: [thunk],
});
