import React, { useState } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";

import { styles } from "../1.Pages/ApplicationForm/ApplicationFormStyle";

import {
  updateSectionOne,
  postUpload,
  getUpload,
} from "../4.Store/ApplicationFormSlice";

const UploadPhoto = () => {
  const dispatch = useDispatch();
  const [fileInput, setFileInput] = useState(null);

  const { sectionOne } = useSelector((state) => state.ApplicationForm);
  const { image } = sectionOne;

  const uploadImage = (e, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const uploadFile = e.target.files[0];
      const FormData = require("form-data");
      const form = new FormData();
      const fileType = uploadFile.type;
      const fileSize = uploadFile.size;
      const fileTypeAllowed = ["image/jpeg", "image/png"];

      if (!fileTypeAllowed.includes(fileType)) {
        Swal.fire("Accept Image In JPEG / PNG Only");
        e.target.value = null;
        return;
      }

      if (fileSize > 3145728) {
        Swal.fire("File Too Big (>3MB)");
        reject(new Error("File size exceeds the limit"));
        e.target.value = null;
        return;
      }

      // Create a new HTML Image element
      const img = document.createElement("img");
      img.onload = () => {
        // Calculate the new dimensions while preserving the aspect ratio
        let newWidth = img.width;
        let newHeight = img.height;

        if (newWidth > maxWidth) {
          const ratio = maxWidth / newWidth;
          newWidth = maxWidth;
          newHeight *= ratio;
        }

        if (newHeight > maxHeight) {
          const ratio = maxHeight / newHeight;
          newHeight = maxHeight;
          newWidth *= ratio;
        }

        // Create a canvas element to draw the resized image
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Convert the canvas content to a Blob
        canvas.toBlob((blob) => {
          // Append the Blob to the FormData
          form.append("uploadFile", blob, uploadFile.name);

          dispatch(postUpload(form)).then((result) => {
            // console.log(result);
            const details = {
              fileType: uploadFile.type,
              fileSize: uploadFile.size,
              fileName1: uploadFile.name,
              path: result.payload.path,
              fileName2: result.payload.fileName,
            };
            // console.log(details);
            resolve(details);
          });
        }, fileType);
      };

      // Load the selected image source into the img element
      const reader = new FileReader();
      reader.onload = (event) => {
        img.src = event.target.result;
      };
      reader.readAsDataURL(uploadFile);
    });
  };

  const handleChangeImage = (e) => {
    // Call uploadImage with your desired maxWidth and maxHeight
    uploadImage(e, 300, 300) // Adjust these values as needed
      .then((res) => {
        // console.log(res);
        const b = {
          filename: res.fileName2,
          path: res.path,
        };
        // console.log(b);
        dispatch(getUpload(b)).then((result) => {
          // console.log(result);
          const entity = {
            ...sectionOne,
            image: `data:${res.fileType};base64,${result.payload}`,
          };
          // console.log(entity);
          dispatch(updateSectionOne(entity));
        });
      })
      .catch((error) => {
        // console.log(error);
        e.target.value = null;
      });
  };

  const handleClick = () => {
    if (fileInput) fileInput.click();
  };

  return (
    <Row className="justify-content-md-center justify-content-sm-center mt-3 mb-3">
      <Col md="auto" sm="auto" className="text-center">
        <Button style={styles.ButtonUpload} onClick={handleClick}>
          {image ? (
            <>
              <div style={styles.CardImg}>
                <img src={image} alt="Uploaded" style={styles.Image} />
                <input
                  ref={(input) => setFileInput(input)}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  style={{ display: "none" }}
                  onChange={handleChangeImage}
                />
              </div>
            </>
          ) : (
            <div style={styles.CardImg}>
              <input
                ref={(input) => setFileInput(input)}
                type="file"
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                onChange={handleChangeImage}
              />
              <div style={styles.Text}>
                Attach Recent Passport Size Image Here <br /> File Limit: 3MB
              </div>
            </div>
          )}
        </Button>
      </Col>
    </Row>
  );
};

export default UploadPhoto;
