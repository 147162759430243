import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { getHRInternshipListPagination } from "../../4.Store/HRTableListSlice";

const TitleInternshipList = () => {
  const dispatch = useDispatch();

  const { internshipLists, currentInternshipPage } = useSelector(
    (store) => store.HRTableList
  );
  const { status } = useSelector((state) => state.ApplicationForm);

  const calculateNewCount = () => {
    return internshipLists.filter(
      (item) => item.status === 0 || item.status === 10
    ).length;
  };

  // const calculateNewCount = () => {
  //   if (status === 0 || status === 10) {
  //     return internshipLists.filter(
  //       (item) => item.status === 0 || item.status === 10
  //     ).length;
  //   }
  // };

  return (
    <>
      <span>Internship List</span>&nbsp;
      <span
        className="badge"
        style={{ border: "1px solid white", backgroundColor: "#5E8ABB" }}
      >
        {calculateNewCount()}
      </span>
    </>
  );
};

export default TitleInternshipList;
