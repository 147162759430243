import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, InputGroup, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import SVTableApplicationList from "./Components/SVTableApplicationList";
import SVCardApplicationList from "./Components/SVCardApplicationList";
import PaginationList from "../../2.Common/Pagination";

import {
  getSVApplicationListPagination,
  getSVApplicationListSearch,
  updateSearchSvApplicationList,
  updateCurrentSVApplicationPage,
} from "../../4.Store/SVTableListSlice";

const SVApplicationList = () => {
  const sv_id = localStorage.getItem("sv_id");

  const dispatch = useDispatch();

  const [searchButtonClicked, setSearchButtonClicked] = useState(false);

  const {
    circulationBySvIdLists,
    currentSVApplicationPage,
    totalCurrentSVApplicationPage,
    searchCirculationBySvIdLists,
    searchKeyWordCirculationBySvIdLists,
  } = useSelector((store) => store.SVTableList);

  useEffect(() => {
    if (sv_id) {
      if (searchButtonClicked) {
        if (searchKeyWordCirculationBySvIdLists) {
          dispatch(
            getSVApplicationListSearch({
              sv_id,
              key: searchKeyWordCirculationBySvIdLists,
            })
          );
        } else {
          dispatch(
            getSVApplicationListPagination({
              sv_id,
              page: currentSVApplicationPage,
            })
          );
        }
        setSearchButtonClicked(false); // Reset the search button state
      } else {
        dispatch(
          getSVApplicationListPagination({
            sv_id,
            page: currentSVApplicationPage,
          })
        );
      }
    }
  }, [
    currentSVApplicationPage,
    searchKeyWordCirculationBySvIdLists,
    searchButtonClicked,
    sv_id,
  ]);

  const handleSearchClick = () => {
    setSearchButtonClicked(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSearchClick(event);
    }
  };

  const handlePagination = (page) => {
    dispatch(updateCurrentSVApplicationPage(page));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <Form.Control
                className="form-control"
                placeholder="Search"
                onChange={({ currentTarget: input }) => {
                  dispatch(updateSearchSvApplicationList(input.value));
                }}
                onKeyDown={handleKeyDown}
                value={searchKeyWordCirculationBySvIdLists}
              />
              <Button variant="outline-info" onClick={handleSearchClick}>
                <FontAwesomeIcon icon={faSearch} style={{ color: "black" }} />
              </Button>
            </InputGroup>
          </Col>
          <Col md="auto" sm="auto">
            <PaginationList
              nPages={totalCurrentSVApplicationPage} // Use the totalCurrentPage value from the store
              currentPage={currentSVApplicationPage}
              handlePagination={handlePagination}
            />
          </Col>
        </Row>

        <SVTableApplicationList />
        <SVCardApplicationList />

        <Row>
          <Col>
            <InputGroup className="mb-3">
              <Form.Control
                className="form-control"
                placeholder="Search"
                onChange={({ currentTarget: input }) => {
                  dispatch(updateSearchSvApplicationList(input.value));
                }}
                onKeyDown={handleKeyDown}
                value={searchKeyWordCirculationBySvIdLists}
              />
              <Button variant="outline-info" onClick={handleSearchClick}>
                <FontAwesomeIcon icon={faSearch} style={{ color: "black" }} />
              </Button>
            </InputGroup>
          </Col>
          <Col md="auto" sm="auto">
            <PaginationList
              nPages={totalCurrentSVApplicationPage} // Use the totalCurrentPage value from the store
              currentPage={currentSVApplicationPage}
              handlePagination={handlePagination}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SVApplicationList;
