export const styles = {
  CardContainer: { backgroundColor: "white", margin: "30px" },
  CardImg: {
    position: "relative",
    width: "100px",
    height: "150px",
    backgroundColor: "#F2F2F2",
    borderRadius: "16px",
    boxShadow: "0px 1px 12px rgba(3,3,3,0.08)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  Text: {
    color: "#858585",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 300,
    lineHeight: "16px",
    textAlign: "center",
  },
  ButtonUpload: {
    backgroundColor: "transparent",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },
  TextHeader5: { textAlign: "left", fontWeight: "bold", marginTop: 30 },
};
