import React, { useState } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import PDFViewer from "./PDFViewer.jsx";
import {
  updateSectionOne,
  postUpload,
  getUpload,
} from "../4.Store/ApplicationFormSlice";

const UploadCoverLetter = () => {
  const dispatch = useDispatch();

  const [viewPdf, setViewPdf] = useState(undefined);
  const [isUpload, setIsUpload] = useState(false);

  const { sectionOne } = useSelector((state) => state.ApplicationForm);
  const { cover_letter } = sectionOne;

  const handlePdfViewer = () => {
    setViewPdf(undefined);
  };

  const handleViewPdf = (path, filename) => {
    // console.log(path, filename);
    dispatch(getUpload({ path, filename })).then((result) => {
      // console.log(result.payload);

      const { Base64 } = require("js-base64");
      const pdf = `data:application/pdf;base64,${result.payload}`;
      setViewPdf(pdf);
    });
  };

  const uploadCoverLetter = (e) => {
    setIsUpload(true);
    return new Promise((resolve, reject) => {
      const uploadFile = e.target.files[0];
      const fileType = uploadFile.type;
      const fileSize = uploadFile.size;
      const fileTypeAllowed = ["application/pdf"];

      if (!fileTypeAllowed.includes(fileType)) {
        Swal.fire("Accept PDF File Only");
        reject(new Error("Invalid file type"));
        setIsUpload(false);
        e.target.value = null;
        return;
      }

      if (fileSize > 1048576) {
        Swal.fire("File Too Big (>1MB)");
        reject(new Error("File size exceeds the limit"));
        setIsUpload(false);
        e.target.value = null;
        return;
      }

      const form = new FormData();
      form.append("uploadFile", uploadFile);

      dispatch(postUpload(form))
        .then((result) => {
          // console.log(result);
          const details = {
            fileType: uploadFile.type,
            fileSize: uploadFile.size,
            fileName1: uploadFile.name,
            path: result.payload.path,
            fileName2: result.payload.fileName,
          };
          // console.log(details);
          resolve(details);
          setIsUpload(false);
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
          setIsUpload(false);
        });
    });
  };

  const handleUploadCoverLetter = (e) => {
    setIsUpload(true);
    uploadCoverLetter(e)
      .then((cover_letter) => {
        // console.log(cover_letter);
        if (cover_letter?.fileName2 && cover_letter?.path) {
          if (cover_letter?.fileSize > 0) {
            const entity = { ...sectionOne, cover_letter: cover_letter };
            // console.log(entity);
            dispatch(updateSectionOne(entity));
            setIsUpload(false);
          } else {
            e.target.value = null;
            Swal.fire("Please Re-Upload Cover Letter");
          }
        } else {
          e.target.value = null;
          // console.log("Please Re-Upload Cover Letter Again");
        }
        // const entity = { ...sectionOne, cover_letter: cover_letter };
        // console.log(entity);
        // dispatch(updateSectionOne(entity));
        // setIsUpload(false);
      })
      .catch((error) => {
        // console.log(error);
        // Clear the file input by resetting its value
        e.target.value = null;
        setIsUpload(false);
      });
  };

  return (
    <>
      {/* {isUpload && <PreloaderUpload />} */}
      {viewPdf && <PDFViewer onClose={handlePdfViewer} fileObject={viewPdf} />}
      {viewPdf === undefined && (
        <Form.Group as={Row} className="px-2 py-3">
          <Form.Label
            column
            sm="2"
            style={{ fontSize: "15px", textAlign: "left" }}
          >
            Cover Letter&nbsp;&nbsp;
            <a id="coverletter">
              <FontAwesomeIcon icon={faCircleQuestion} />
            </a>
            <Tooltip anchorSelect="#coverletter" content="File limit: 1 MB" />
          </Form.Label>
          <Col sm="10">
            {isUpload ? (
              <>
                <div>UPLOADING COVER LETTER</div>
                <div className="progress"></div>
              </>
            ) : (
              <>
                {cover_letter && (
                  <ul>
                    <li>
                      <Form.Control
                        className="cursor"
                        plaintext
                        readOnly
                        value={cover_letter?.fileName1}
                        onClick={() =>
                          handleViewPdf(
                            cover_letter?.path,
                            cover_letter?.fileName2
                          )
                        }
                      />
                    </li>
                  </ul>
                )}
                <Form.Control
                  name="cover_letter"
                  onChange={handleUploadCoverLetter}
                  type="file"
                  accept="application/pdf"
                />
              </>
            )}
          </Col>
        </Form.Group>
      )}
    </>
  );
};

export default UploadCoverLetter;
