import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, InputGroup, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import SVTableAssessmentList from "./Components/SVTableAssessmentList";
import SVCardAssessmentList from "./Components/SVCardAssessmentList";
import PaginationList from "../../2.Common/Pagination";

import {
  getSVInterviewListPagination,
  getSVInterviewListSearch,
  updateSearchSvAssessmentList,
  updateCurrentSVAssessmentPage,
} from "../../4.Store/SVTableListSlice";

const SVAssessmentList = () => {
  const sv_id = localStorage.getItem("sv_id");

  const dispatch = useDispatch();

  const [searchButtonClicked, setSearchButtonClicked] = useState(false);

  const {
    assessmentBySvIdLists,
    currentSVAssessmentPage,
    totalCurrentSVAssessmentPage,
    searchAssessmentBySvIdLists,
    searchKeyWordAssessmentBySvIdLists,
  } = useSelector((store) => store.SVTableList);

  useEffect(() => {
    if (sv_id) {
      if (searchButtonClicked) {
        if (searchKeyWordAssessmentBySvIdLists) {
          dispatch(
            getSVInterviewListSearch({
              sv_id,
              key: searchKeyWordAssessmentBySvIdLists,
            })
          );
        } else {
          dispatch(
            getSVInterviewListPagination({
              sv_id,
              page: currentSVAssessmentPage,
            })
          );
        }
        setSearchButtonClicked(false); // Reset the search button state
      } else {
        dispatch(
          getSVInterviewListPagination({
            sv_id,
            page: currentSVAssessmentPage,
          })
        );
      }
    }
  }, [
    currentSVAssessmentPage,
    searchKeyWordAssessmentBySvIdLists,
    searchButtonClicked,
    sv_id,
  ]);

  const handleSearchClick = () => {
    setSearchButtonClicked(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleSearchClick(event);
    }
  };

  const handlePagination = (page) => {
    dispatch(updateCurrentSVAssessmentPage(page));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <InputGroup className="mb-3">
              <Form.Control
                className="form-control"
                placeholder="Search"
                onChange={({ currentTarget: input }) => {
                  dispatch(updateSearchSvAssessmentList(input.value)); // Update the search term on input change
                }}
                onKeyDown={handleKeyDown}
                value={searchKeyWordAssessmentBySvIdLists}
              />
              <Button variant="outline-info" onClick={handleSearchClick}>
                <FontAwesomeIcon icon={faSearch} style={{ color: "black" }} />
              </Button>
            </InputGroup>
          </Col>
          <Col md="auto" sm="auto">
            <PaginationList
              nPages={totalCurrentSVAssessmentPage}
              currentPage={currentSVAssessmentPage}
              handlePagination={handlePagination}
            />
          </Col>
        </Row>

        <SVTableAssessmentList />
        <SVCardAssessmentList />

        <Row>
          <Col>
            <InputGroup className="mb-3">
              <Form.Control
                className="form-control"
                placeholder="Search"
                onChange={({ currentTarget: input }) => {
                  dispatch(updateSearchSvAssessmentList(input.value)); // Update the search term on input change
                }}
                onKeyDown={handleKeyDown}
                value={searchKeyWordAssessmentBySvIdLists}
              />
              <Button variant="outline-info" onClick={handleSearchClick}>
                <FontAwesomeIcon icon={faSearch} style={{ color: "black" }} />
              </Button>
            </InputGroup>
          </Col>
          <Col md="auto" sm="auto">
            <PaginationList
              nPages={totalCurrentSVAssessmentPage}
              currentPage={currentSVAssessmentPage}
              handlePagination={handlePagination}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default SVAssessmentList;
