import { Container, Row, Col, Form } from "react-bootstrap";

import { styles } from "./AssessmentFormStyle";
import { FormHeaderAssessment } from "../../2.Common/FormHeader";
import SectionA from "./Components/SectionA";
import SectionB from "./Components/SectionB";
import SectionC from "./Components/SectionC";
import SectionD from "./Components/SectionD";
import Approval from "./Components/Approval";

const AssessmentForm = () => {
  return (
    <Container fluid>
      <FormHeaderAssessment />

      <Row className="py-2">
        <Col>
          <Form.Label style={styles.TitleStyle}>
            HR DEPARTMENT SECTION
          </Form.Label>
        </Col>
      </Row>
      <SectionA />
      <hr />

      <Row className="py-2">
        <Col>
          <Form.Label style={styles.TitleStyle}>INTERVIEWER SECTION</Form.Label>
        </Col>
      </Row>
      <SectionB />
      <SectionC />
      <SectionD />
      <hr />

      <Row className="py-2">
        <Col>
          <Form.Label style={styles.TitleStyle}>APPROVAL SECTION</Form.Label>
        </Col>
      </Row>
      <Approval />
    </Container>
  );
};
export default AssessmentForm;
