import { useSelector } from "react-redux";
import { Col, Row, Card, Form } from "react-bootstrap";
import { styles } from "../SVFeedbackStyle";

const SVInternshipDetail = () => {
  const { superiorName, assignDeptProject } = useSelector(
    (state) => state.Circulation
  );

  return (
    <Row className="py-2">
      <Col md={12}>
        <Card>
          <Card.Header style={styles.TitleStyle}>
            Internship Details
            <Form.Text muted className="formLabel">
              Contact HR to amend Internship Placement
            </Form.Text>
          </Card.Header>
          <Card.Body>
            <ul>
              <li>
                Superior Name:{" "}
                <span style={{ color: "red" }}>{superiorName}</span>
              </li>
              <li>
                Assigned Department / Project:{" "}
                <span style={{ color: "red" }}>{assignDeptProject}</span>
              </li>
            </ul>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default SVInternshipDetail;
