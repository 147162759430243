import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { Tooltip } from "react-tooltip";

export const FormLabelControlTextFullNR = ({
  label,
  placeholder,
  name,
  value,
  handleChangeForm,
  type,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-2">
      <Form.Label column sm="2" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}
      </Form.Label>
      <Col sm="10">
        <Form.Control
          name={name}
          value={value}
          onChange={handleChangeForm}
          required
          type={type}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlTextNR = ({
  label,
  placeholder,
  name,
  value,
  handleChangeForm,
  type,
  icon,
  tooltip,
  tooltipId,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3">
      <Form.Label column sm="4" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}
        &nbsp;&nbsp;
        <a id={tooltipId} style={{ textAlign: "right" }}>
          <FontAwesomeIcon icon={icon} />
        </a>
        <Tooltip anchorSelect={`#${tooltipId}`} content={tooltip} />{" "}
      </Form.Label>
      <Col sm="8">
        <Form.Control
          name={name}
          value={value}
          onChange={handleChangeForm}
          required
          type={type}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlTextNRNoIcon = ({
  label,
  placeholder,
  name,
  value,
  handleChangeForm,
  type,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3">
      <Form.Label column sm="4" style={{ fontSize: "15px", textAlign: "left" }}>
        {label}
      </Form.Label>
      <Col sm="8">
        <Form.Control
          name={name}
          value={value}
          onChange={handleChangeForm}
          required
          type={type}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelSelectNR = ({
  label,
  name,
  value,
  options,
  handleChange,
  isDisabled,
  placeholder,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3" style={{ textAlign: "left" }}>
      <Form.Label column sm="4" style={{ fontSize: "15px" }}>
        {label}
      </Form.Label>
      <Col sm="8" className="SelectOption">
        <Select
          name={name}
          value={value}
          options={options}
          onChange={(selectedOption) => handleChange(name, selectedOption)}
          isDisabled={isDisabled}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlSalaryNR = ({
  label,
  name,
  value,
  handleChangeForm,
  type,
  inputText,
  placeholder,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3" style={{ textAlign: "left" }}>
      <Form.Label column sm="4" style={{ fontSize: "15px" }}>
        {label}
      </Form.Label>
      <Col sm="8">
        <InputGroup>
          <InputGroup.Text>{inputText}</InputGroup.Text>
          <Form.Control
            name={name}
            value={value}
            onChange={handleChangeForm}
            required
            type={type}
            placeholder={placeholder}
          />
          <InputGroup.Text>.00</InputGroup.Text>
        </InputGroup>
      </Col>
    </Form.Group>
  );
};

export const FormLabelControlAppendRightNR = ({
  label,
  name,
  value,
  handleChangeForm,
  type,
  inputText,
  placeholder,
  appendLabel,
}) => {
  return (
    <Form.Group as={Row} className="px-2 py-3" style={{ textAlign: "left" }}>
      <Form.Label column sm="4" style={{ fontSize: "15px" }}>
        {label}
      </Form.Label>
      <Col sm="8">
        <InputGroup>
          <Form.Control
            name={name}
            value={value}
            onChange={handleChangeForm}
            required
            type={type}
            placeholder={placeholder}
          />
          <InputGroup.Text>{appendLabel}</InputGroup.Text>
        </InputGroup>
      </Col>
    </Form.Group>
  );
};
