import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../5.Services/apiServices";
import moment from "moment";

const namespace = "log";

export const getLogEmails = createAsyncThunk(
  `${namespace}/emails`,
  async (body, { rejectWithValue }) => {
    const api = "GetEmailsLog";
    const replace = {
      search: "id",
      replace: body,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postCustomEmail = createAsyncThunk(
  `${namespace}/postCustomEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_CustomEmail_Candidate";
    const replace = {
      search: "jobapplication_id",
      replace: body.jobapplication_id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  email: {
    _id: "",
    message: {
      response: "",
    },
    data: {
      from: "career@hsl.com.my",
      to: "",
      subject: "",
      text: "",
    },
    date: "",
    time: "",
  },
  emails: [],
};

const LogSlice = createSlice({
  name: "log",
  initialState,
  reducers: {
    updateEmail(state, action) {
      const data = {
        ...state,
        email: action.payload,
      };
      return data;
    },

    resetEmail(state, action) {
      return {
        ...state,
        email: initialState.email,
      };
    },

    getEmail(state, action) {
      return {
        ...state,
        email: state.emails.find((email) => email._id == action.payload),
      };
    },
  },

  extraReducers: {
    [getLogEmails.pending]: (state, action) => {
      state.loading = true;
    },
    [getLogEmails.fulfilled]: (state, action) => {
      state.loading = false;
      state.emails = [];
      state.emails = [...state.emails, ...action.payload];
    },
    [getLogEmails.rejected]: (state, action) => {
      state.error = true;
      console.error(action.payload);
    },
  },
});

export const { updateEmail, resetEmail, getEmail } = LogSlice.actions;
export default LogSlice.reducer;
