import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import UserService from "../5.Services/UserService";

export const styles = {
  NavbarStyle: { backgroundColor: "#5E8ABB" },
  TextStyle: { fontWeight: "bold", color: "#fdedbc" },
};

const NavbarMenu = () => {
  let navigate = useNavigate();
  const [role, setRole] = useState();

  useEffect(() => {
    const whatrole = UserService.getRoles();
    setRole(whatrole);
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: "Confirm Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.doLogout();
        navigate && navigate("/");
      }
    });
  };

  return (
    <>
      <Navbar style={styles.NavbarStyle}>
        <Container fluid>
          <Navbar.Brand href="#/Homepage">
            <FontAwesomeIcon icon={faHouse} color="#fdedbc" />
          </Navbar.Brand>

          <Nav>
            {role && role.includes("jobapp_hr") && (
              <>
                <NavDropdown
                  title={<span style={styles.TextStyle}> View Other List</span>}
                >
                  <NavDropdown.Item href="#/ArchiveList">
                    Archive List
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#/KIVList">KIV List</NavDropdown.Item>
                  <NavDropdown.Item href="#/DuplicateList">
                    Duplicate List
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link href="#/SourceReport">
                  <span style={styles.TextStyle}>Source Report</span>
                </Nav.Link>

                <Nav.Link href="#/FullApplicationForm">
                  <span style={styles.TextStyle}>Full Form</span>
                </Nav.Link>
              </>
            )}

            <Nav.Link onClick={handleLogout}>
              <span style={styles.TextStyle}>Log Out</span>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};
export default NavbarMenu;
