import { useRef, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import { useSelector, useDispatch } from "react-redux";

import { updateSectionD } from "../4.Store/AssessmentSlice";

const SignatureInterviewer = () => {
  const dispatch = useDispatch();

  const sigCanvas = useRef();
  const [openModel, setOpenModal] = useState(false);
  const [penColor, setPenColor] = useState("black");

  const { sectionD } = useSelector((state) => state.Assessment);
  const { interviewer_signature } = sectionD;

  const colors = ["black", "blue", "red"];

  const create = () => {
    const interviewerURL = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    // console.log(URL);
    const entity = { ...sectionD, interviewer_signature: interviewerURL };
    // console.log(entity);
    // console.log(interviewer_signature);
    dispatch(updateSectionD(entity));
    // console.log(interviewer_signature);
    setOpenModal(false);
  };

  return (
    <>
      <Row style={{ marginTop: "-1rem" }}>
        <Col>
          <Image
            src={interviewer_signature || null}
            className="signature"
            onClick={() => setOpenModal(true)}
          />
          {openModel && (
            <div className="modalContainer">
              <div className="modalContainerBox">
                <div className="sigPad__penColors">
                  <div>
                    <h1>Choose Pen Color:</h1>
                    {colors.map((color) => (
                      <span
                        key={color}
                        style={{
                          backgroundColor: color,
                          border: `${
                            color === penColor ? `2px solid ${color}` : ""
                          }`,
                        }}
                        onClick={() => setPenColor(color)}
                      ></span>
                    ))}
                  </div>
                  <div className="sigPadContainer">
                    <SignatureCanvas
                      penColor={penColor}
                      canvasProps={{ className: "sigCanvas" }}
                      ref={sigCanvas}
                    />
                    <hr />
                    <button onClick={() => sigCanvas.current.clear()}>
                      Clear
                    </button>
                  </div>
                  <div className="modal__bottom">
                    <button onClick={() => setOpenModal(false)}>Cancel</button>
                    <button className="create" onClick={create}>
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
export default SignatureInterviewer;
