export const styles = {
  CardContainer: { backgroundColor: "white", margin: "30px" },
  TitleStyle: {
    fontWeight: "bold",
    // color: "#fdedbc",
    // textShadow: `
    //   -1px -1px 0 #000,
    //    1px -1px 0 #000,
    //   -1px  1px 0 #000,
    //    1px  1px 0 #000
    // `,
  },
  ButtonStyle: {
    backgroundColor: "#fdedbc",
    fontWeight: "bold",
    color: "#000",
  },
  TableHeader: { backgroundColor: "#fdedbc" },
  ThIcon: {
    verticalAlign: "middle",
    textAlign: "center",
  },
  ThText: {
    verticalAlign: "middle",
    textAlign: "left",
  },
  TdIcon: {
    cursor: "pointer",
    textAlign: "center",
  },
  TdText: {
    cursor: "pointer",
    textAlign: "left",
  },
};
