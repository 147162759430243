import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Form, ButtonGroup, Button } from "react-bootstrap";

import { styles } from "../HRFeedbackStyle";

import {
  putApplicationForm,
  getApplicationForm,
  updateHrFeedback,
} from "../../../4.Store/ApplicationFormSlice";
import {
  getCirculationByCirculationId,
  getCirculationByJobAppId,
} from "../../../4.Store/CirculationSlice";
import { getAssessmentByJobAppId } from "../../../4.Store/AssessmentSlice";

const HRSVRemark = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const {
    _id,
    hrRemark,
    nonSarawakian,
    foreigner,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
  } = useSelector((state) => state.ApplicationForm);
  const { jobAppId, svRemark } = useSelector((state) => state.Circulation);

  const handleCheckBoxRemark = (name) => {
    let value = "";
    const entity1 = { ...sectionOne };
    const entity2 = { ...sectionTwo };
    const entity3 = { ...sectionThree };
    const entity4 = { ...sectionFour };
    const entity5 = { ...sectionFive };
    const entity6 = { ...sectionSix };
    const entity7 = { ...sectionSeven };
    switch (name) {
      case "nonSarawakian":
        value = nonSarawakian === "Yes" ? "No" : "Yes";
        dispatch(
          putApplicationForm({
            // id: _id,
            id: jobAppId ? jobAppId : _id,
            nonSarawakian: value,
            hrRemark,
            sectionOne: entity1,
            sectionTwo: entity2,
            sectionThree: entity3,
            sectionFour: entity4,
            sectionFive: entity5,
            sectionSix: entity6,
            sectionSeven: entity7,
          })
        );
        break;
      case "foreigner":
        value = foreigner === "Yes" ? "No" : "Yes";
        dispatch(
          putApplicationForm({
            // id: _id,
            id: jobAppId ? jobAppId : _id,
            foreigner: value,
            hrRemark,
            sectionOne: entity1,
            sectionTwo: entity2,
            sectionThree: entity3,
            sectionFour: entity4,
            sectionFive: entity5,
            sectionSix: entity6,
            sectionSeven: entity7,
          })
        );
        break;
      default:
        break;
    }
    dispatch(getApplicationForm(jobAppId ? jobAppId : _id));
  };

  const handleChangeRemark = (e) => {
    const entity = { hrRemark: e.target.value };
    dispatch(updateHrFeedback(entity));
  };

  const handleClickSaveRemark = () => {
    const entity1 = { ...sectionOne };
    const entity2 = { ...sectionTwo };
    const entity3 = { ...sectionThree };
    const entity4 = { ...sectionFour };
    const entity5 = { ...sectionFive };
    const entity6 = { ...sectionSix };
    const entity7 = { ...sectionSeven };
    dispatch(
      putApplicationForm({
        id: jobAppId ? jobAppId : _id,
        hrRemark,
        sectionOne: entity1,
        sectionTwo: entity2,
        sectionThree: entity3,
        sectionFour: entity4,
        sectionFive: entity5,
        sectionSix: entity6,
        sectionSeven: entity7,
      })
    )
      .then(() => {
        dispatch(getApplicationForm(jobAppId ? jobAppId : _id));
        dispatch(getCirculationByJobAppId(param._id));
        dispatch(getCirculationByCirculationId(param._id)).then((res) => {
          dispatch(getApplicationForm(res.payload.jobAppId));
          dispatch(getAssessmentByJobAppId(res.payload.jobAppId));
          dispatch(getCirculationByJobAppId(res.payload.jobAppId));
        });
      })
      .then(() => {
        Swal.fire("Save Successfully");
      })
      .catch((err) => {
        Swal.fire("Error / Masalah", err.response.data, "error", "OK");
      });
  };

  return (
    <>
      <Row className="py-2">
        <Col md={12}>
          <Form.Label style={styles.TitleStyle}>HR Remark:</Form.Label>
          <Row>
            <Col md="auto" sm="auto">
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Non Sarawakian"
                  onChange={() => handleCheckBoxRemark("nonSarawakian")}
                  checked={nonSarawakian === "Yes"}
                />
              </Form.Group>
            </Col>
            <Col md="auto" sm="auto">
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Foreigner"
                  onChange={() => handleCheckBoxRemark("foreigner")}
                  checked={foreigner === "Yes"}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Control
            name="hrRemark"
            as="textarea"
            rows={3}
            value={hrRemark || ""}
            onChange={handleChangeRemark}
          />
          <ButtonGroup className="mt-3" style={{ float: "right" }}>
            <Button onClick={handleClickSaveRemark} style={styles.ButtonStyle}>
              Save HR Remark
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      <Row className="py-2">
        <Col md={12}>
          <Form.Label style={styles.TitleStyle}>Supervisor Remark:</Form.Label>
          <Form.Control
            name="svRemark"
            as="textarea"
            rows={3}
            value={svRemark || ""}
            readOnly
            style={{ color: "royalblue", fontWeight: "bold" }}
          />
        </Col>
      </Row>
    </>
  );
};
export default HRSVRemark;
