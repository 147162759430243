import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row, Card, Form, Button, ButtonGroup } from "react-bootstrap";
import Swal from "sweetalert2";

import { styles } from "../HRFeedbackStyle";

import {
  updateSuperiorName,
  updateAssignDeptProject,
  putCirculation,
  postCirculationSvEmail,
  getCirculationByCirculationId,
  getCirculationByJobAppId,
  getInternshipDetailsByJobAppId,
} from "../../../4.Store/CirculationSlice";
import { getApplicationForm } from "../../../4.Store/ApplicationFormSlice";
import {
  getAssessmentByJobAppId,
  putAssessmentForm,
} from "../../../4.Store/AssessmentSlice";

const InternshipDetail = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const { _id, hrRemark } = useSelector((state) => state.ApplicationForm);

  const {
    jobAppId,
    svId,
    svName,
    svEmail,
    svRemark,
    ivDate,
    ivTime,
    ivDuration,
    ivMethod,
    ivAMPM,
    superiorName,
    assignDeptProject,
  } = useSelector((state) => state.Circulation);

  // console.log(superiorName);

  const handleChangeSuperiorName = (e) => {
    const entity = { superiorName: e.target.value };
    // console.log(entity);
    dispatch(updateSuperiorName(entity));
  };

  const handleChangeDepartmentProject = (e) => {
    const entity = { assignDeptProject: e.target.value };
    // console.log(entity);
    dispatch(updateAssignDeptProject(entity));
  };

  const handleClickUpdateDetails = () => {
    dispatch(getInternshipDetailsByJobAppId(param._id))
      .then((res) => {
        // console.log(res);
        // console.log(res.payload._id);
        const entity = {
          _id: res.payload._id,
          jobAppId,
          hrRemark,
          svId,
          svName,
          svEmail,
          svRemark,
          ivDate,
          ivTime,
          ivDuration,
          ivMethod,
          circulationStatus: 500,
          ivAMPM,
          superiorName,
          assignDeptProject,
        };
        dispatch(putCirculation(entity));
      })

      // dispatch(putCirculation(entity))
      //   .then(() => {
      //     dispatch(postCirculationSvEmail({ id: param._id }));
      //   })
      //   .then(() => {
      //     dispatch(getCirculationByJobAppId(param._id));
      //     dispatch(getCirculationByCirculationId(param._id)).then((res) => {
      //       dispatch(getApplicationForm(res.payload.jobAppId));
      //       dispatch(getAssessmentByJobAppId(res.payload.jobAppId)).then(
      //         (respond) => {
      //           const array = respond.payload;

      //           array.forEach((item) => {
      //             const id = item._id;
      //             const entity = {
      //               _id: id,
      //               interviewDate: ivDate,
      //               interviewTime: ivTime,
      //               interviewAmPm: ivAMPM,
      //               jobAppId: jobAppId ? jobAppId : _id,
      //             };
      //             dispatch(putAssessmentForm(entity));
      //           });
      //         }
      //       );
      //       dispatch(getCirculationByJobAppId(res.payload.jobAppId));
      //     });
      //   })
      .then(() => {
        Swal.fire("Interview Details Successfully Updated");
      })
      .catch((err) => {
        Swal.fire("Error / Masalah", err.response.data, "error", "OK");
      });
  };

  return (
    <Row className="py-2">
      <Col md={12}>
        <Card>
          <Card.Header style={styles.TitleStyle}>
            Internship Details
          </Card.Header>
          <Card.Body>
            <Row className="my-2 mx-3">
              <Col md={12} sm={12}>
                <Form.Label>
                  Superior Name: <span className="star">*</span>
                </Form.Label>
                <Form.Control
                  name="superiorName"
                  value={superiorName}
                  onChange={handleChangeSuperiorName}
                />
              </Col>
            </Row>
            <Row className="my-3 mx-3">
              <Col md={12} sm={12}>
                <Form.Label>
                  Assign Department or Project: <span className="star">*</span>
                </Form.Label>
                <Form.Control
                  name="assignDeptProject"
                  value={assignDeptProject}
                  onChange={handleChangeDepartmentProject}
                />
              </Col>
            </Row>
            {/* <ButtonGroup className="mt-3" style={{ float: "right" }}>
              <Button
                onClick={handleClickUpdateDetails}
                style={styles.ButtonStyle}
              >
                Update Details
              </Button>
            </ButtonGroup> */}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default InternshipDetail;
