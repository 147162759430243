import { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "../AssessmentFormStyle";

import { updateApproval } from "../../../4.Store/AssessmentSlice";

const Approval = () => {
  const dispatch = useDispatch();

  const [recommendation, setRecommendation] = useState(true);

  const { approval } = useSelector((state) => state.Assessment);
  const { interviewer_recommendation } = approval;

  const handleTickRecommendation = (value) => {
    if (recommendation) {
      setRecommendation(false);
      const entity = { ...approval, interviewer_recommendation: value };
      dispatch(updateApproval(entity));
      // console.log(entity);
    } else {
      setRecommendation(true);
      const entity = { ...approval, interviewer_recommendation: "" };
      dispatch(updateApproval(entity));
      // console.log(entity);
    }
  };

  return (
    <>
      <Form.Label style={styles.TitleStyle}>
        Interviewer’s Recommendation (Please Tick One)
      </Form.Label>

      <div>
        <Form.Check
          type="checkbox"
          label="Hire"
          value="Hire"
          onChange={(e) => handleTickRecommendation(e.target.value)}
          checked={interviewer_recommendation == "Hire"}
        />
        <Form.Check
          type="checkbox"
          label="KIV"
          value="KIV"
          onChange={(e) => handleTickRecommendation(e.target.value)}
          checked={interviewer_recommendation == "KIV"}
        />
        <Form.Check
          type="checkbox"
          label="Not suitable"
          value="Not Suitable"
          onChange={(e) => handleTickRecommendation(e.target.value)}
          checked={interviewer_recommendation == "Not Suitable"}
        />
      </div>
    </>
  );
};
export default Approval;
