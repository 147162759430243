import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavbarMenu from "../2.Common/NavbarMenu";
import jwt_decode from "jwt-decode";
import HR from "../asset/HR.png";
import SV from "../asset/SV.png";

const Homepage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const accessToken = localStorage.getItem("access_token");
  const token = jwt_decode(accessToken);

  const isHR = token?.realm_access?.roles?.includes("jobapp_hr");
  const isSV = token.realm_access?.roles?.includes("jobapp_sv");

  // console.log(isHR);

  const handleClickHR = () => {
    navigate("/HRView");
  };
  const handleClickSV = () => {
    navigate("/SVView");
  };

  return (
    <>
      <NavbarMenu />
      <Container className="px-3">
        <Row className="mx-md-5 my-3 mx-sm-5">
          {isHR && (
            <Col md={12} sm={12}>
              <Card
                type="button"
                onClick={handleClickHR}
                className="mx-auto cardHomepage"
              >
                <Card.Img
                  variant="top"
                  src={HR}
                  style={{
                    height: "150px",
                    width: "auto",
                    marginTop: "1em",
                    marginBottom: "1em",
                    border: "1px solid white",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    HUMAN RESOURCE
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>

        <Row className="mx-md-5 my-3 mx-sm-5">
          {isSV && (
            <Col md={12} sm={12}>
              <Card
                type="button"
                onClick={handleClickSV}
                className="mx-auto cardHomepage"
              >
                <Card.Img
                  variant="top"
                  src={SV}
                  style={{
                    height: "150px",
                    width: "auto",
                    marginTop: "1em",
                    marginBottom: "1em",
                    border: "1px solid white",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <Card.Body>
                  <Card.Title className="text-center">SUPERVISOR</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};
export default Homepage;
