import axios from "axios";

export const apis = [
  //Supervisors
  {
    name: "GetSupervisorList",
    method: "GET",
    endpoint: "/api/supervisors/lists/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },

  //Upload Files
  {
    name: "UploadFile",
    method: "POST",
    endpoint: "/api/uploads/upload",
  },
  {
    name: "GetUploadFile",
    method: "GET",
    endpoint: "/api/uploads/upload/path/fileName2",
  },

  //Applications
  {
    name: "CreateApplication",
    method: "POST",
    endpoint: "/api/applications/jobapplications",
  },
  {
    name: "UpdateApplication",
    method: "PUT",
    endpoint: "/api/applications/jobapplications/id",
  },
  {
    name: "GetApplication",
    method: "GET",
    endpoint: "/api/applications/jobapplications/id",
  },

  //Emails
  {
    name: "Create_ThankyouEmail_Candidate",
    method: "POST",
    // endpoint: "/api/emails/thankyou/application/jobapplication_id",
    endpoint: "/api/emails/candidate/create/application/jobapplication_id",
  },
  {
    name: "Create_UpdateEmail_Candidate",
    method: "POST",
    // endpoint: "/api/emails/update/application/jobapplication_id",
    endpoint: "/api/emails/candidate/update/application/jobapplication_id",
  },
  {
    name: "Create_CustomEmail_Candidate",
    method: "POST",
    endpoint: "/api/emails/custom/create/email/jobapplication_id",
  },
  {
    name: "Create_CirculationEmail_Supervisor",
    method: "POST",
    // endpoint: "/api/emails/circulations/application/circulations_id",
    endpoint: "/api/emails/supervisor/create/circulation/circulations_id",
  },
  {
    name: "Create_NotificationNewApplicationEmail_HR",
    method: "POST",
    // endpoint: "/api/emails/notification/jobapplication_id",
    endpoint: "/api/emails/notification/create/application/jobapplication_id",
  },
  {
    name: "Create_NotificationUpdateApplicationEmail_HR",
    method: "POST",
    endpoint: "/api/emails/update/afterapplication/jobapplication_id",
  },
  {
    name: "Create_NotificationUpdateCirculationEmail_SV",
    method: "POST",
    // endpoint: "/api/emails/circulations/notification/circulations_id",
    endpoint: "/api/emails/notification/update/circulation/circulations_id",
  },
  {
    name: "Create_NewAssessmentEmail_Interviewer",
    method: "POST",
    // endpoint: "/api/emails/assesments/application/assesment_id",
    endpoint: "/api/emails/supervisor/create/interview/assesment_id",
  },
  {
    name: "Create_NotificationContactCandidateEmail_HR",
    method: "POST",
    // endpoint: "/api/emails/update/application/hr/jobapplication_id",
    endpoint: "/api/emails/notification/update/application/jobapplication_id",
  },
  {
    name: "Create_NotificationSubmitCirculation_HR",
    method: "POST",
    endpoint: "/api/emails/circulations/application/hr/circulation_id",
  },
  {
    name: "Create_NotificationSubmitInterviewer_HR",
    method: "POST",
    // endpoint: "/api/emails/shortlisted/application/hr/jobapplication_id",
    endpoint: "/api/emails/notification/create/assesments/assesment_id",
  },
  {
    name: "Create_ContactCandidateForIVEmail_Candidate",
    method: "POST",
    endpoint: "/api/emails/candidate/create/interview/circulation_id",
  },
  {
    name: "Create_InterviewCancelEmail_Supervisor",
    method: "POST",
    endpoint: "/api/emails/supervisor/cancel/circulation/circulaton_id",
  },
  {
    name: "Create_InterviewCancelEmail_Interviewer",
    method: "POST",
    endpoint: "/api/emails/supervisor/cancel/interview/interview_id",
  },
  {
    name: "Create_RejectInternshipEmail_Candidate",
    method: "POST",
    endpoint: "/api/emails/candidate/reject/internship/jobapplication_id",
  },

  //Circulations
  {
    name: "CreateCirculation",
    method: "POST",
    endpoint: "/api/circulations/circulations",
  },
  {
    name: "UpdateCirculation",
    method: "PUT",
    endpoint: "/api/circulations/circulations/circulation_id",
  },
  {
    name: "GetCirculation_CirculationId",
    method: "GET",
    endpoint:
      "/api/circulations/circulations/circulation_id/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "GetCirculation_JobappId",
    method: "GET",
    endpoint:
      "/api/circulations/circulations/to/jobapp_id/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "GetInternshipDetails_JobappId",
    method: "GET",
    endpoint:
      "/api/circulations/circulations/internship/jobapp_id/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },

  //Assessment
  {
    name: "CreateAssessment",
    method: "POST",
    endpoint: "/api/assesments/assesments",
  },
  {
    name: "UpdateAssessment",
    method: "PUT",
    endpoint: "/api/assesments/assesments/{assesment_id}",
  },
  {
    name: "GetAssessment",
    method: "GET",
    endpoint:
      "/api/assesments/assesments/{assesment_id}/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "GetAssessment_JobappId",
    method: "GET",
    endpoint:
      "/api/assesments/assesments/jobapp/{jobapp_id}/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },

  //Download File Excel
  {
    name: "CreateExcel_ApplicationList",
    method: "POST",
    endpoint: "/api/excel/excel",
  },
  {
    name: "CreateExcel_ApprovedInterviewList",
    method: "POST",
    endpoint: "/api/excel/excel/circulation",
  },
  {
    name: "CreateExcel_AssessmentList",
    method: "POST",
    endpoint: "/api/excel/excel/assessment",
  },

  //List By Pagination
  {
    name: "Pagination_GetAll",
    method: "GET",
    endpoint:
      "/api/pagination/jobapplications/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Pagination_GetByStatus",
    method: "GET",
    endpoint:
      "/api/pagination/jobapplications/status/statusnumber/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Pagination_GetInternship",
    method: "GET",
    endpoint:
      "/api/pagination/jobapplications/internship/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Pagination_GetCirculation",
    method: "GET",
    endpoint:
      "/api/pagination/circulations/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Pagination_GetAssessment",
    method: "GET",
    endpoint:
      "/api/pagination/assesments/status/statusnumber/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Pagination_GetSVApplication",
    method: "GET",
    endpoint:
      "/api/pagination/circulations/sv/sv_id/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Pagination_GetSVAssessment",
    method: "GET",
    endpoint:
      "/api/pagination/assesments/sv/sv_id/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },

  //List By Search
  {
    name: "Search_GetAll",
    method: "GET",
    endpoint:
      "/api/search/jobapplications/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Search_GetByStatus",
    method: "GET",
    endpoint:
      "/api/search/jobapplications/status/statusnumber/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Search_GetInternship",
    method: "GET",
    endpoint:
      "/api/search/jobapplications/internship/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Search_GetCirculation",
    method: "GET",
    endpoint:
      "/api/search/circulations/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Search_GetAssessment",
    method: "GET",
    endpoint:
      "/api/search/assesments/status/statusnumber/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Search_GetSVApplication",
    method: "GET",
    endpoint:
      "/api/search/circulations/sv/sv_id/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },
  {
    name: "Search_GetSVAssessment",
    method: "GET",
    endpoint:
      "/api/search/assesments/sv/sv_id/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },

  //Email Log
  {
    name: "GetEmailsLog",
    method: "GET",
    endpoint: "/api/logs/emails/jobapplication/id",
  },

  //Job Vacancies
  {
    name: "GetVacancyList",
    method: "GET",
    endpoint: "/api/vacancy/lists/9f56d9b292b55162ca3999b56f054b407a2ae67e",
  },

  //Source Report
  {
    name: "CreateReportList_Source",
    method: "POST",
    endpoint: "/api/sources",
  },

  //Push Notification
  // {
  //   name: "CreateNotificationCirculation",
  //   method: "POST",
  //   endpoint:
  //     "/api/testnotifications/notificiation-circulation/{circulation_id}/supervisor/{sv_id}",
  //   // endpoint:"/api/notifications/send-push-notification/circulation/circulation_id/sv/sv_id",
  // },
  // {
  //   name: "CreateNotificationAssessment",
  //   method: "POST",
  //   endpoint:
  //     "/api/testnotifications/notificiation-assessment/{assessment_id}/supervisor/{interviewer_id}",
  //   // endpoint:"/api/notifications/send-push-notification/assessment/:assessment_id/interviewer/interviewer_id",
  // },
  {
    name: "TestNotification",
    method: "POST",
    endpoint:
      "/api/testnotifications/circulation/{circulation_id}/supervisor/{sv_id}",
    // "/api/testnotifications/cc",
  },
  {
    name: "TestNotificationAssessment",
    method: "POST",
    endpoint:
      "/api/testnotifications/assessment/{assessment_id}/supervisor/{interviewer_id}",
  },
];

export const apiToken = {
  // ip: "192.168.5.174:5000",
  // ip: "jobapp.hslnet.my",
  ip: "mobiletesting.hslnet.my",
};

// export const apiURL = `http://${apiToken.ip}`;
export const apiURL = `https://${apiToken.ip}`;

export const apiServices = async (service, opts) => {
  const api = apis.find((api) => api.name === service);

  // let config = {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "true",
  //   "Access-Control-Allow-Credentials": "true",
  // };

  let config = {
    "Access-Control-Allow-Origin": "true",
    "Access-Control-Allow-Credentials": "true",
  };

  if ("uploads" == service) {
    config = {
      headers: { "Content-Type": "multipart/form-data" },
      "Access-Control-Allow-Origin": "true",
      "Access-Control-Allow-Credentials": "true",
    };
  }

  //if API not found
  if (api === undefined) {
    if (opts?.onFailure !== undefined) opts.onFailure("API Not Found");
    return;
  }

  const method = api.method;
  let endpoint = api.endpoint;

  if (opts?.replace !== undefined) {
    if (Array.isArray(opts.replace)) {
      opts.replace.forEach((replace) => {
        endpoint = endpoint.replace(replace.search, replace.replace);
        endpoint = endpoint.replace(replace.search2, replace.replace2);
      });
    } else {
      endpoint = endpoint.replace(opts?.replace.search, opts?.replace.replace);
      endpoint = endpoint.replace(
        opts?.replace.search2,
        opts?.replace.replace2
      );
    }
  }

  // console.log(endpoint);

  let response = "";
  let data = {};

  // if (method === "GET") {
  //   response = axios.get(`${apiURL}${endpoint}`, config);
  // }

  if (method === "GET") {
    response = axios.get(`${apiURL}${endpoint}`, {
      ...config,
      params: opts?.params,
    }); // Include the params property in the request
  }

  // if (method === "POST") {
  //   const body = opts?.body === undefined ? {} : opts?.body;
  //   response = axios.post(`${apiURL}${endpoint}`, body, config);
  // }

  if (method === "POST") {
    const body = opts?.body === undefined ? {} : opts?.body;
    // response = axios.post(`${apiURL}${endpoint}`, body, config);
    response = axios({
      method: "post",
      url: `${apiURL}${endpoint}`,
      data: body,
      headers: config,
    });
  }

  if (method === "PUT") {
    const body = opts?.body === undefined ? {} : opts?.body;
    response = axios.put(`${apiURL}${endpoint}`, body, config);
  }

  return response;
};
