import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form, Row, Col } from "react-bootstrap";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

import { styles } from "./ApplicationFormStyle";
import {
  FormLabelControlTextFullNR,
  FormLabelControlTextNR,
  FormLabelControlTextNRNoIcon,
} from "../../2.Common/NotRequiredForm";
import { FormLabelSelect } from "../../2.Common/RequiredForm";
import PDFViewer from "../../2.Common/PDFViewer.jsx";
import { RateSkillList } from "../../2.Common/DropdownOptions";
import UploadEduPrimaryCert from "../../2.Common/UploadEduPrimaryCert.jsx";
import UploadEduSecondaryCert from "../../2.Common/UploadEduSecondaryCert.jsx";
import UploadEduTertiaryCert from "../../2.Common/UploadEduTertiaryCert.jsx";
import UploadEduOtherCert from "../../2.Common/UploadEduOtherCert.jsx";

import { updateSectionThree } from "../../4.Store/ApplicationFormSlice";

const SectionThree = () => {
  const dispatch = useDispatch();

  const [viewPdf, setViewPdf] = useState(undefined);

  const { sectionThree } = useSelector((state) => state.ApplicationForm);
  const {
    professional_affiliate,
    computer_skills,
    other_relevant_skills,
    extra_curricular_activities,
    english_written_skill,
    english_oral_skill,
    bahasa_written_skill,
    bahasa_oral_skill,
    mandarin_written_skill,
    mandarin_oral_skill,
    other_written_skill,
    other_oral_skill,

    primaryEduName,
    primaryEduTown,
    primaryEduYear,
    primaryEduQualification,
    primaryEduUploadFile,

    secondaryEduName,
    secondaryEduTown,
    secondaryEduYear,
    secondaryEduQualification,
    secondaryEduUploadFile,

    tertiaryEduName,
    tertiaryEduTown,
    tertiaryEduYear,
    tertiaryEduQualification,
    tertiaryEduUploadFile,

    otherEduName,
    otherEduTown,
    otherEduYear,
    otherEduQualification,
    otherEduUploadFile,
  } = sectionThree;

  const handleChangeForm = (e) => {
    e.persist();
    const entity = {
      ...sectionThree,
      [e.target.name]: e.target.value.toUpperCase(),
    };
    dispatch(updateSectionThree(entity));
    // console.log(entity);
  };

  const handleChangeDropdown = (name, value) => {
    const entity = { ...sectionThree, [name]: value };
    switch (name) {
      case "english_written_skill":
        dispatch(updateSectionThree(entity));
        break;
      case "english_oral_skill":
        dispatch(updateSectionThree(entity));
        break;
      case "bahasa_written_skill":
        dispatch(updateSectionThree(entity));
        break;
      case "bahasa_oral_skill":
        dispatch(updateSectionThree(entity));
        break;
      case "mandarin_written_skill":
        dispatch(updateSectionThree(entity));
        break;
      case "mandarin_oral_skill":
        dispatch(updateSectionThree(entity));
        break;
    }
  };

  const handlePdfViewer = () => {
    setViewPdf(undefined);
  };

  return (
    <>
      {viewPdf && <PDFViewer onClose={handlePdfViewer} fileObject={viewPdf} />}
      {viewPdf === undefined && (
        <Container fluid>
          <Form>
            <h5 className="px-2" style={styles.TextHeader5}>
              (A) Language
            </h5>
            <Row>
              <Col md={2} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • English Language:
                </h6>
              </Col>
              <Col md={5} sm={12}>
                <FormLabelSelect
                  label="Written Level"
                  name="english_written_skill"
                  value={english_written_skill}
                  options={RateSkillList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={5} sm={12}>
                <FormLabelSelect
                  label="Spoken Level"
                  name="english_oral_skill"
                  value={english_oral_skill}
                  options={RateSkillList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • Bahasa Malaysia:
                </h6>
              </Col>
              <Col md={5} sm={12}>
                <FormLabelSelect
                  label="Written Level"
                  name="bahasa_written_skill"
                  value={bahasa_written_skill}
                  options={RateSkillList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={5} sm={12}>
                <FormLabelSelect
                  label="Spoken Level"
                  name="bahasa_oral_skill"
                  value={bahasa_oral_skill}
                  options={RateSkillList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • Mandarin:
                </h6>
              </Col>
              <Col md={5} sm={12}>
                <FormLabelSelect
                  label="Written Level"
                  name="mandarin_written_skill"
                  value={mandarin_written_skill}
                  options={RateSkillList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={5} sm={12}>
                <FormLabelSelect
                  label="Spoken Level"
                  name="mandarin_oral_skill"
                  value={mandarin_oral_skill}
                  options={RateSkillList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
            </Row>

            <h6 className="px-2 py-1" style={styles.TextHeader6}>
              • Other Language (If Any):
            </h6>
            <FormLabelControlTextFullNR
              label="Spoken"
              placeholder="Enter here"
              name="other_oral_skill"
              value={other_oral_skill}
              handleChangeForm={handleChangeForm}
            />
            <FormLabelControlTextFullNR
              label="Writen"
              placeholder="Enter here"
              name="other_written_skill"
              value={other_written_skill}
              handleChangeForm={handleChangeForm}
            />

            <h5 className="px-2" style={styles.TextHeader5}>
              (B) Skill
            </h5>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlTextNR
                  label="Professional Affiliate"
                  placeholder="Enter here"
                  name="professional_affiliate"
                  value={professional_affiliate}
                  handleChangeForm={handleChangeForm}
                  icon={faCircleQuestion}
                  tooltip="Example: MIA / BEM"
                  tooltipId="proaff-tooltip"
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Computer Skill"
                  placeholder="Enter here"
                  name="computer_skills"
                  value={computer_skills}
                  handleChangeForm={handleChangeForm}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Extra Curricular Activities"
                  placeholder="Enter here"
                  name="extra_curricular_activities"
                  value={extra_curricular_activities}
                  handleChangeForm={handleChangeForm}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Other Relevant Skills"
                  placeholder="Enter here"
                  name="other_relevant_skills"
                  value={other_relevant_skills}
                  handleChangeForm={handleChangeForm}
                />
              </Col>
            </Row>

            <h5 className="px-2" style={styles.TextHeader5}>
              (C) Education History
            </h5>
            <Row>
              <Col md={2} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • Primary Education:
                </h6>
              </Col>
              <Col md={10} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Institute Name"
                  placeholder="Please select"
                  name="primaryEduName"
                  value={primaryEduName}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Institute Location"
                  placeholder="Please select"
                  name="primaryEduTown"
                  value={primaryEduTown}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Year Attended"
                  placeholder="Please select"
                  name="primaryEduYear"
                  value={primaryEduYear}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Qualification"
                  placeholder="Please select"
                  name="primaryEduQualification"
                  value={primaryEduQualification}
                  handleChangeForm={handleChangeForm}
                />
                <UploadEduPrimaryCert />
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • Secondary Education:
                </h6>
              </Col>
              <Col md={10} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Institute Name"
                  placeholder="Please select"
                  name="secondaryEduName"
                  value={secondaryEduName}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Institute Location"
                  placeholder="Please select"
                  name="secondaryEduTown"
                  value={secondaryEduTown}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Year Attended"
                  placeholder="Please select"
                  name="secondaryEduYear"
                  value={secondaryEduYear}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Qualification"
                  placeholder="Please select"
                  name="secondaryEduQualification"
                  value={secondaryEduQualification}
                  handleChangeForm={handleChangeForm}
                />
                <UploadEduSecondaryCert />
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • Tertiary Education:
                </h6>
              </Col>
              <Col md={10} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Institute Name"
                  placeholder="Please select"
                  name="tertiaryEduName"
                  value={tertiaryEduName}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Institute Location"
                  placeholder="Please select"
                  name="tertiaryEduTown"
                  value={tertiaryEduTown}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Year Attended"
                  placeholder="Please select"
                  name="tertiaryEduYear"
                  value={tertiaryEduYear}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Qualification"
                  placeholder="Please select"
                  name="tertiaryEduQualification"
                  value={tertiaryEduQualification}
                  handleChangeForm={handleChangeForm}
                />
                <UploadEduTertiaryCert />
              </Col>
            </Row>
            <Row>
              <Col md={2} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • Other Education:
                </h6>
              </Col>
              <Col md={10} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Institute Name"
                  placeholder="Please select"
                  name="otherEduName"
                  value={otherEduName}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Institute Location"
                  placeholder="Please select"
                  name="otherEduTown"
                  value={otherEduTown}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Year Attended"
                  placeholder="Please select"
                  name="otherEduYear"
                  value={otherEduYear}
                  handleChangeForm={handleChangeForm}
                />
                <FormLabelControlTextNRNoIcon
                  label="Qualification"
                  placeholder="Please select"
                  name="otherEduQualification"
                  value={otherEduQualification}
                  handleChangeForm={handleChangeForm}
                />
                <UploadEduOtherCert />
              </Col>
            </Row>
          </Form>
        </Container>
      )}
    </>
  );
};
export default SectionThree;
