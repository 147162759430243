import FormWizard from "react-form-wizard-component";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container } from "react-bootstrap";

import { styles } from "../1.Pages/InternshipForm/InternshipFormStyle";
import { FormHeaderInternship } from "../2.Common/FormHeader";
import SectionOneInternship from "../1.Pages/InternshipForm/SectionOneInternship";
import SectionTwo from "../1.Pages/ApplicationForm/SectionTwo";
import SectionThree from "../1.Pages/ApplicationForm/SectionThree";
import SectionFour from "../1.Pages/ApplicationForm/SectionFour";
import SectionFive from "../1.Pages/ApplicationForm/SectionFive";
import SectionSix from "../1.Pages/ApplicationForm/SectionSix";
import SectionSeven from "../1.Pages/ApplicationForm/SectionSeven";
import SectionEight from "../1.Pages/ApplicationForm/SectionEight";
import InternshipFormViewSite from "./InternshipFormViewSite";

import {
  getApplicationForm,
  putApplicationForm,
  postThankyouEmail,
  postHrNotificationUpdateJobApplicationEmail,
} from "../4.Store/ApplicationFormSlice";

const InternshipFormSite = () => {
  const param = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (param._id) {
      dispatch(getApplicationForm(param._id));
    }
  }, [param._id]);

  const {
    _id,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    sectionEight,
    dateUpdated,
    isUpdatedByCandidate,
  } = useSelector((state) => state.ApplicationForm);
  const {
    image,
    position_applied,
    internship,
    name,
    identification_certification,
    gender,
    date_of_birth,
    age,
    contact_number,
    email,
    highest_qualification,
    institutionName,
    studyField,
    studyField_other,
    internshipStartDate,
    internshipFinishDate,
    internshipPeriod,
    internshipPeriodWeek,
    internshipPeriodMonth,
    continueWorkAfterInternship,
    relocate,
    current_location,
    current_location_other,
    cover_letter,
    resume,
    certificate,
    acknowledgement1,
  } = sectionOne;
  const { marital_status, emergency_references } = sectionTwo;
  const {
    english_written_skill,
    english_oral_skill,
    bahasa_written_skill,
    bahasa_oral_skill,
    mandarin_written_skill,
    mandarin_oral_skill,
  } = sectionThree;
  const {
    currentEmployer,
    currentPosition,
    currentTelephone,
    currentAddress,
    currentDuties,
    currentReason,
    currentSalary,
    currentFromDate,
    currentToDate,
    relatives,
    relatives_name,
    relatives_position,
    isHavePreviousEmployement,
    isCurrentWorking,
    currentFromMonth,
    currentFromYear,
    currentToMonth,
    currentToYear,
  } = sectionFour;
  const { references } = sectionFive;
  const {
    date_to_work,
    enquiry_previos_employer,
    bankrupt,
    arrested_indicted_convicted,
  } = sectionSix;
  const { essay, tellUsHr } = sectionSeven;
  const { acknowledgement2 } = sectionEight;

  const handleComplete = () => {
    if (acknowledgement2) {
      const entity = {
        id: _id,
        sectionOne,
        sectionTwo,
        sectionThree,
        sectionFour,
        sectionFive,
        sectionSix,
        sectionSeven,
        sectionEight,
        isUpdatedByCandidate: 1,
        dateUpdated,
      };

      // console.log(entity);

      dispatch(putApplicationForm(entity))
        .unwrap()
        .then(() => {
          dispatch(postThankyouEmail({ id: _id }));
          dispatch(postHrNotificationUpdateJobApplicationEmail({ id: _id }));
        })
        .then(() => {
          Swal.fire("Form Updated Successfully.");
        })
        .then(() => {
          window.location.href = "https://www.hsl.com.my/about/career/";
        })
        .catch((err) => {
          // console.log(err);
          Swal.fire("Error / Masalah", err, "error", "OK");
        });
    } else {
      return Swal.fire({
        icon: "error",
        html: `<b style="color:red;">Please Tick Declaration!</b>`,
      });
    }
  };

  // check validate tab
  let errors1,
    errors2,
    errors3,
    errors4,
    errors5,
    errors6,
    errors7 = "";
  const checkValidateTab1 = () => {
    if (image == "") {
      errors1 = { section: 1, part: "Profile Picture" };
      return false;
    }

    if (position_applied == "") {
      errors1 = { section: 1, part: "Position Applied" };
      return false;
    }

    if (name == "") {
      errors1 = { section: 1, part: "Full Name" };
      return false;
    }

    if (identification_certification == "") {
      errors1 = { section: 1, part: "Identification Certification" };
      return false;
    }

    if (gender == "") {
      errors1 = { section: 1, part: "Gender" };
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email == "") {
      errors1 = { section: 1, part: "Valid Email" };
      return false;
    } else if (!emailRegex.test(email)) {
      errors1 = { section: 1, part: "Valid Email" };
      return false;
    }

    if (date_of_birth == "") {
      errors1 = { section: 1, part: "Date of Birth" };
      return false;
    }

    if (contact_number == "") {
      errors1 = { section: 1, part: "Contact Number" };
      return false;
    }

    if (institutionName == "") {
      errors1 = { section: 1, part: "Institution Name" };
      return false;
    }

    if (highest_qualification == "") {
      errors1 = { section: 1, part: "Highest Qualification" };
      return false;
    }

    if (studyField == "") {
      errors1 = { section: 1, part: "Field of Study" };
      return false;
    }

    if (internshipStartDate == "") {
      errors1 = { section: 1, part: "Start Date" };
      return false;
    }

    if (internshipFinishDate == "") {
      errors1 = { section: 1, part: "Finish Date" };
      return false;
    }

    if (continueWorkAfterInternship == "") {
      errors1 = { section: 1, part: "Availability To Work After Internship" };
      return false;
    }

    if (current_location == "") {
      errors1 = { section: 1, part: "Current Location" };
      return false;
    }

    if (relocate == "") {
      errors1 = { section: 1, part: "Relocate Outstation" };
      return false;
    }
  };
  const checkValidateTab2 = () => {
    if (marital_status == "") {
      errors2 = { section: 2, part: "Marital Status" };
      return false;
    }

    if (emergency_references.length == 0) {
      errors2 = { section: 2, part: "Emergency References" };
      return false;
    }
  };
  const checkValidateTab3 = () => {
    if (english_written_skill == "") {
      errors3 = { section: 3, part: "English Written Skill" };
      return false;
    }

    if (english_oral_skill == "") {
      errors3 = { section: 3, part: "English Oral Skill" };
      return false;
    }

    if (bahasa_written_skill == "") {
      errors3 = { section: 3, part: "Bahasa Written Skill" };
      return false;
    }

    if (bahasa_oral_skill == "") {
      errors3 = { section: 3, part: "Bahasa Oral Skill" };
      return false;
    }

    if (mandarin_written_skill == "") {
      errors3 = { section: 3, part: "Mandarin Written Skill" };
      return false;
    }

    if (mandarin_oral_skill == "") {
      errors3 = { section: 3, part: "Mandarin Oral Skill" };
      return false;
    }
  };
  const checkValidateTab4 = () => {
    if (currentEmployer == "") {
      errors4 = { section: 4, part: "Current Employer Name" };
      return false;
    }
    if (currentAddress == "") {
      errors4 = { section: 4, part: "Current Employer Address" };
      return false;
    }
    if (currentTelephone == "") {
      errors4 = { section: 4, part: "Current Employer Contact Number" };
      return false;
    }
    if (currentPosition == "") {
      errors4 = { section: 4, part: "Current Position Held" };
      return false;
    }
    if (currentSalary == "") {
      errors4 = { section: 4, part: "Current Salary" };
      return false;
    }
    if (currentDuties == "") {
      errors4 = { section: 4, part: "Current Duties" };
      return false;
    }
    if (currentReason == "") {
      errors4 = { section: 4, part: "Current Reason(s) For Leaving" };
      return false;
    }
    if (isCurrentWorking == "") {
      errors4 = { section: 4, part: "Currently Working?" };
      return false;
    }

    if (currentFromMonth == "") {
      errors4 = { section: 4, part: "From Month" };
      return false;
    }
    if (currentFromYear == "") {
      errors4 = { section: 4, part: "From Year" };
      return false;
    }
    if (isCurrentWorking == "No" && currentToMonth == "") {
      errors4 = { section: 4, part: "To Month" };
      return false;
    }

    if (isCurrentWorking == "No" && currentToYear == "") {
      errors4 = { section: 4, part: "To Year" };
      return false;
    }

    if (isHavePreviousEmployement == "") {
      errors4 = { section: 4, part: "Previous Employment? " };
      return false;
    }
    if (relatives == "") {
      errors4 = { section: 4, part: "Relatives" };
      return false;
    }
  };
  const checkValidateTab5 = () => {
    if (references.length == 0) {
      errors5 = { section: 5, part: "References" };
      return false;
    }
  };
  const checkValidateTab6 = () => {
    if (date_to_work == "") {
      errors6 = { section: 6, part: "Date To Work" };
      return false;
    }
    if (enquiry_previos_employer == "") {
      errors6 = { section: 6, part: "Enquiry Previous Employer" };
      return false;
    }
    if (bankrupt == "") {
      errors6 = { section: 6, part: "Bankrupt / Debt Repayment" };
      return false;
    }
    if (arrested_indicted_convicted == "") {
      errors6 = {
        section: 6,
        part: "Arrested / Indicted / Convicted",
      };
      return false;
    }
  };
  const checkValidateTab7 = () => {
    if (essay.trim().split(/\s+/).length < 50) {
      errors7 = {
        section: 7,
        part: "Please Enter At Least 150 Words In Part 1",
      };
      return false;
    }
    if (tellUsHr.trim().split(/\s+/).length < 50) {
      errors7 = {
        section: 7,
        part: "Please Enter At Least 100 Words In Part 2",
      };
      return false;
    }
  };

  // error messages
  const errorMessages1 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors1.section} : ${errors1.part}</b>`,
    });
  };
  const errorMessages2 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors2.section} : ${errors2.part}</b>`,
    });
  };
  const errorMessages3 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors3.section} : ${errors3.part}</b>`,
    });
  };
  const errorMessages4 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors4.section} : ${errors4.part}</b>`,
    });
  };
  const errorMessages5 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors5.section} : ${errors5.part}</b>`,
    });
  };
  const errorMessages6 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors6.section} : ${errors6.part}</b>`,
    });
  };
  const errorMessages7 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b style="color:red;">Section ${errors7.section} : ${errors7.part}</b>`,
    });
  };

  return (
    <Container fluid>
      <Card style={styles.CardContainer}>
        {/* <FormHeaderInternship />
        {param._id ? (
          isUpdatedByCandidate == 1 ? (
            <InternshipFormViewSite />
          ) : (
            <>
              <FormWizard stepSize="sm" onComplete={handleComplete}>
                <FormWizard.TabContent title="Biodata">
                  <SectionOneInternship />
                </FormWizard.TabContent>

                <FormWizard.TabContent
                  title="Family"
                  isValid={checkValidateTab1()}
                  validationError={errorMessages1}
                >
                  <SectionTwo />
                </FormWizard.TabContent>

                <FormWizard.TabContent
                  title="Language, Skill & Education"
                  isValid={checkValidateTab2()}
                  validationError={errorMessages2}
                >
                  <SectionThree />
                </FormWizard.TabContent>

                <FormWizard.TabContent
                  title="Work Experience"
                  isValid={checkValidateTab3()}
                  validationError={errorMessages3}
                >
                  <SectionFour />
                </FormWizard.TabContent>

                <FormWizard.TabContent
                  title="References"
                  isValid={checkValidateTab4()}
                  validationError={errorMessages4}
                >
                  <SectionFive />
                </FormWizard.TabContent>

                <FormWizard.TabContent
                  title="Other Informations"
                  isValid={checkValidateTab5()}
                  validationError={errorMessages5}
                >
                  <SectionSix />
                </FormWizard.TabContent>

                <FormWizard.TabContent
                  title="Writing Skills"
                  isValid={checkValidateTab6()}
                  validationError={errorMessages6}
                >
                  <SectionSeven />
                </FormWizard.TabContent>

                <FormWizard.TabContent
                  title="Acknowledgement"
                  isValid={checkValidateTab7()}
                  validationError={errorMessages7}
                >
                  <SectionEight />
                </FormWizard.TabContent>
              </FormWizard>
            </>
          )
        ) : (
          <SectionOneInternship />
        )} */}
        <FormHeaderInternship />
        <SectionOneInternship />
      </Card>
    </Container>
  );
};

export default InternshipFormSite;
