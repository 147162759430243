import { Container } from "react-bootstrap";

const styles = {
  Text: { textAlign: "center", fontWeight: "bold" },
};

export const FormHeaderApplication = () => {
  return (
    <>
      <Container>
        <h3 style={styles.Text}>THE HOCK SENG LEE GROUP OF COMPANIES</h3>
        <h5 style={styles.Text}>EMPLOYMENT APPLICATION FORM</h5>
      </Container>
    </>
  );
};

export const FormHeaderInternship = () => {
  return (
    <>
      <Container>
        <h3 style={styles.Text}>THE HOCK SENG LEE GROUP OF COMPANIES</h3>
        <h5 style={styles.Text}>INTERNSHIP APPLICATION FORM</h5>
      </Container>
    </>
  );
};

export const FormHeaderAssessment = () => {
  return (
    <>
      <Container>
        <h3 style={styles.Text}>THE HOCK SENG LEE GROUP OF COMPANIES</h3>
        <h5 style={styles.Text}>INTERVIEW ASSESSMENT FORM</h5>
      </Container>
    </>
  );
};
