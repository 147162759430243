export const styles = {
  TableHeader: { backgroundColor: "#fdedbc" },
  ThIcon: {
    padding: "10px",
    fontSize: "18px",
    verticalAlign: "middle",
    textAlign: "center",
  },
  ThText: {
    padding: "10px",
    fontSize: "18px",
    verticalAlign: "middle",
    textAlign: "left",
  },
  TdIcon: {
    cursor: "pointer",
    textAlign: "center",
  },
  TdText: {
    cursor: "pointer",
    textAlign: "left",
  },
  TdIconStyle: {
    // border: "1px solid black",
    padding: "2px",
    borderRadius: "5px",
    cursor: "pointer",
  },
};
