import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { getHRAssessmentListPagination } from "../../4.Store/HRTableListSlice";

const TitleAssessmentList = () => {
  const dispatch = useDispatch();

  const { assessmentList, currentHRAssessmentPage } = useSelector(
    (store) => store.HRTableList
  );
  const { approval } = useSelector((state) => state.Assessment);

  const calculateNewCount = () => {
    return assessmentList.filter(
      (item) => item.approval?.interviewer_recommendation == ""
    ).length;
  };

  // const calculateNewCount = () => {
  //   if (!approval.interviewer_recommendation) {
  //     {
  //       return assessmentList.filter(
  //         (item) => item.approval?.interviewer_recommendation == ""
  //       ).length;
  //     }
  //   }
  // };

  return (
    <>
      <span>Assessment List</span>&nbsp;
      <span
        className="badge"
        style={{ border: "1px solid white", backgroundColor: "#5E8ABB" }}
      >
        {calculateNewCount()}
      </span>
    </>
  );
};

export default TitleAssessmentList;
