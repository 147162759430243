export const styles = {
  CardContainer: { backgroundColor: "white", margin: "30px" },
  CardImg: {
    position: "relative",
    width: "120px",
    height: "180px",
    backgroundColor: "#F2F2F2",
    borderRadius: "16px",
    boxShadow: "0px 1px 12px rgba(3,3,3,0.08)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  Image: {
    position: "relative",
    width: "150px",
    height: "200px",
    backgroundColor: "#F2F2F2",
    borderRadius: "16px",
    boxShadow: "0px 1px 12px rgba(3,3,3,0.08)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  Text: {
    color: "#858585",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: 300,
    lineHeight: "16px",
    textAlign: "center",
  },
  ButtonUpload: {
    backgroundColor: "transparent",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },
  TextHeader5: { textAlign: "left", fontWeight: "bold", marginTop: 30 },
  TextHeader6: { textAlign: "left", fontWeight: "bold", marginTop: 10 },
  TextHeader6A: { textAlign: "left", marginTop: 10 },
  FormLabel: { fontSize: "15px", textAlign: "left" },

  CardStyle: { border: "#fff", marginBottom: "10px" },
  CardHeader: {
    backgroundColor: "#000",
  },
  NameHeader: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  CardBody: {
    backgroundColor: "#fdedbc",
    marginTop: 0,
  },
  TableHeader: { backgroundColor: "#fdedbc" },
};
