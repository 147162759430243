import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

import { styles } from "../SVFeedbackStyle";
import SVInternshipCreate from "./SVInternshipCreate";

import { getApplicationForm } from "../../../4.Store/ApplicationFormSlice";
import {
  putCirculation,
  getCirculationByCirculationId,
} from "../../../4.Store/CirculationSlice";
import SVInterviewCreate from "./SVInterviewCreate";

const SVActionButtons = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const { sectionOne } = useSelector((state) => state.ApplicationForm);

  const {
    _id,
    jobAppId,
    svId,
    svName,
    svEmail,
    svRemark,
    ivDate,
    ivTime,
    ivDuration,
    ivMethod,
    circulationStatus,
    approvalDate,
    ivAMPM,
    superiorName,
    assignDeptProject,
  } = useSelector((state) => state.Circulation);

  const handleShow = () => {
    setShow(true);
  };

  const handleClickReject = () => {
    Swal.fire({
      title: "Confirm Reject Candidate?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const entity = {
          _id: _id,
          jobAppId,
          svId,
          svName,
          svEmail,
          svRemark,
          ivDate,
          ivTime,
          ivDuration,
          ivMethod,
          circulationStatus: 120,
          ivAMPM,
          superiorName,
          assignDeptProject,
        };
        dispatch(putCirculation(entity))
          // .then(() => {
          //   dispatch(postSvNotifyHREmail({ id: _id })); =>HR ask to remove
          // })
          .then(() => {
            Swal.fire("Status Updated");
          })
          .then(() => {
            dispatch(getCirculationByCirculationId(param._id)).then((data) => {
              dispatch(getApplicationForm(data.payload.jobAppId));
            });
          })
          .catch((err) => {
            Swal.fire("Error / Masalah", err.response.data, "error", "OK");
          });
      }
    });
  };

  const handleClickApproveForInternship = (ev) => {
    ev.preventDefault();
    const form = ev.currentTarget;

    if (form.checkValidity() === false) {
      ev.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      const entity = {
        _id: _id,
        jobAppId,
        svId,
        svName,
        svEmail,
        svRemark,
        ivDate,
        ivTime,
        ivDuration,
        ivMethod,
        circulationStatus: 500,
        approvalDate,
        ivAMPM,
        superiorName,
        assignDeptProject,
      };
      dispatch(putCirculation(entity))
        // .then(() => {
        //   dispatch(postSvNotifyHREmail({ id: _id })); =>HR ask to remove
        // })
        .then(() => {
          Swal.fire("Status And Internship Placement Updated");
        })
        .then(() => {
          dispatch(getCirculationByCirculationId(param._id)).then((data) => {
            dispatch(getApplicationForm(data.payload.jobAppId));
          });
        })
        .catch((err) => {
          Swal.fire("Error / Masalah", err.response.data, "error", "OK");
        });
    }
  };

  const handleClickApprove = (ev) => {
    ev.preventDefault();
    const form = ev.currentTarget;

    if (form.checkValidity() === false) {
      ev.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      const entity = {
        _id: _id,
        jobAppId,
        svId,
        svName,
        svEmail,
        svRemark,
        ivDate,
        ivTime,
        ivDuration,
        ivMethod,
        circulationStatus: 500,
        approvalDate,
        ivAMPM,
        superiorName,
        assignDeptProject,
      };
      dispatch(putCirculation(entity))
        // .then(() => {
        //   dispatch(postSvNotifyHREmail({ id: _id })); =>HR ask to remove
        // })
        .then(() => {
          Swal.fire("Status And Interview Details Updated");
        })
        .then(() => {
          dispatch(getCirculationByCirculationId(param._id)).then((data) => {
            dispatch(getApplicationForm(data.payload.jobAppId));
          });
        })
        .catch((err) => {
          Swal.fire("Error / Masalah", err.response.data, "error", "OK");
        });
    }
  };

  return (
    <>
      {circulationStatus == 400 && (
        <>
          <Row className="py-2">
            <Col>
              <Form.Label style={styles.TitleStyle}>Action:</Form.Label>
            </Col>
            <Row>
              <Col md="auto">
                <Button style={styles.ButtonApproveStyle} onClick={handleShow}>
                  Approve
                </Button>
              </Col>
              <Col md="auto">
                <Button
                  style={styles.ButtonRejectStyle}
                  onClick={handleClickReject}
                >
                  Reject
                </Button>
              </Col>
            </Row>
          </Row>

          {sectionOne?.internship == "yes" ? (
            <Modal show={show} onHide={() => setShow(false)} centered>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleClickApproveForInternship}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Fill In The Internship Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <SVInternshipCreate />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Save Internship Placement
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          ) : (
            <Modal show={show} onHide={() => setShow(false)} centered>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleClickApprove}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Fill In The Interview Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <SVInterviewCreate />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" type="submit">
                    Save Interview Details
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          )}
        </>
      )}
    </>
  );
};
export default SVActionButtons;
