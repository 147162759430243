import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";

import { styles } from "../SVFeedbackStyle";

import { updateSvFeedback } from "../../../4.Store/CirculationSlice";

const SVHRRemark = () => {
  const dispatch = useDispatch();

  const { hrRemark } = useSelector((state) => state.ApplicationForm);
  const { svRemark } = useSelector((state) => state.Circulation);

  const handleChangeSvRemark = (e) => {
    const entity = { svRemark: e.target.value };
    dispatch(updateSvFeedback(entity));
  };

  return (
    <>
      <Row className="py-2">
        <Col md={12}>
          <Form.Label style={styles.TitleStyle}>HR Remark:</Form.Label>
          <Form.Control
            name="hrRemark"
            as="textarea"
            rows={3}
            value={hrRemark || ""}
            readOnly
          />
        </Col>
      </Row>

      <Row className="py-2">
        <Col md={12}>
          <Form.Label style={styles.TitleStyle}>Supervisor Remark:</Form.Label>
          <Form.Control
            name="svRemark"
            as="textarea"
            rows={3}
            value={svRemark || ""}
            onChange={handleChangeSvRemark}
          />
        </Col>
      </Row>
    </>
  );
};
export default SVHRRemark;
