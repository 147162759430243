import Select from "react-select";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import CryptoJS from "crypto-js";

import { styles } from "./ApplicationFormStyle.js";
import {
  FormLabelSelectFull,
  FormLabelControlTextFull,
  FormLabelControlSalary,
  FormLabelControlText,
  FormLabelSelect,
  FormLabelControlAppendRight,
  FormLabelControlTextNoIcon,
  FormLabelControlTextFullNoIcon,
} from "../../2.Common/RequiredForm.js";
import {
  GenderList,
  HighestQualificationList,
  CurrentLocationList,
  YesNoList,
  AdvertisementSourceList,
  ReligionList,
  RaceList,
  DialectList,
} from "../../2.Common/DropdownOptions.js";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FormLabelControlTextNRNoIcon } from "../../2.Common/NotRequiredForm.js";
import UploadPhoto from "../../2.Common/UploadPhoto.js";
import UploadCoverLetter from "../../2.Common/UploadCoverLetter.js";
import UploadResume from "../../2.Common/UploadResume.js";
import UploadCertificate from "../../2.Common/UploadCertificate.js";

import PDFViewer from "../../2.Common/PDFViewer.jsx";
import { PreloaderSubmit } from "../../2.Common/Preloader.js";

import {
  updateSectionOne,
  getVacancy,
  postApplicationForm,
  postThankyouEmail,
} from "../../4.Store/ApplicationFormSlice.js";
import UserService from "../../5.Services/UserService.js";

const SectionOne = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const [role, setRole] = useState();
  const [vacancies, setVacancies] = useState([]);
  const [viewPdf, setViewPdf] = useState(undefined);
  const [validated, setValidated] = useState(false);
  const [tickDeclaration, setTickDeclaration] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false); //Loading For Submit Btn

  const { sectionOne, isUpdatedByCandidate, date, date2 } = useSelector(
    (state) => state.ApplicationForm
  );
  const {
    image,
    position_applied,
    internship,
    name,
    identification_certification,
    gender,
    date_of_birth,
    email,
    age,
    contact_number,
    place_of_birth,
    height,
    weight,
    religion,
    race,
    dialect,
    address,
    health,
    epf_number,
    income_tax_number,
    highest_qualification,
    expected_salary,
    current_location,
    current_location_other,
    fresh_graduate,
    fresh_graduate_no,
    relocate,
    ads_source,
    ads_source_other,
    cover_letter,
    resume,
    certificate,
    acknowledgement1,
  } = sectionOne;

  useEffect(() => {
    const whatrole = UserService.getRoles();
    setRole(whatrole);
  }, []);

  useEffect(() => {
    if (param._id === undefined) {
      // Function to extract query parameters from the URL
      const getQueryParam = (name) => {
        const params = new URLSearchParams(location.search);
        return params.get(name);
      };

      // Get the position parameter from the URL
      const position = getQueryParam("position");
      // console.log("Position:", position);

      // Put vacancy list into array
      dispatch(getVacancy()).then((res) => {
        const arr = [];
        let result = res.payload;

        result.forEach((vacancy) => {
          arr.push({
            label: vacancy.vacancy,
            value: vacancy._id,
          });
        });
        setVacancies(
          arr.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          })
        );

        // Find the selected position object in the vacancies array
        const selectedPosition = arr.find(
          (option) => option.label === position
        );
        // console.log("Selected Position:", selectedPosition);

        // Set selected position if it exists
        const entity = { ...sectionOne, position_applied: selectedPosition };
        // console.log(entity);
        dispatch(updateSectionOne(entity));
      });
    }
  }, [location.search]);

  useEffect(() => {
    var dob = new Date(date_of_birth);
    var cur = new Date();
    var diff = cur - dob; // This is the difference in milliseconds
    var age2 = Math.floor(diff / 31557600000); // Divide by 1000*60*60*24*365.25
    if (age2 < 1 || age2.isNan) {
      age2 = 0;
    }
    var entity = { ...sectionOne, age: age2 };
    dispatch(updateSectionOne(entity));
  }, [date_of_birth]);

  const handlePdfViewer = () => {
    setViewPdf(undefined);
  };

  const handleChangeDropdown = (name, value) => {
    const entity = { ...sectionOne, [name]: value };
    switch (name) {
      case "position_applied":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "gender":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "religion":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "race":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "highest_qualification":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "current_location":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "fresh_graduate":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "relocate":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "ads_source":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
    }
  };

  const handleChangeForm = (e) => {
    const entity = {
      ...sectionOne,
      [e.target.name]: e.target.value.toUpperCase(),
    };
    dispatch(updateSectionOne(entity));
    // console.log(entity);
  };

  const handleChangeForm2 = (e) => {
    const entity = {
      ...sectionOne,
      [e.target.name]: e.target.value,
    };
    dispatch(updateSectionOne(entity));
    // console.log(entity);
  };

  const FilterDialect = DialectList.filter((v) => {
    if (race !== undefined) {
      return v.branch === race.value;
    } else {
      return false;
    }
  });

  const handleChangeDropdownDialect = (value) => {
    const entity = { ...sectionOne, dialect: value };
    dispatch(updateSectionOne(entity));
    // console.log(entity);
  };

  const handleChangeSalary = (e) => {
    const newValue = parseInt(e.target.value);

    const secretKey = "abc123"; // Use a strong, secure key here
    const encryptedValue = CryptoJS.AES.encrypt(
      newValue.toString(),
      secretKey
    ).toString();

    if (!isNaN(newValue)) {
      const entity = {
        ...sectionOne,
        expected_salary2: encryptedValue,
        expected_salary: newValue,
      };
      dispatch(updateSectionOne(entity));
      // console.log(entity);
    }
  };

  const handleTickDeclaration = () => {
    if (tickDeclaration) {
      setTickDeclaration(false);
      const entity = { ...sectionOne, acknowledgement1: "agree" };
      dispatch(updateSectionOne(entity));
      // console.log(entity);
    } else {
      setTickDeclaration(true);
      const entity = { ...sectionOne, acknowledgement1: "not agree" };
      dispatch(updateSectionOne(entity));
      // console.log(entity);
    }
  };

  const handleClickSubmit = (ev) => {
    ev.preventDefault();
    const form = ev.currentTarget;

    if (form.checkValidity() === false) {
      ev.stopPropagation();
    }

    setValidated(true);

    const emailFormat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    switch (true) {
      case !sectionOne?.image:
        Swal.fire("Photography Is Empty!");
        return; // Prevent form submission
      case !sectionOne?.position_applied:
        Swal.fire("Position Applied Is Empty!");
        return;
      case !sectionOne?.name:
        Swal.fire("Name Is Empty!");
        return;
      case !sectionOne?.identification_certification:
        Swal.fire("NRIC Is Empty!");
        return;
      case !sectionOne?.gender?.value:
        Swal.fire("Gender Is Empty!");
        return;
      case !sectionOne?.date_of_birth:
        Swal.fire("Date Of Birth Is Empty!");
        return;
      case !sectionOne?.contact_number:
        Swal.fire("Contact Number Is Empty!");
        return;
      case !sectionOne?.email:
        Swal.fire("Email Is Empty!");
        return;
      case sectionOne?.email && !emailFormat.test(email):
        Swal.fire("Use Correct Email Format!");
        return;
      case !sectionOne?.highest_qualification?.value:
        Swal.fire("Highest Qualification Is Empty!");
        return;
      case !sectionOne?.current_location?.value:
        Swal.fire("Current Location Is Empty!");
        return;
      case !sectionOne?.expected_salary:
        Swal.fire("Expected Salary Is Empty!");
        return;
      case !sectionOne?.fresh_graduate?.value:
        Swal.fire("Fresh Graduate Status Is Empty!");
        return;
      case !sectionOne?.relocate?.value:
        Swal.fire("Relocate Outstation Is Empty!");
        return;
      case !sectionOne?.ads_source?.value:
        Swal.fire("Advertisment Source Is Empty!");
        return;
      case sectionOne?.ads_source?.value == "Staff Referral" &&
        !sectionOne?.ads_source_other:
        Swal.fire("State Staff Name For Staff Referral!");
        return;
      case sectionOne?.ads_source?.value == "Other" &&
        !sectionOne?.ads_source_other:
        Swal.fire("State Other For Other!");
        return;
      case !sectionOne?.resume:
        Swal.fire("Resume Is Empty!");
        return;
      case !sectionOne?.acknowledgement1:
        Swal.fire("Tick Declaration!");
        return;
    }

    if (form.checkValidity()) {
      setIsLoading(true);

      try {
        const entity = {
          ...sectionOne,
          identification_certification: identification_certification.replaceAll(
            "-",
            ""
          ),
          contact_number: contact_number.replaceAll(" ", ""),
          expected_salary: "***",
        };
        dispatch(
          postApplicationForm({
            sectionOne: entity,
            date,
            date2,
            status: 0,
            emailSend: 0,
          })
        )
          .then((data) => {
            // console.log(data);
            Swal.fire("Your Application Has Been Successfully Submitted.");
            dispatch(postThankyouEmail({ id: data.payload.insertedId }));
            // dispatch(postHrNotificationEmail({ id: data.payload.insertedId })); =>HR ask to remove
            setIsSubmit(true);
            setIsLoading(false);
          })
          .then(() => {
            window.location.href = "https://www.hsl.com.my/about/career/";
          });
      } catch (err) {
        setIsLoading(false);
        Swal.fire("Error / Masalah", err.response.data, "error", "OK");
      }
    }
  };

  return (
    <>
      {isLoading && <PreloaderSubmit />}
      {viewPdf && <PDFViewer onClose={handlePdfViewer} fileObject={viewPdf} />}
      {viewPdf === undefined && (
        <Container fluid>
          <Form noValidate validated={validated} onSubmit={handleClickSubmit}>
            <UploadPhoto />
            {param._id ? (
              <Form.Group
                as={Row}
                className="px-2 py-3"
                style={{ textAlign: "left" }}
              >
                <Form.Label column sm="2" style={{ fontSize: "15px" }}>
                  Position Applied&nbsp;<span className="star">*</span>
                </Form.Label>
                <Col sm="10" style={{ marginTop: "5px" }}>
                  {position_applied?.label || position_applied}
                </Col>
              </Form.Group>
            ) : (
              <FormLabelSelectFull
                label="Position Applied"
                name="position_applied"
                value={position_applied}
                options={vacancies}
                handleChange={handleChangeDropdown}
              />
            )}
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlText
                  label="Full Name"
                  name="name"
                  value={name}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter Here"
                  icon={faCircleQuestion}
                  tooltip="Fill in full name as per NRIC / Passport"
                  tooltipId="fullname-tooltip"
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlText
                  label="NRIC / Passport No."
                  name="identification_certification"
                  value={identification_certification}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter Here"
                  icon={faCircleQuestion}
                  tooltip="Fill in without dash (-)"
                  tooltipId="nric-tooltip"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Gender"
                  name="gender"
                  value={gender}
                  options={GenderList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlTextNoIcon
                  label="Email"
                  name="email"
                  value={email}
                  type="email"
                  handleChangeForm={handleChangeForm2}
                  placeholder="name@example.com"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlTextNoIcon
                  label="Date Of Birth"
                  name="date_of_birth"
                  value={date_of_birth}
                  handleChangeForm={handleChangeForm2}
                  type="date"
                />
              </Col>
              <Col md={6} sm={12}>
                <Form.Group as={Row} className="px-2 py-3">
                  <Form.Label
                    column
                    sm="4"
                    style={{ fontSize: "15px", textAlign: "left" }}
                  >
                    Age&nbsp;<span className="star">*</span>
                    &nbsp;&nbsp;
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control name="age" value={age || 0} readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlText
                  label="Contact Number"
                  name="contact_number"
                  value={contact_number}
                  handleChangeForm={handleChangeForm}
                  type="number"
                  placeholder="Enter Here"
                  icon={faCircleQuestion}
                  tooltip="Fill in without dash (-)"
                  tooltipId="contactnum-tooltip"
                />
              </Col>
              {param._id && role && isUpdatedByCandidate == 1 && (
                <>
                  <Col>
                    <FormLabelControlTextNoIcon
                      label="Place Of Birth"
                      name="place_of_birth"
                      value={place_of_birth}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                    />
                  </Col>
                </>
              )}
              {param._id && !role && (
                <>
                  <Col>
                    <FormLabelControlTextNoIcon
                      label="Place Of Birth"
                      name="place_of_birth"
                      value={place_of_birth}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                    />
                  </Col>
                </>
              )}
            </Row>
            {param._id && role && isUpdatedByCandidate == 1 && (
              <>
                <Row>
                  <Col md={6} sm={12}>
                    <FormLabelControlAppendRight
                      label="Height"
                      name="height"
                      value={height}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                      appendLabel="CM"
                      type="number"
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <FormLabelControlAppendRight
                      label="Weight"
                      name="weight"
                      value={weight}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                      appendLabel="KG"
                      type="number"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <FormLabelSelect
                      label="Religion"
                      name="religion"
                      value={religion}
                      options={ReligionList}
                      handleChange={handleChangeDropdown}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <FormLabelSelect
                      label="Race"
                      name="race"
                      value={race}
                      options={RaceList}
                      handleChange={handleChangeDropdown}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Form.Group
                      as={Row}
                      className="px-2 py-3"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label column sm="4" style={{ fontSize: "15px" }}>
                        Dialect&nbsp;<span className="star">*</span>
                      </Form.Label>
                      <Col sm="8" className="SelectOption">
                        <Select
                          name="dialect"
                          value={dialect || ""}
                          options={FilterDialect}
                          onChange={handleChangeDropdownDialect}
                          placeholder="Please Select"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <FormLabelControlTextFullNoIcon
                  label="Address"
                  name="address"
                  value={address}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter here"
                />
                <FormLabelControlTextFull
                  label="Health"
                  name="health"
                  value={health}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter here"
                  icon={faCircleQuestion}
                  tooltip="State any mental or serious physical illness, disability or handicap and period suffered. State any regular treatment medical taken. Enter NONE if none."
                  tooltipId="health-tooltip"
                />
                <Row>
                  <Col md={6} sm={12}>
                    <FormLabelControlTextNRNoIcon
                      label="EPF"
                      name="epf_number"
                      value={epf_number}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <FormLabelControlTextNRNoIcon
                      label="Income Tax"
                      name="income_tax_number"
                      value={income_tax_number}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                    />
                  </Col>
                </Row>
              </>
            )}
            {param._id && !role && (
              <>
                <Row>
                  <Col md={6} sm={12}>
                    <FormLabelControlAppendRight
                      label="Height"
                      name="height"
                      value={height}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                      appendLabel="CM"
                      type="number"
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <FormLabelControlAppendRight
                      label="Weight"
                      name="weight"
                      value={weight}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                      appendLabel="KG"
                      type="number"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <FormLabelSelect
                      label="Religion"
                      name="religion"
                      value={religion}
                      options={ReligionList}
                      handleChange={handleChangeDropdown}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <FormLabelSelect
                      label="Race"
                      name="race"
                      value={race}
                      options={RaceList}
                      handleChange={handleChangeDropdown}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Form.Group
                      as={Row}
                      className="px-2 py-3"
                      style={{ textAlign: "left" }}
                    >
                      <Form.Label column sm="4" style={{ fontSize: "15px" }}>
                        Dialect&nbsp;<span className="star">*</span>
                      </Form.Label>
                      <Col sm="8" className="SelectOption">
                        <Select
                          name="dialect"
                          value={dialect || ""}
                          options={FilterDialect}
                          onChange={handleChangeDropdownDialect}
                          placeholder="Please Select"
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <FormLabelControlTextFullNoIcon
                  label="Address"
                  name="address"
                  value={address}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter here"
                />
                <FormLabelControlTextFull
                  label="Health"
                  name="health"
                  value={health}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter here"
                  icon={faCircleQuestion}
                  tooltip="State any mental or serious physical illness, disability or handicap and period suffered. State any regular treatment medical taken. Enter NONE if none."
                  tooltipId="health-tooltip"
                />
                <Row>
                  <Col md={6} sm={12}>
                    <FormLabelControlTextNRNoIcon
                      label="EPF"
                      name="epf_number"
                      value={epf_number}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <FormLabelControlTextNRNoIcon
                      label="Income Tax"
                      name="income_tax_number"
                      value={income_tax_number}
                      handleChangeForm={handleChangeForm}
                      placeholder="Enter here"
                    />
                  </Col>
                </Row>
              </>
            )}

            <h5 className="px-2" style={styles.TextHeader5}>
              (A) Other Info
            </h5>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Highest Qualification"
                  name="highest_qualification"
                  value={highest_qualification}
                  options={HighestQualificationList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlSalary
                  label="Expected Salary"
                  name="expected_salary"
                  value={expected_salary}
                  handleChangeForm={handleChangeSalary}
                  placeholder="Enter here"
                  inputText="RM"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Current Location"
                  name="current_location"
                  value={current_location}
                  options={CurrentLocationList}
                  handleChange={handleChangeDropdown}
                />
                {current_location?.value === "Others" && (
                  <FormLabelControlTextNoIcon
                    label="Please State Location"
                    name="current_location_other"
                    value={current_location_other}
                    handleChangeForm={handleChangeForm}
                  />
                )}
              </Col>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Are You Fresh Graduate?"
                  name="fresh_graduate"
                  value={fresh_graduate}
                  options={YesNoList}
                  handleChange={handleChangeDropdown}
                />
                {fresh_graduate?.value === "No" && (
                  <FormLabelControlAppendRight
                    label="Please State Year(s) of Experience"
                    name="fresh_graduate_no"
                    value={fresh_graduate_no}
                    handleChangeForm={handleChangeForm}
                    appendLabel="Year(s)"
                    type="number"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Willing To Relocate Outstation Within Sarawak?"
                  name="relocate"
                  value={relocate}
                  options={YesNoList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="How Did You Find Out About The Position?"
                  name="ads_source"
                  value={ads_source}
                  options={AdvertisementSourceList}
                  handleChange={handleChangeDropdown}
                />
                {ads_source?.value === "Other" && (
                  <FormLabelControlTextNoIcon
                    label="Please State Other"
                    name="ads_source_other"
                    value={ads_source_other}
                    handleChangeForm={handleChangeForm}
                  />
                )}
                {ads_source?.value === "Staff Referral" && (
                  <FormLabelControlTextNoIcon
                    label="Please State Staff Name:"
                    name="ads_source_other"
                    value={ads_source_other}
                    handleChangeForm={handleChangeForm}
                  />
                )}
              </Col>
            </Row>

            <h5 className="px-2" style={styles.TextHeader5}>
              (B) Attachments&nbsp;
              <span style={{ fontSize: "small" }}>(File Limit: 5MB)</span>
            </h5>
            <UploadCoverLetter />
            <UploadResume />
            <UploadCertificate />

            {param._id === undefined && (
              <>
                <Form.Group as={Row} className="px-3 py-5">
                  <Col sm={12}>
                    <Form.Check
                      type="checkbox"
                      onChange={handleTickDeclaration}
                      required
                      checked={acknowledgement1 == "agree"}
                      style={{ textAlign: "justify" }}
                      label="I declare that the information given in this application is true and complete. I understand that any misleading or false information or wilful omission is sufficient reason for refusal or dismissal from employment. I also understand that as a condition of employment I may have to successfully complete a company sponsored medical examination. I further understand that final confirmation of employment is subject to a check of references, qualifications and facts, as well as a review of my performance during my probation."
                    />
                  </Col>
                </Form.Group>

                <Row className="justify-content-md-center mt-2 mb-3">
                  <Col md="auto" sm="auto">
                    <Button
                      variant="warning"
                      style={{ fontWeight: "bold" }}
                      type="submit"
                      disabled={isSubmit == true}
                    >
                      SUBMIT JOB APPLICATION
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Container>
      )}
    </>
  );
};
export default SectionOne;
