import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Table, Image, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArrowDown,
  faDownload,
  faBoltLightning,
} from "@fortawesome/free-solid-svg-icons";

import "../SVTableList.css";

import { getCirculationByCirculationId } from "../../../4.Store/CirculationSlice";

const SVTableApplicationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    circulationBySvIdLists,
    currentSVApplicationPage,
    totalCurrentSVApplicationPage,
    searchCirculationBySvIdLists,
    searchKeyWordCirculationBySvIdLists,
  } = useSelector((store) => store.SVTableList);

  const handleClick = (_id) => {
    navigate(`/SVViewForm/${_id}`);
  };

  const handleDownloadBiodata = (_id) => {
    dispatch(getCirculationByCirculationId(_id)).then((data) => {
      // const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}`;
      const pdfUrl = `http://192.168.5.174:5000/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}`;
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadFullForm = (_id) => {
    dispatch(getCirculationByCirculationId(_id)).then((data) => {
      // const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/full/${data?.payload?.jobAppId}`;
      const pdfUrl = `http://192.168.5.174:5000/api/pdfs33/pdf/full/${data?.payload?.jobAppId}`;;
      window.open(pdfUrl, "_blank");
    });
  };

  return (
    <Container className="TableContainer" fluid>
      <Table>
        <thead className="TableHeader">
          <tr>
            <th className="ThText">Date Applied</th>
            <th className="ThIcon">Profile</th>
            <th className="ThText">Name</th>
            <th className="ThText">Position</th>
            <th className="ThText">Location</th>
            <th className="ThText">Qualification</th>
            <th className="ThText">Fresh Graduate</th>
            <th className="ThText">RM</th>
            <th className="ThText">Status</th>
            <th className="ThIcon">
              <FontAwesomeIcon icon={faBoltLightning} title="Download" />
            </th>
          </tr>
        </thead>
        {searchKeyWordCirculationBySvIdLists ? (
          <tbody>
            {searchCirculationBySvIdLists.map(
              ({ _id, jobapplication, circulationStatus }, i) => (
                <tr key={i}>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.date}
                  </td>
                  <td className="TdIcon" onClick={() => handleClick(_id)}>
                    <Image
                      src={jobapplication?.sectionOne?.image}
                      className="ProfileImage"
                    />
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.name}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.internship == "yes" &&
                      "INTERNSHIP"}
                    {jobapplication?.sectionOne?.position_applied?.label ||
                      jobapplication?.sectionOne?.position_applied}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.current_location?.label}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.highest_qualification?.label}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {(jobapplication?.sectionOne?.fresh_graduate?.value ==
                      "Yes" && <span>FRESH GRADUATE</span>) ||
                      (jobapplication?.sectionOne?.fresh_graduate?.value ==
                        "No" &&
                        jobapplication?.sectionOne?.fresh_graduate_no +
                          " " +
                          "YEAR(S) EXPERIENCE") ||
                      "N/A"}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.expected_salary || "N/A"}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {(circulationStatus == 400 && <span>PENDING</span>) ||
                      (circulationStatus == 500 && <span>ACCEPTED</span>) ||
                      (circulationStatus == 120 && <span>REJECTED</span>)}
                  </td>
                  <td className="TdIcon">
                    {jobapplication?.isUpdatedByCandidate == 1 ? (
                      <FontAwesomeIcon
                        onClick={() => handleDownloadFullForm(_id)}
                        icon={faFileArrowDown}
                        title="Print Form"
                        color="#E1AD01"
                        className="fa-xl"
                      />
                    ) : (
                      <FontAwesomeIcon
                        onClick={() => handleDownloadBiodata(_id)}
                        icon={faDownload}
                        title="Print Biodata"
                        color="black"
                      />
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        ) : (
          <tbody>
            {circulationBySvIdLists.map(
              ({ _id, jobapplication, circulationStatus }, i) => (
                <tr key={i}>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.date}
                  </td>
                  <td className="TdIcon" onClick={() => handleClick(_id)}>
                    <Image
                      src={jobapplication?.sectionOne?.image}
                      className="ProfileImage"
                    />
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.name}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.internship === "yes"
                      ? "INTERNSHIP"
                      : jobapplication?.sectionOne?.position_applied?.label ||
                        jobapplication?.sectionOne?.position_applied}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.current_location?.label}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.highest_qualification?.label}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {(jobapplication?.sectionOne?.fresh_graduate?.value ==
                      "Yes" && <span>FRESH GRADUATE</span>) ||
                      (jobapplication?.sectionOne?.fresh_graduate?.value ==
                        "No" &&
                        jobapplication?.sectionOne?.fresh_graduate_no +
                          " " +
                          "YEAR(S) EXPERIENCE") ||
                      "N/A"}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.expected_salary || "N/A"}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {(circulationStatus == 400 && <span>PENDING</span>) ||
                      (circulationStatus == 500 && <span>ACCEPTED</span>) ||
                      (circulationStatus == 120 && <span>REJECTED</span>)}
                  </td>
                  <td className="TdIcon">
                    {jobapplication?.isUpdatedByCandidate == 1 ? (
                      <FontAwesomeIcon
                        onClick={() => handleDownloadFullForm(_id)}
                        icon={faFileArrowDown}
                        title="Print Form"
                        color="#E1AD01"
                        className="fa-xl"
                      />
                    ) : (
                      <FontAwesomeIcon
                        onClick={() => handleDownloadBiodata(_id)}
                        icon={faDownload}
                        title="Print Biodata"
                        color="black"
                      />
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        )}
      </Table>
    </Container>
  );
};
export default SVTableApplicationList;
