import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Row, Card, Form, Button, ButtonGroup } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";

import { styles } from "../HRFeedbackStyle";
import {
  InterviewMethodList,
  IvDurationList,
  IvTimeList,
} from "../../../2.Common/DropdownOptions";

import {
  updateInterviewDate,
  updateInterviewTime,
  updateInterviewAmPm,
  updateInterviewMethod,
  updateInterviewDuration,
  putCirculation,
  postCirculationSvEmail,
  getCirculationByCirculationId,
  getCirculationByJobAppId,
} from "../../../4.Store/CirculationSlice";
import { getApplicationForm } from "../../../4.Store/ApplicationFormSlice";
import {
  getAssessmentByJobAppId,
  putAssessmentForm,
} from "../../../4.Store/AssessmentSlice";

const InterviewDetail = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const { _id, hrRemark } = useSelector((state) => state.ApplicationForm);

  const {
    jobAppId,
    svId,
    svName,
    svEmail,
    svRemark,
    ivDate,
    ivTime,
    ivDuration,
    ivMethod,
    ivAMPM,
    superiorName,
    assignDeptProject,
  } = useSelector((state) => state.Circulation);

  const AMPMOptions = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ];

  const handleChangeIvDate = (e) => {
    const entity = { ivDate: e.target.value };
    dispatch(updateInterviewDate(entity));
    // console.log(entity);
  };

  const handleChangeIvTime = (value) => {
    const entity = { ivTime: value };
    dispatch(updateInterviewTime(entity));
    // console.log(entity);
  };

  const handleChangeAmPm = (value) => {
    const entity = { ivAMPM: value };
    dispatch(updateInterviewAmPm(entity));
    // console.log(entity);
  };

  const handleChangeIvMethod = (value) => {
    const entity = { ivMethod: value };
    dispatch(updateInterviewMethod(entity));
  };

  const handleChangeIvDuration = (value) => {
    const entity = { ivDuration: value };
    dispatch(updateInterviewDuration(entity));
  };

  const handleClickEditInterviewDetails = () => {
    const entity = {
      _id: param._id,
      jobAppId,
      hrRemark,
      svId,
      svName,
      svEmail,
      svRemark,
      ivDate,
      ivTime,
      ivDuration,
      ivMethod,
      circulationStatus: 500,
      ivAMPM,
      superiorName,
      assignDeptProject,
    };
    dispatch(putCirculation(entity))
      .then(() => {
        dispatch(postCirculationSvEmail({ id: param._id }));
      })
      .then(() => {
        dispatch(getCirculationByJobAppId(param._id));
        dispatch(getCirculationByCirculationId(param._id)).then((res) => {
          dispatch(getApplicationForm(res.payload.jobAppId));
          dispatch(getAssessmentByJobAppId(res.payload.jobAppId)).then(
            (respond) => {
              const array = respond.payload;

              array.forEach((item) => {
                const id = item._id;
                const entity = {
                  _id: id,
                  interviewDate: ivDate,
                  interviewTime: ivTime,
                  interviewAmPm: ivAMPM,
                  jobAppId: jobAppId ? jobAppId : _id,
                };
                dispatch(putAssessmentForm(entity));
              });
            }
          );
          dispatch(getCirculationByJobAppId(res.payload.jobAppId));
        });
      })
      .then(() => {
        Swal.fire("Interview Details Successfully Updated");
      })
      .catch((err) => {
        Swal.fire("Error / Masalah", err.response.data, "error", "OK");
      });
  };

  return (
    <Row className="py-2">
      <Col md={12}>
        <Card>
          <Card.Header style={styles.TitleStyle}>Interview Details</Card.Header>
          <Card.Body>
            <Row className="my-2 mx-3">
              <Col md={3} sm={12}>
                <Form.Label>
                  Date <span className="star">*</span>
                </Form.Label>
              </Col>
              <Col md={6} sm={12}>
                <Form.Control
                  name="ivDate"
                  type="date"
                  value={ivDate || ""}
                  onChange={handleChangeIvDate}
                  required
                />
              </Col>
            </Row>
            <Row className="my-3 mx-3">
              <Col md={3} sm={12}>
                <Form.Label>
                  Time <span className="star">*</span>
                </Form.Label>
              </Col>
              <Col md={4} sm={12}>
                <Select
                  name="ivTime"
                  options={IvTimeList}
                  value={ivTime || ""}
                  onChange={handleChangeIvTime}
                />
              </Col>
              <Col md={4} sm={12}>
                <Select
                  name="ivAMPM"
                  options={AMPMOptions}
                  value={ivAMPM || ""}
                  onChange={handleChangeAmPm}
                />
              </Col>
            </Row>
            <Row className="my-3 mx-3">
              <Col md={3} sm={12}>
                <Form.Label>
                  Method <span className="star">*</span>
                </Form.Label>
              </Col>
              <Col md={6} sm={12}>
                <Select
                  name="ivMethod"
                  options={InterviewMethodList}
                  defaultValue={ivMethod || ""}
                  onChange={handleChangeIvMethod}
                />
              </Col>
            </Row>
            <Row className="my-3 mx-3">
              <Col md={3} sm={12}>
                <Form.Label>Duration</Form.Label>
              </Col>
              <Col md={6} sm={12}>
                <Select
                  name="ivDuration"
                  options={IvDurationList}
                  value={ivDuration || ""}
                  onChange={handleChangeIvDuration}
                />
              </Col>
            </Row>
            <ButtonGroup className="mt-3" style={{ float: "right" }}>
              <Button
                onClick={handleClickEditInterviewDetails}
                variant="outline-primary"
                style={styles.ButtonStyle}
              >
                Update Details
              </Button>
            </ButtonGroup>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default InterviewDetail;
