import { useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";

import { styles } from "../HRFeedbackStyle";

const CandidateStatus = () => {
  const {
    status,
    emailSend,
    date,
    dateUpdatedStatus,
    dateSendEmailForFullForm,
  } = useSelector((state) => state.ApplicationForm);
  const {
    circulationStatus,
    isContactForIv,
    dateUpdatedCirculationStatus,
    dateSendEmailForInterview,
    interviewCancelReason,
  } = useSelector((state) => state.Circulation);

  return (
    <Row className="py-2">
      <Col>
        <Form.Label style={styles.TitleStyle}>Candidate Status:</Form.Label>
        <ul>
          {emailSend == 1 && (
            <li className="text-primary">
              {dateSendEmailForFullForm?.split(",")[0]}&nbsp;
              {/* Candidate has been contacted for more details. */}
              Email Candidate For Full Form is send SUCCESSFULLY
            </li>
          )}

          {isContactForIv == 1 && (
            <li className="text-primary">
              {dateSendEmailForInterview?.split(",")[0]}&nbsp;
              {/* Candidate has been contacted for INTERVIEW. */}
              Email Candidate For Interview is send SUCCESSFULLY
            </li>
          )}

          {circulationStatus == 400 && (
            <li>
              {dateUpdatedCirculationStatus?.split(",")[0]}&nbsp;Circulated to
              supervisor
            </li>
          )}
          {circulationStatus == 500 && (
            <li>
              {dateUpdatedCirculationStatus?.split(",")[0]}&nbsp;Circulation
              Status - Approve for interview
            </li>
          )}
          {circulationStatus == 120 && (
            <li>
              {dateUpdatedCirculationStatus?.split(",")[0]}&nbsp;Circulation
              Status - Reject for interview
            </li>
          )}
          {circulationStatus == 130 && (
            <li className="text-danger">
              {dateUpdatedCirculationStatus?.split(",")[0]}&nbsp;Interview
              Cancel: Reason ({interviewCancelReason})
            </li>
          )}

          {status == -10 && (
            <li className="text-danger">
              {date?.split(",")[0]}&nbsp;
              {/* Rejected For Internship */}
              Email Candidate For Internship Rejection is send SUCCESSFULLY
            </li>
          )}
          {status == 0 ||
            (status == 10 && <li>{date?.split(",")[0]} Pending Review</li>)}
          {status == 50 && (
            <li>{dateUpdatedStatus?.split(",")[0]} Candidate is KIV</li>
          )}
          {status == 100 && (
            <li>{dateUpdatedStatus?.split(",")[0]} Review Only</li>
          )}
          {status == 200 && (
            <li>
              {dateUpdatedStatus?.split(",")[0]} Review and Recommend (Proceed
              to circulation)
            </li>
          )}
          {status == 20 && (
            <li>
              {dateUpdatedStatus?.split(",")[0]} Candidate Accept Internship
              Offer
            </li>
          )}
          {status == -20 && (
            <li>
              {dateUpdatedStatus?.split(",")[0]} Candidate Turn Down Internship
              Offer
            </li>
          )}
        </ul>
      </Col>
    </Row>
  );
};
export default CandidateStatus;
