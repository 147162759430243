import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import {
  IvTimeList,
  InterviewMethodList,
  IvDurationList,
} from "../../../2.Common/DropdownOptions";

import {
  updateInterviewDate,
  updateInterviewTime,
  updateInterviewAmPm,
  updateInterviewMethod,
  updateInterviewDuration,
} from "../../../4.Store/CirculationSlice";

const SVInterviewCreate = () => {
  const dispatch = useDispatch();

  const { ivDate, ivTime, ivDuration, ivMethod, ivAMPM } = useSelector(
    (state) => state.Circulation
  );

  const AMPMOptions = [
    { label: "AM", value: "AM" },
    { label: "PM", value: "PM" },
  ];

  const handleChangeIvDate = (e) => {
    const entity = { ivDate: e.target.value };
    dispatch(updateInterviewDate(entity));
    // console.log(entity);
  };

  const handleChangeIvTime = (value) => {
    const entity = { ivTime: value };
    dispatch(updateInterviewTime(entity));
    // console.log(entity);
  };

  const handleChangeAmPm = (value) => {
    const entity = { ivAMPM: value };
    dispatch(updateInterviewAmPm(entity));
    // console.log(entity);
  };

  const handleChangeIvMethod = (value) => {
    const entity = { ivMethod: value };
    dispatch(updateInterviewMethod(entity));
  };

  const handleChangeIvDuration = (value) => {
    const entity = { ivDuration: value };
    dispatch(updateInterviewDuration(entity));
  };

  return (
    <>
      <Row className="my-2 mx-3">
        <Col md={3} sm={12}>
          <Form.Label>
            Date <span className="star">*</span>
          </Form.Label>
        </Col>
        <Col md={6} sm={12}>
          <Form.Control
            name="ivDate"
            type="date"
            value={ivDate || ""}
            onChange={handleChangeIvDate}
            required
          />
        </Col>
      </Row>

      <Row className="my-3 mx-3">
        <Col md={3} sm={12}>
          <Form.Label>
            Time <span className="star">*</span>
          </Form.Label>
        </Col>
        <Col md={4} sm={12}>
          <Select
            name="ivTime"
            options={IvTimeList}
            value={ivTime || ""}
            onChange={handleChangeIvTime}
          />
        </Col>
        <Col md={4} sm={12}>
          <Select
            name="ivAMPM"
            options={AMPMOptions}
            value={ivAMPM || ""}
            onChange={handleChangeAmPm}
          />
        </Col>
      </Row>

      <Row className="my-3 mx-3">
        <Col md={3} sm={12}>
          <Form.Label>
            Method <span className="star">*</span>
          </Form.Label>
        </Col>
        <Col md={6} sm={12}>
          <Select
            name="ivMethod"
            options={InterviewMethodList}
            defaultValue={ivMethod || ""}
            onChange={handleChangeIvMethod}
          />
        </Col>
      </Row>
      
      <Row className="my-3 mx-3">
        <Col md={3} sm={12}>
          <Form.Label>Duration</Form.Label>
        </Col>
        <Col md={6} sm={12}>
          <Select
            name="ivDuration"
            options={IvDurationList}
            value={ivDuration || ""}
            onChange={handleChangeIvDuration}
          />
        </Col>
      </Row>
    </>
  );
};
export default SVInterviewCreate;
