import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Container, Form, Row, Col, Table, Button } from "react-bootstrap";
import Swal from "sweetalert2";

import { styles } from "./ApplicationFormStyle";
import { FormLabelSelectFull } from "../../2.Common/RequiredForm";
import { MaritalStatusList } from "../../2.Common/DropdownOptions";
import { FormLabelControlTextNRNoIcon } from "../../2.Common/NotRequiredForm";

import { updateSectionTwo } from "../../4.Store/ApplicationFormSlice";

const SectionTwo = () => {
  const dispatch = useDispatch();

  const [childrenName, setChildrenName] = useState("");
  const [childrenAge, setChildrenAge] = useState("");
  const [emergencyContactName, setEmergencyContactName] = useState("");
  const [emergencyContactNumber, setEmergencyContactNumber] = useState("");
  const [emergencyContactRelationship, setEmergencyContactRelationship] =
    useState("");

  const { sectionTwo } = useSelector((state) => state.ApplicationForm);
  const {
    marital_status,
    spouse,
    father,
    mother,
    children,
    emergency_references,
  } = sectionTwo;

  const handleChangeDropdown = (name, value) => {
    const entity = { ...sectionTwo, [name]: value };
    switch (name) {
      case "marital_status":
        dispatch(updateSectionTwo(entity));
        // console.log(entity);
        break;
    }
  };

  const handleChangeSpouseForm = (e) => {
    const entity = {
      ...sectionTwo,
      spouse: { ...spouse, [e.target.name]: e.target.value.toUpperCase() },
    };
    dispatch(updateSectionTwo(entity));
    // console.log(entity);
  };

  const handleChangeFatherForm = (e) => {
    const entity = {
      ...sectionTwo,
      father: { ...father, [e.target.name]: e.target.value.toUpperCase() },
    };
    dispatch(updateSectionTwo(entity));
    // console.log(entity);
  };

  const handleChangeMotherForm = (e) => {
    const entity = {
      ...sectionTwo,
      mother: { ...mother, [e.target.name]: e.target.value.toUpperCase() },
    };
    dispatch(updateSectionTwo(entity));
    // console.log(entity);
  };

  const handleClickSaveChildrenForm = () => {
    const arr = { ...sectionTwo };
    let arr2 = [...arr.children];
    arr2.push({
      children_name: childrenName.toUpperCase(),
      children_age: +childrenAge,
    });

    const entity = {
      ...sectionTwo,
      children: arr2,
      number_of_children: arr2.length,
    };

    dispatch(updateSectionTwo(entity));
    // console.log(entity);

    setChildrenName("");
    setChildrenAge("");
  };

  const handleClickRemoveChildrenForm = (index) => {
    Swal.fire({
      title: "Confirm Remove Children?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const arr = { ...sectionTwo };
        const arr2 = arr.children.filter((e, i) => i !== index);

        const entity = {
          ...sectionTwo,
          children: arr2,
          number_of_children: arr2.length,
        };
        dispatch(updateSectionTwo(entity));
        // console.log(entity);
      }
    });
  };

  const handleClickSaveEmergencyContactForm = () => {
    const emptyFields = [];

    if (!emergencyContactName) {
      emptyFields.push("Name");
    }

    if (!emergencyContactRelationship) {
      emptyFields.push("Relationship");
    }

    if (!emergencyContactNumber) {
      emptyFields.push("Contact Number");
    }

    if (emptyFields.length > 0) {
      Swal.fire(`The Following Fields Are Empty: ${emptyFields.join(", ")}`);
    } else {
      const arr = { ...sectionTwo };
      let arr2 = [...arr.emergency_references];
      arr2.push({
        name: emergencyContactName.toUpperCase(),
        contact_number: emergencyContactNumber.replace(/-/g, ""),
        relationship: emergencyContactRelationship.toUpperCase(),
      });

      const entity = { ...sectionTwo, emergency_references: arr2 };
      dispatch(updateSectionTwo(entity));
      // console.log(entity);

      setEmergencyContactName("");
      setEmergencyContactNumber("");
      setEmergencyContactRelationship("");
    }
  };

  const handleClickRemoveEmergencyContactForm = (index) => {
    Swal.fire({
      title: "Confirm Remove Emergency Contact?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const arr = { ...sectionTwo };
        const arr2 = arr.emergency_references.filter((e, i) => i !== index);

        const entity = { ...sectionTwo, emergency_references: arr2 };
        dispatch(updateSectionTwo(entity));
        // console.log(entity);
      }
    });
  };

  return (
    <>
      <Container fluid>
        <Form>
          <h5 className="px-2" style={styles.TextHeader5}>
            (A) Family Details
          </h5>
          <Row>
            <Col md={12} sm={12}>
              <FormLabelSelectFull
                label="Marital Status"
                name="marital_status"
                value={marital_status}
                options={MaritalStatusList}
                handleChange={handleChangeDropdown}
              />
            </Col>
          </Row>
          {marital_status?.value === "Married" && (
            <Row>
              <Col md={2} sm={12}>
                <h6 className="px-2 py-1" style={styles.TextHeader6}>
                  • Spouse Details:
                </h6>
              </Col>
              <Col md={10} sm={12}>
                <Col md={12} sm={12}>
                  <FormLabelControlTextNRNoIcon
                    label="Name"
                    placeholder="Enter here"
                    name="name"
                    value={spouse?.name}
                    handleChangeForm={handleChangeSpouseForm}
                  />
                </Col>
                <Col md={12} sm={12}>
                  <FormLabelControlTextNRNoIcon
                    label="Occupation"
                    placeholder="Enter here"
                    name="occupation"
                    value={spouse?.occupation}
                    handleChangeForm={handleChangeSpouseForm}
                  />
                </Col>
                <Col md={12} sm={12}>
                  <FormLabelControlTextNRNoIcon
                    label="Employer"
                    placeholder="Enter here"
                    name="employer"
                    value={spouse?.employer}
                    handleChangeForm={handleChangeSpouseForm}
                  />
                </Col>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={12} sm={12}>
              <h6 className="px-2 py-1" style={styles.TextHeader6}>
                • Children:
              </h6>
            </Col>
            <Table>
              <thead>
                <tr>
                  <td>No</td>
                  <td>Name</td>
                  <td>Age</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-column="No:">#</td>
                  <td data-column="Name:">
                    <Form.Control
                      name="children_name"
                      value={childrenName}
                      onChange={(e) => setChildrenName(e.target.value)}
                    />
                  </td>
                  <td data-column="Age:">
                    <Form.Control
                      type="number"
                      name="children_age"
                      value={childrenAge}
                      onChange={(e) => setChildrenAge(e.target.value)}
                    />
                  </td>
                  <td data-column="Action:">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={handleClickSaveChildrenForm}
                      style={{ fontWeight: "bold" }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp;Save Children
                    </Button>
                  </td>
                </tr>
                {children?.map(({ children_name, children_age }, i) => (
                  <tr key={i}>
                    <td data-column="No:">{i + 1}</td>
                    <td data-column="Name:">{children_name}</td>
                    <td data-column="Age:">{children_age}</td>
                    <td data-column="Action:">
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleClickRemoveChildrenForm(i)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>

          <Row>
            <Col md={2} sm={12}>
              <h6 className="px-2 py-1" style={styles.TextHeader6}>
                • Father Details:
              </h6>
            </Col>
            <Col md={10} sm={12}>
              <Col md={12} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Name"
                  placeholder="Enter here"
                  name="name"
                  value={father?.name}
                  handleChangeForm={handleChangeFatherForm}
                />
              </Col>
              <Col md={12} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Occupation"
                  placeholder="Enter here"
                  name="occupation"
                  value={father?.occupation}
                  handleChangeForm={handleChangeFatherForm}
                />
              </Col>
              <Col md={12} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Employer"
                  placeholder="Enter here"
                  name="employer"
                  value={father?.employer}
                  handleChangeForm={handleChangeFatherForm}
                />
              </Col>
            </Col>
          </Row>

          <Row>
            <Col md={2} sm={12}>
              <h6 className="px-2 py-1" style={styles.TextHeader6}>
                • Mother Details:
              </h6>
            </Col>
            <Col md={10} sm={12}>
              <Col md={12} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Name"
                  placeholder="Enter here"
                  name="name"
                  value={mother?.name}
                  handleChangeForm={handleChangeMotherForm}
                />
              </Col>
              <Col md={12} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Occupation"
                  placeholder="Enter here"
                  name="occupation"
                  value={mother?.occupation}
                  handleChangeForm={handleChangeMotherForm}
                />
              </Col>
              <Col md={12} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Employer"
                  placeholder="Enter here"
                  name="employer"
                  value={mother?.employer}
                  handleChangeForm={handleChangeMotherForm}
                />
              </Col>
            </Col>
          </Row>

          <h5 className="px-2" style={styles.TextHeader5}>
            (B) Emergency Contact
          </h5>
          <h6 className="px-2" style={styles.TextHeader6A}>
            • Fill In At Least&nbsp;<span className="star">ONE</span>
            &nbsp;Emergency Contact&nbsp;
            <span className="star">*</span>
          </h6>
          <Table>
            <thead>
              <tr>
                <td>No</td>
                <td>Name</td>
                <td>Relationship</td>
                <td>Contact No.</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-column="No:">#</td>
                <td data-column="Name:">
                  <Form.Control
                    name="emergency_contact_name"
                    value={emergencyContactName}
                    onChange={(e) => setEmergencyContactName(e.target.value)}
                  />
                </td>
                <td data-column="Relationship:">
                  <Form.Control
                    name="emergency_contact_relationship"
                    value={emergencyContactRelationship}
                    onChange={(e) =>
                      setEmergencyContactRelationship(e.target.value)
                    }
                  />
                </td>
                <td data-column="Contact Number:">
                  <Form.Control
                    name="emergency_contact_number"
                    value={emergencyContactNumber}
                    onChange={(e) => setEmergencyContactNumber(e.target.value)}
                  />
                </td>
                <td data-column="Action:">
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={handleClickSaveEmergencyContactForm}
                    style={{ fontWeight: "bold" }}
                    // disabled={ !emergencyContactName || !emergencyContactRelationship || !emergencyContactNumber }
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;Save Emergency Contact
                  </Button>
                </td>
              </tr>
              {emergency_references?.map(
                ({ name, contact_number, relationship }, i) => (
                  <tr key={i}>
                    <td data-column="No:">{i + 1}</td>
                    <td data-column="Name:">{name}</td>
                    <td data-column="Relationship:">{relationship}</td>
                    <td data-column="Contact Number:">{contact_number}</td>
                    <td data-column="Action:">
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => handleClickRemoveEmergencyContactForm(i)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Form>
      </Container>
    </>
  );
};
export default SectionTwo;
