import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  updateSectionEight,
  putApplicationForm,
  getApplicationForm,
} from "../../4.Store/ApplicationFormSlice";
import UserService from "../../5.Services/UserService";

const SectionEight = () => {
  const dispatch = useDispatch();

  const [tickDeclaration, setTickDeclaration] = useState(true);
  const [role, setRole] = useState();

  useEffect(() => {
    const whatrole = UserService.getRoles();
    setRole(whatrole);
  }, []);

  const {
    _id,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    sectionEight,
    dateUpdated,
  } = useSelector((state) => state.ApplicationForm);
  const { acknowledgement2 } = sectionEight;

  const { jobAppId } = useSelector((state) => state.Circulation);

  const handleTickDeclaration = () => {
    if (tickDeclaration) {
      setTickDeclaration(false);
      const entity = { ...sectionEight, acknowledgement2: "agree" };
      dispatch(updateSectionEight(entity));
      // console.log(entity);
    } else {
      setTickDeclaration(true);
      const entity = { ...sectionEight, acknowledgement2: "not agree" };
      dispatch(updateSectionEight(entity));
      // console.log(entity);
    }
  };

  const handleClickHRUpdate = () => {
    const entity1 = { ...sectionOne };
    const entity2 = { ...sectionTwo };
    const entity3 = { ...sectionThree };
    const entity4 = { ...sectionFour };
    const entity5 = { ...sectionFive };
    const entity6 = { ...sectionSix };
    const entity7 = { ...sectionSeven };

    dispatch(
      putApplicationForm({
        id: jobAppId ? jobAppId : _id,
        sectionOne: entity1,
        sectionTwo: entity2,
        sectionThree: entity3,
        sectionFour: entity4,
        sectionFive: entity5,
        sectionSix: entity6,
        sectionSeven: entity7,
      })
    )
      .then(() => {
        dispatch(getApplicationForm(jobAppId ? jobAppId : _id));
      })
      .then(() => {
        Swal.fire("Successfully Updated.");
      })
      .catch((err) => {
        Swal.fire("Error / Masalah", err.response.data, "error", "OK");
      });
  };

  return (
    <Container fluid>
      <Form>
        <Form.Group as={Row} className="px-2 py-3">
          <Col sm="12">
            <Form.Check
              name="acknowledgement2"
              style={{ textAlign: "justify" }}
              type="checkbox"
              label="I declare that the information given in this application is true and complete. I understand that any misleading or false information or wilful omission is sufficient reason for refusal or dismissal from employment. I also understand that as a condition of employment I may have to successfully complete a company sponsored medical examination. I further understand that final confirmation of employment is subject to a check of references, qualifications and facts, as well as a review of my performance during my probation."
              onChange={handleTickDeclaration}
              checked={acknowledgement2 == "agree"}
              required
            />
          </Col>
        </Form.Group>
      </Form>

      {role && role.includes("jobapp_hr") && (
        <>
          <div>
            <Form.Label>Submitted On {dateUpdated}</Form.Label>
          </div>
          <div className="text-center">
            <Button className="mx-auto loginBtn" onClick={handleClickHRUpdate}>
              HR Update Form
            </Button>
          </div>
        </>
      )}
    </Container>
  );
};
export default SectionEight;
