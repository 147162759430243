import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import moment from "moment";

import { styles } from "../HRFeedbackStyle";
import {
  ActionStatusList,
  ActionEmailList,
} from "../../../2.Common/DropdownOptions";

import {
  postUpdateEmail,
  getApplicationForm,
  putApplicationForm,
  postRejectInternship,
} from "../../../4.Store/ApplicationFormSlice";
import {
  putCirculation,
  getCirculationByJobAppId,
  getCirculationByCirculationId,
  postContactCandidateForInterviewEmail,
  updateInteviewCancelReason,
  postInterviewCancelToSupervisorEmail,
  getInternshipDetailsByJobAppId,
} from "../../../4.Store/CirculationSlice";
import {
  getAssessmentByJobAppId,
  putAssessmentForm,
  postInterviewCancelToInterviewerEmail,
} from "../../../4.Store/AssessmentSlice";

const ActionButtons = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const [showModal, setShowModal] = useState(false);

  const {
    _id,
    emailSend,
    hrRemark,
    status,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
  } = useSelector((state) => state.ApplicationForm);

  const { jobAppId, circulationStatus, interviewCancelReason } = useSelector(
    (state) => state.Circulation
  );

  const handleChangeInterviewCancelReason = (e) => {
    const entity = { interviewCancelReason: e.target.value };
    dispatch(updateInteviewCancelReason(entity));
    // console.log(entity);
  };

  const handleClickCancelInterviewAll = () => {
    Swal.fire({
      title: "Confirm Cancel Interview?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (sectionOne?.internship == "yes") {
          dispatch(getAssessmentByJobAppId(param._id)).then((resarray) => {
            // console.log(resarray);
            dispatch(getInternshipDetailsByJobAppId(param._id)).then((res) => {
              // console.log(res);
              const entityA = {
                _id: res?.payload?._id,
                jobAppId,
                circulationStatus: 130,
                interviewCancelReason,
                dateUpdatedCirculationStatus: moment().format(
                  "yyyy-MM-DD, h:mm:ss a"
                ),
              };
              dispatch(putCirculation(entityA));

              const entityB = { id: res?.payload?._id };
              dispatch(postInterviewCancelToSupervisorEmail(entityB));

              if (resarray.payload.length > 0) {
                const array = resarray.payload;
                // console.log(array);
                array.forEach((item) => {
                  const id = item._id;
                  const entity1 = {
                    _id: id,
                    assessmentStatus: 130,
                    jobAppId: _id,
                  };
                  // console.log(entity1);
                  dispatch(putAssessmentForm(entity1));
                  const entity2 = { id: id };
                  dispatch(postInterviewCancelToInterviewerEmail(entity2)); //Email interviewer for iv cancel
                });
              }

              dispatch(getCirculationByJobAppId(param._id));
              dispatch(getCirculationByCirculationId(res?.payload?._id))
                .then((res) => {
                  dispatch(getApplicationForm(param._id));
                  dispatch(getAssessmentByJobAppId(param._id));
                  dispatch(getCirculationByJobAppId(param._id));
                })
                .then(() => {
                  Swal.fire("Interview Cancel!", "", "success");
                  setShowModal(false);
                })
                .catch((err) => {
                  Swal.fire(
                    "Error / Masalah",
                    err.response.data,
                    "error",
                    "OK"
                  );
                });
            });
          });
        } else {
          dispatch(getAssessmentByJobAppId(jobAppId)).then((resarray) => {
            // console.log(resarray);
            const entityA = {
              _id: param._id,
              jobAppId,
              circulationStatus: 130,
              interviewCancelReason,
              dateUpdatedCirculationStatus: moment().format(
                "yyyy-MM-DD, h:mm:ss a"
              ),
            };
            dispatch(putCirculation(entityA));

            const entityB = { id: param._id };
            // console.log(entityB)
            dispatch(postInterviewCancelToSupervisorEmail(entityB)); //Email supervisor for iv cancel

            if (resarray.payload.length > 0) {
              const array = resarray.payload;
              // console.log(array);
              array.forEach((item) => {
                const id = item._id;
                const entity1 = {
                  _id: id,
                  assessmentStatus: 130,
                  jobAppId: jobAppId ? jobAppId : _id,
                };
                // console.log(entity1);
                dispatch(putAssessmentForm(entity1));
                const entity2 = { id: id };
                dispatch(postInterviewCancelToInterviewerEmail(entity2)); //Email interviewer for iv cancel
              });
            }

            dispatch(getCirculationByJobAppId(param._id));
            dispatch(getCirculationByCirculationId(param._id))
              .then((res) => {
                dispatch(getApplicationForm(res.payload.jobAppId));
                dispatch(getAssessmentByJobAppId(res.payload.jobAppId));
                dispatch(getCirculationByJobAppId(res.payload.jobAppId));
              })
              .then(() => {
                Swal.fire("Interview Cancel!", "", "success");
                setShowModal(false);
              })
              .catch((err) => {
                Swal.fire("Error / Masalah", err.response.data, "error", "OK");
              });
          });
        }
      }
    });
  };

  const handleDropdownActionStatus = (i) => {
    if (i?.value == 100) {
      Swal.fire({
        title: "Confirm Review Only?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          const entity1 = { ...sectionOne };
          const entity2 = { ...sectionTwo };
          const entity3 = { ...sectionThree };
          const entity4 = { ...sectionFour };
          const entity5 = { ...sectionFive };
          const entity6 = { ...sectionSix };
          const entity7 = { ...sectionSeven };
          dispatch(
            putApplicationForm({
              id: jobAppId ? jobAppId : _id,
              status: 100,
              hrRemark,
              sectionOne: entity1,
              sectionTwo: entity2,
              sectionThree: entity3,
              sectionFour: entity4,
              sectionFive: entity5,
              sectionSix: entity6,
              sectionSeven: entity7,
              dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
            })
          )
            .then(() => {
              dispatch(getApplicationForm(jobAppId ? jobAppId : _id));
            })
            .then(() => {
              Swal.fire("Status Updated");
            })
            .catch((err) => {
              Swal.fire("Error / Masalah", err.response.data, "error", "OK");
            });
        }
      });
    }

    if (i?.value == 200) {
      Swal.fire({
        title: "Confirm Review And Recommend?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          const entity1 = { ...sectionOne };
          const entity2 = { ...sectionTwo };
          const entity3 = { ...sectionThree };
          const entity4 = { ...sectionFour };
          const entity5 = { ...sectionFive };
          const entity6 = { ...sectionSix };
          const entity7 = { ...sectionSeven };
          dispatch(
            putApplicationForm({
              id: jobAppId ? jobAppId : _id,
              status: 200,
              hrRemark,
              sectionOne: entity1,
              sectionTwo: entity2,
              sectionThree: entity3,
              sectionFour: entity4,
              sectionFive: entity5,
              sectionSix: entity6,
              sectionSeven: entity7,
              dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
            })
          )
            .then(() => {
              dispatch(getApplicationForm(jobAppId ? jobAppId : _id));
            })
            .then(() => {
              Swal.fire("Status Updated");
            })
            .catch((err) => {
              Swal.fire("Error / Masalah", err.response.data, "error", "OK");
            });
        }
      });
    }

    if (i?.value == 130) {
      setShowModal(true);
    }

    if (i?.value == 20) {
      Swal.fire({
        title: "Confirm Candidate Accept Internship Offer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          const entity1 = { ...sectionOne };
          const entity2 = { ...sectionTwo };
          const entity3 = { ...sectionThree };
          const entity4 = { ...sectionFour };
          const entity5 = { ...sectionFive };
          const entity6 = { ...sectionSix };
          const entity7 = { ...sectionSeven };
          dispatch(
            putApplicationForm({
              id: jobAppId ? jobAppId : _id,
              status: 20,
              hrRemark,
              sectionOne: entity1,
              sectionTwo: entity2,
              sectionThree: entity3,
              sectionFour: entity4,
              sectionFive: entity5,
              sectionSix: entity6,
              sectionSeven: entity7,
              dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
            })
          )
            .then(() => {
              dispatch(getApplicationForm(jobAppId ? jobAppId : _id));
            })
            .then(() => {
              Swal.fire("Status Updated");
            })
            .catch((err) => {
              Swal.fire("Error / Masalah", err.response.data, "error", "OK");
            });
        }
      });
    }

    if (i?.value == -20) {
      Swal.fire({
        title: "Confirm Candidate Turn Down Internship Offer?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          const entity1 = { ...sectionOne };
          const entity2 = { ...sectionTwo };
          const entity3 = { ...sectionThree };
          const entity4 = { ...sectionFour };
          const entity5 = { ...sectionFive };
          const entity6 = { ...sectionSix };
          const entity7 = { ...sectionSeven };
          dispatch(
            putApplicationForm({
              id: jobAppId ? jobAppId : _id,
              status: -20,
              hrRemark,
              sectionOne: entity1,
              sectionTwo: entity2,
              sectionThree: entity3,
              sectionFour: entity4,
              sectionFive: entity5,
              sectionSix: entity6,
              sectionSeven: entity7,
              dateUpdatedStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
            })
          )
            .then(() => {
              dispatch(getApplicationForm(jobAppId ? jobAppId : _id));
            })
            .then(() => {
              Swal.fire("Status Updated");
            })
            .catch((err) => {
              Swal.fire("Error / Masalah", err.response.data, "error", "OK");
            });
        }
      });
    }
  };

  const handleDropdownActionEmail = (i) => {
    if (i?.value == "emailFullForm") {
      Swal.fire({
        title: "Confirm Contact Candidate?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(postUpdateEmail({ id: _id }))
            .then(() => {
              const entity1 = { ...sectionOne };
              const entity2 = { ...sectionTwo };
              const entity3 = { ...sectionThree };
              const entity4 = { ...sectionFour };
              const entity5 = { ...sectionFive };
              const entity6 = { ...sectionSix };
              const entity7 = { ...sectionSeven };
              dispatch(
                putApplicationForm({
                  id: jobAppId ? jobAppId : _id,
                  emailSend: 1,
                  sectionOne: entity1,
                  sectionTwo: entity2,
                  sectionThree: entity3,
                  sectionFour: entity4,
                  sectionFive: entity5,
                  sectionSix: entity6,
                  sectionSeven: entity7,
                  dateSendEmailForFullForm: moment().format(
                    "yyyy-MM-DD, h:mm:ss a"
                  ),
                })
              )
                .then(() => {
                  dispatch(getApplicationForm(jobAppId ? jobAppId : _id));
                })
                .then(() => {
                  // dispatch(postHrNotifyEmail({ id: _id })); =>HR ask to remove
                  Swal.fire("Email Send To Candidate.");
                });
            })
            .catch((err) => {
              Swal.fire("Error / Masalah", err.response.data, "error", "OK");
            });
        }
      });
    }

    if (i?.value == "emailInterview") {
      Swal.fire({
        title: "Confirm Contact Candidate For Interview?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          if (sectionOne?.internship == "yes") {
            dispatch(getInternshipDetailsByJobAppId(param._id))
              .then((res) => {
                // console.log(res);
                dispatch(
                  postContactCandidateForInterviewEmail({
                    id: res?.payload._id,
                  })
                ).then(() => {
                  const entity = {
                    _id: res?.payload._id,
                    jobAppId,
                    isContactForIv: 1,
                    dateSendEmailForInterview: moment().format(
                      "yyyy-MM-DD, h:mm:ss a"
                    ),
                  };
                  dispatch(putCirculation(entity))
                    .then(() => {
                      dispatch(getCirculationByJobAppId(param._id));
                      dispatch(
                        getCirculationByCirculationId(res?.payload._id)
                      ).then((res) => {
                        dispatch(getApplicationForm(param._id));
                        dispatch(getAssessmentByJobAppId(param._id));
                        dispatch(getCirculationByJobAppId(param._id));
                      });
                    })
                    .then(() => {
                      Swal.fire("Email Send To Candidate.");
                    });
                });
              })
              .catch((err) => {
                Swal.fire("Error / Masalah", err.response.data, "error", "OK");
              });
          } else {
            dispatch(postContactCandidateForInterviewEmail({ id: param._id }))
              .then(() => {
                const entity = {
                  _id: param._id,
                  jobAppId,
                  isContactForIv: 1,
                  dateSendEmailForInterview: moment().format(
                    "yyyy-MM-DD, h:mm:ss a"
                  ),
                };
                dispatch(putCirculation(entity))
                  .then(() => {
                    dispatch(getCirculationByJobAppId(param._id));
                    dispatch(getCirculationByCirculationId(param._id)).then(
                      (res) => {
                        dispatch(getApplicationForm(res.payload.jobAppId));
                        dispatch(getAssessmentByJobAppId(res.payload.jobAppId));
                        dispatch(
                          getCirculationByJobAppId(res.payload.jobAppId)
                        );
                      }
                    );
                  })
                  .then(() => {
                    Swal.fire("Email Send To Candidate.");
                  });
              })
              .catch((err) => {
                Swal.fire("Error / Masalah", err.response.data, "error", "OK");
              });
          }
        }
      });
    }

    if (i?.value == "emailRejection") {
      Swal.fire({
        title: "Confirm Reject Internship and Send Rejection Email?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(postRejectInternship({ id: _id }))
            .then(() => {
              const entity1 = { ...sectionOne };
              const entity2 = { ...sectionTwo };
              const entity3 = { ...sectionThree };
              const entity4 = { ...sectionFour };
              const entity5 = { ...sectionFive };
              const entity6 = { ...sectionSix };
              const entity7 = { ...sectionSeven };
              dispatch(
                putApplicationForm({
                  id: jobAppId ? jobAppId : _id,
                  status: -10,
                  sectionOne: entity1,
                  sectionTwo: entity2,
                  sectionThree: entity3,
                  sectionFour: entity4,
                  sectionFive: entity5,
                  sectionSix: entity6,
                  sectionSeven: entity7,
                  dateSendEmailForRejectInternship: moment().format(
                    "yyyy-MM-DD, h:mm:ss a"
                  ),
                })
              )
                .then(() => {
                  dispatch(getApplicationForm(jobAppId ? jobAppId : _id));
                })
                .then(() => {
                  // dispatch(postHrNotifyEmail({ id: _id })); =>HR ask to remove
                  Swal.fire("Email Send To Candidate.");
                });
            })
            .catch((err) => {
              Swal.fire("Error / Masalah", err.response.data, "error", "OK");
            });
        }
      });
    }
  };

  const filterEmailOption = ActionEmailList.filter((option) => {
    // console.log(option)
    if (option.value === "emailFullForm") return true;
    if (option.value === "emailInterview" && circulationStatus === 500)
      return true;
    if (option.value === "emailRejection" && sectionOne?.internship === "yes")
      return true;
    return false;
  });

  const filterStatusOption = ActionStatusList.filter((option) => {
    // console.log(option)
    if (option.value === 100) return true;
    if (option.value === 200) return true;
    if (option.value === 130) return true;
    if (option.value === 20 && sectionOne?.internship === "yes") return true;
    if (option.value === -20 && sectionOne?.internship === "yes") return true;
    return false;
  });

  return (
    <>
      <Row className="py-2">
        <Col>
          <Form.Label style={styles.TitleStyle}>Status Action:</Form.Label>
          <Select
            // options={ActionStatusList}
            options={filterStatusOption}
            placeholder="Please Select"
            onChange={handleDropdownActionStatus}
          />
        </Col>
        <Col>
          <Form.Label style={styles.TitleStyle}>Email Action:</Form.Label>
          <Select
            // options={ActionEmailList}
            options={filterEmailOption}
            placeholder="Please Select"
            onChange={handleDropdownActionEmail}
          />
        </Col>
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          Fill In Reason For Interview Cancel
        </Modal.Header>
        <Modal.Body>
          <Form.Control onChange={handleChangeInterviewCancelReason} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => handleClickCancelInterviewAll(_id)}
          >
            Cancel Interview
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ActionButtons;
