import { useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";

import { styles } from "../SVFeedbackStyle";

const SVCandidateStatus = () => {
  const { circulationStatus } = useSelector((state) => state.Circulation);

  return (
    <Row className="py-2">
      <Col>
        <Form.Label style={styles.TitleStyle}>Candidate Status:</Form.Label>
        <ul>
          {circulationStatus == 400 && (
            <li>Pending Review (Please APPROVE or REJECT)</li>
          )}
          {circulationStatus == 500 && <li>Approve for interview</li>}
          {circulationStatus == 120 && <li>Reject for interview</li>}
        </ul>
      </Col>
    </Row>
  );
};
export default SVCandidateStatus;
