import { Card, Container, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

import NavbarMenu from "../2.Common/NavbarMenu";
import InternshipList from "../1.Pages/HRTableList/InternshipList";
import ReviewRecommendList from "../1.Pages/HRTableList/ReviewRecommendList";
import ApprovedForInterviewList from "../1.Pages/HRTableList/ApprovedForInterviewList";
import AssessmentList from "../1.Pages/HRTableList/AssessmentList";
import TitleApplicationList from "../1.Pages/HRTabTitle/TitleApplicationList";
import TitleInternshipList from "../1.Pages/HRTabTitle/TitleInternshipList";
import TitleApprovedForInterviewList from "../1.Pages/HRTabTitle/TitleApprovedForInterviewList";
import TitleAssessmentList from "../1.Pages/HRTabTitle/TitleAssessmentList";
import ApplicationListOri from "../1.Pages/HRTableList/ApplicationListOri";

import { updateCurrentListTab } from "../4.Store/ApplicationFormSlice";

const HRTableListSite = () => {
  const dispatch = useDispatch();

  const { currentListTab } = useSelector((state) => state.ApplicationForm);

  const accessToken = localStorage.getItem("access_token");
  const token = jwt_decode(accessToken);

  const isHR = token?.realm_access?.roles?.includes("jobapp_hr");

  return (
    <>
      {isHR && (
        <>
          <NavbarMenu />
          <Container fluid>
            <Card className="mt-3 mb-3">
              <Tabs
                defaultActiveKey={currentListTab}
                id="justify-tab"
                className="mb-3 tabs"
                justify
                onSelect={(k) => dispatch(updateCurrentListTab(k))}
              >
                <Tab eventKey="0" title={<TitleApplicationList />}>
                  <ApplicationListOri />
                </Tab>
                <Tab eventKey="1" title={<TitleInternshipList />}>
                  <InternshipList />
                </Tab>
                <Tab eventKey="2" title="Review Recommend List">
                  <ReviewRecommendList />
                </Tab>
                <Tab eventKey="3" title={<TitleApprovedForInterviewList />}>
                  <ApprovedForInterviewList />
                </Tab>
                <Tab eventKey="4" title={<TitleAssessmentList />}>
                  <AssessmentList />
                </Tab>
              </Tabs>
            </Card>
          </Container>
        </>
      )}
    </>
  );
};
export default HRTableListSite;
