import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../5.Services/apiServices";
import moment from "moment";

const namespace = "Assessment";

//Assessment Form
export const getAssessmentForm = createAsyncThunk(
  `${namespace}/getAssessmentForm`,
  async (body, { rejectWithValue }) => {
    const api = "GetAssessment";
    const replace = {
      search: "{assesment_id}",
      replace: body,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const putAssessmentForm = createAsyncThunk(
  `${namespace}/putAssessmentForm`,
  async (body, { rejectWithValue }) => {
    const api = "UpdateAssessment";
    const replace = {
      search: "{assesment_id}",
      replace: body._id,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postAssessmentForm = createAsyncThunk(
  `${namespace}/postAssessmentForm`,
  async (body, { rejectWithValue }) => {
    const api = "CreateAssessment";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getAssessmentByJobAppId = createAsyncThunk(
  `${namespace}/getAssessmentByJobAppId`,
  async (body, { rejectWithValue }) => {
    const api = "GetAssessment_JobappId";
    const replace = {
      search: "{jobapp_id}",
      replace: body,
    };
    const opt = {
      replace: replace,
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Email
export const postAssessmentToSvEmail = createAsyncThunk(
  `${namespace}/postAssessmentToSvEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_NewAssessmentEmail_Interviewer";
    const replace = {
      search: "assesment_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postInterviewCancelToInterviewerEmail = createAsyncThunk(
  `${namespace}/postInterviewCancelToInterviewerEmail`,
  async (body, { rejectWithValue }) => {
    const api = "Create_InterviewCancelEmail_Interviewer";
    const replace = {
      search: "assesment_id",
      // replace: body._id,
      replace: body.id,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    // console.log(body);
    try {
      // console.log(1);
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  _id: "", //assessment_id
  jobAppId: "",
  circulationId: "",
  jobapplication: {},
  sectionA: {
    qualification: "",
    qualification_suitability: "",
    experience_relevance: "",
    working_years: "",
    fresh_graduate_check: "",
    professional_affiliate_check: "",
    professional_affiliate_description: "",
    remarks: "",
  },
  sectionB: {
    confidence_level: "",
    manner: "",
    self_esteem: "",
    dressing: "",
    overall_impression: "",
  },
  sectionC: {
    language_spoken_english: "",
    language_writen_english: "",
    language_spoken_chinese: "",
    language_writen_chinese: "",
    language_spoken_bahasa: "",
    language_writen_bahasa: "",
    language_spoken_other: "",
    language_writen_other: "",
    writing_abilities: "",
  },
  sectionD: {
    other_comment: "",
    current_salary: "",
    expected_salary: "",
    suggested_placement: "",
    require_notice: "",
    recommend_offer: "",
    proposed_first_day: "",
    other_term: "",
    remark: "",
    interviewer_signature: "",
    date: moment().format("yyyy-MM-DD, h:mm:ss a"),
  },
  approval: {
    interviewer_recommendation: "",
    director_approval: "",
    director_signature: "",
    director_date: "",
  },
  interviewer: {
    interviewerId: "",
    interviewerName: "",
    interviewerEmail: "",
  },
  assessmentStatus: "",
  interviewerList: [],
  interviewDate: "",
  interviewTime: "",
  interviewAmPm: "",
  submitInterviewerDate: moment().format("yyyy-MM-DD, h:mm:ss a"),
  submitInterviewerDate2: moment().format("yyyy-MM-DD"),
};

const AssessmentSlice2 = createSlice({
  name: "Assessment",
  initialState,
  reducers: {
    resetAssessmentForm(state, action) {
      return {
        ...state,
        sectionA: initialState.sectionA,
        sectionB: initialState.sectionB,
        sectionC: initialState.sectionC,
        sectionD: initialState.sectionD,
        approval: initialState.approval,
      };
    },
    updateSectionA(state, action) {
      // console.log(action.payload);
      const a = {
        ...state,
        sectionA: action.payload,
      };
      return a;
    },
    updateSectionB(state, action) {
      // console.log(action.payload);
      const a = {
        ...state,
        sectionB: action.payload,
      };
      return a;
    },
    updateSectionC(state, action) {
      // console.log(action.payload);
      const a = {
        ...state,
        sectionC: action.payload,
      };
      return a;
    },
    updateSectionD(state, action) {
      // console.log(action.payload);
      const a = {
        ...state,
        sectionD: action.payload,
      };
      return a;
    },
    updateInterviewer(state, action) {
      // console.log(action.payload);
      const a = {
        ...state,
        interviewer: action.payload,
      };
      return a;
    },
    updateApproval(state, action) {
      // console.log(action.payload);
      const a = {
        ...state,
        approval: action.payload,
      };
      return a;
    },
  },
  extraReducers: {
    //Assessment Form
    [getAssessmentForm.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentForm.fulfilled]: (state, action) => {
      state.loading = false;

      // console.log(action.payload);

      state._id = action.payload._id;
      state.jobAppId = action.payload.jobAppId;
      state.circulationId = action.payload.circulationId;
      state.assessmentStatus = action.payload.assessmentStatus;
      state.interviewDate = action.payload.interviewDate;
      state.interviewTime = action.payload.interviewTime;
      state.interviewAmPm = action.payload.interviewAmPm;
      state.sectionA = { ...state.sectionA, ...action.payload.sectionA };
      state.sectionB = { ...state.sectionB, ...action.payload.sectionB };
      state.sectionC = { ...state.sectionC, ...action.payload.sectionC };
      state.sectionD = { ...state.sectionD, ...action.payload.sectionD };
      state.approval = { ...state.approval, ...action.payload.approval };
      state.interviewer = {
        ...state.interviewer,
        ...action.payload.interviewer,
      };
      state.submitInterviewerDate = {
        ...state.submitInterviewerDate,
        ...action.payload.submitInterviewerDate,
      };

      // console.log(state.sectionA);
    },
    [getAssessmentForm.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [putAssessmentForm.pending]: (state, action) => {
      state.loading = true;
    },
    [putAssessmentForm.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [putAssessmentForm.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postAssessmentForm.pending]: (state, action) => {
      state.loading = true;
    },
    [postAssessmentForm.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postAssessmentForm.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [getAssessmentByJobAppId.pending]: (state, action) => {
      state.loading = true;
    },
    [getAssessmentByJobAppId.fulfilled]: (state, action) => {
      state.loading = false;
      state.interviewerList = [];
      state.interviewerList = [...state.interviewerList, ...action.payload];
      // console.log(state.interviewerList);
    },
    [getAssessmentByJobAppId.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    //Email
    [postAssessmentToSvEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postAssessmentToSvEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postAssessmentToSvEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postInterviewCancelToInterviewerEmail.pending]: (state, action) => {
      state.loading = true;
    },
    [postInterviewCancelToInterviewerEmail.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postInterviewCancelToInterviewerEmail.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
  },
});

export const {
  resetAssessmentForm,
  updateSectionA,
  updateSectionB,
  updateSectionC,
  updateSectionD,
  updateInterviewer,
  updateApproval,
} = AssessmentSlice2.actions;
export default AssessmentSlice2.reducer;
