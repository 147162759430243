import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../5.Services/apiServices";

const namespace = "Report";

export const postListSource = createAsyncThunk(
  `${namespace}/postListSource`,
  async (body, { rejectWithValue }) => {
    const api = "CreateReportList_Source";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  error: false,

  dateStart: "",
  dateEnd: "",
};

const ReportSlice = createSlice({
  name: "Report",
  initialState,
  reducers: {
    resetDateStart(state, action) {
      return {
        ...state,
        dateStart: initialState.dateStart,
      };
    },
    resetDateEnd(state, action) {
      return {
        ...state,
        dateEnd: initialState.dateEnd,
      };
    },
    updateDateStart(state, action) {
      const a = {
        ...state,
        dateStart: action.payload.dateStart,
      };
      return a;
    },
    updateDateEnd(state, action) {
      const a = {
        ...state,
        dateEnd: action.payload.dateEnd,
      };
      return a;
    },
  },
  extraReducers: {
    [postListSource.pending]: (state, action) => {
      state.loading = true;
    },
    [postListSource.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postListSource.rejected]: (state, action) => {
      state.error = true;
      console.error(action.payload);
    },
  },
});

export const { resetDateStart, resetDateEnd, updateDateStart, updateDateEnd } =
  ReportSlice.actions;
export default ReportSlice.reducer;
