import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { getHRApprovedInterviewListPagination } from "../../4.Store/HRTableListSlice";

const TitleApprovedForInterviewList = () => {
  const dispatch = useDispatch();

  const { circulationListByStatus, currentApproveInterviewPage } = useSelector(
    (store) => store.HRTableList
  );
  const { isInterviewerSet } = useSelector((state) => state.Circulation);

  const calculateNewCount = () => {
    return circulationListByStatus.filter((item) => item.isInterviewerSet !== 1)
      .length;
  };

  // const calculateNewCount = () => {
  //   if (isInterviewerSet == 1) {
  //     return 1;
  //   } else {
  //     return circulationListByStatus.filter(
  //       (item) => item.isInterviewerSet !== 1
  //     ).length;
  //   }
  // };

  return (
    <>
      <span>Approved Interview List</span>&nbsp;
      <span
        className="badge"
        style={{ border: "1px solid white", backgroundColor: "#5E8ABB" }}
      >
        {calculateNewCount()}
      </span>
    </>
  );
};

export default TitleApprovedForInterviewList;
