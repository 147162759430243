import jwt_decode from "jwt-decode";
import {
  Card,
  Container,
  Tab,
  Tabs,
  Row,
  Col,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styles } from "../1.Pages/HRFeedback/HRFeedbackStyle";
import NavbarMenu from "../2.Common/NavbarMenu";
import SectionOne from "../1.Pages/ApplicationForm/SectionOne";
import SectionTwo from "../1.Pages/ApplicationForm/SectionTwo";
import SectionThree from "../1.Pages/ApplicationForm/SectionThree";
import SectionFour from "../1.Pages/ApplicationForm/SectionFour";
import SectionFive from "../1.Pages/ApplicationForm/SectionFive";
import SectionSix from "../1.Pages/ApplicationForm/SectionSix";
import SectionSeven from "../1.Pages/ApplicationForm/SectionSeven";
import SectionEight from "../1.Pages/ApplicationForm/SectionEight";
import SectionOneInternship from "../1.Pages/InternshipForm/SectionOneInternship";
import HRFeedback from "../1.Pages/HRFeedback/HRFeedback";

import {
  getCirculationByCirculationId,
  getCirculationByJobAppId,
  getInternshipDetailsByJobAppId,
  resetSectionHrDept,
} from "../4.Store/CirculationSlice";
import {
  getApplicationForm,
  updateCurrentTab,
  resetApplicationForm,
} from "../4.Store/ApplicationFormSlice";
import { getAssessmentByJobAppId } from "../4.Store/AssessmentSlice";

const HRFormViewSite = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();

  const {
    _id,
    currentTab,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    hrRemark,
  } = useSelector((state) => state.ApplicationForm);

  const { jobAppId, circulationStatus } = useSelector(
    (state) => state.Circulation
  );

  useEffect(() => {
    if (param._id) {
      //   dispatch(getApplicationForm(param._id));
      const handleFormResetAndFetch = async () => {
        await dispatch(resetApplicationForm());
        dispatch(getApplicationForm(param._id));
      };
      handleFormResetAndFetch();
    } else {
      dispatch(resetApplicationForm());
    }
  }, [param._id]);

  useEffect(() => {
    dispatch(resetSectionHrDept());
    dispatch(getInternshipDetailsByJobAppId(param._id));
    dispatch(getCirculationByJobAppId(param._id));

    dispatch(getApplicationForm(param._id)).then((res) => {
      // console.log(78, res);
      if (res?.payload?.sectionOne?.internship == "yes") {
        dispatch(getInternshipDetailsByJobAppId(param._id)).then((respond) => {
          // console.log(81, respond);
          dispatch(getCirculationByCirculationId(respond?.payload?._id)).then(
            (a) => {
              dispatch(getApplicationForm(param._id));
              dispatch(getAssessmentByJobAppId(param._id));
              dispatch(getCirculationByJobAppId(param._id));
              dispatch(getInternshipDetailsByJobAppId(param._id));
            }
          );
        });
      } else {
        // console.log("no");
      }
    });

    dispatch(getCirculationByCirculationId(param._id)).then((res) => {
      // console.log(res);
      // console.log(res.payload.jobAppId);
      dispatch(getApplicationForm(res.payload.jobAppId));
      dispatch(getAssessmentByJobAppId(res.payload.jobAppId));
      dispatch(getCirculationByJobAppId(res.payload.jobAppId));
      dispatch(getInternshipDetailsByJobAppId(res.payload.jobAppId));
    });
  }, [param._id]);

  const handleClickBack = () => {
    navigate("/HRView");
  };

  const accessToken = localStorage.getItem("access_token");
  const token = jwt_decode(accessToken);
  const isHR = token?.realm_access?.roles?.includes("jobapp_hr");

  return (
    <>
      {isHR && (
        <>
          <NavbarMenu />
          <nav aria-label="breadcrumb" className="px-1">
            <ol className="breadcrumb">
              <li className="breadcrumb-item breadcrumb-item">
                <a href="#/Homepage" className="breadcrumbstyle">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item breadcrumb-item">
                <a href="#/HRView" className="breadcrumbstyle">
                  Main List
                </a>
              </li>
            </ol>
          </nav>
          <Container fluid>
            <Card style={styles.CardContainer}>
              {sectionOne?.place_of_birth === "" ? (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Biodata">
                    {sectionOne?.internship == "yes" ? (
                      <SectionOneInternship />
                    ) : (
                      <SectionOne />
                    )}
                  </Tab>
                  <Tab eventKey="1" title="HR Feedback">
                    <HRFeedback />
                  </Tab>
                </Tabs>
              ) : (
                <Tabs
                  activeKey={currentTab}
                  id="justify-tab"
                  className="mb-3 tabs"
                  onSelect={(k) => dispatch(updateCurrentTab(k))}
                  fill
                >
                  <Tab eventKey="0" title="Biodata">
                    {sectionOne?.internship == "yes" ? (
                      <SectionOneInternship />
                    ) : (
                      <SectionOne />
                    )}
                  </Tab>
                  <Tab eventKey="1" title="Family">
                    <SectionTwo />
                  </Tab>
                  <Tab eventKey="2" title="Education & Skill">
                    <SectionThree />
                  </Tab>
                  <Tab eventKey="3" title="Work Experience">
                    <SectionFour />
                  </Tab>
                  <Tab eventKey="4" title="References">
                    <SectionFive />
                  </Tab>
                  <Tab eventKey="5" title="Other Information">
                    <SectionSix />
                  </Tab>
                  <Tab eventKey="6" title="Writing Skill">
                    <SectionSeven />
                  </Tab>
                  <Tab eventKey="7" title="Acknowledgement">
                    <SectionEight />
                  </Tab>
                  <Tab eventKey="8" title="HR Feedback">
                    <HRFeedback />
                  </Tab>
                </Tabs>
              )}

              <Row className="mx-3">
                <Col>
                  {sectionOne?.place_of_birth ? (
                    currentTab == 0 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button variant="primary" onClick={handleClickBack}>
                            Back
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "left" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab - 1))
                            }
                          >
                            Previous
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button variant="primary" onClick={handleClickBack}>
                          Back
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "left" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab - 1))
                          }
                        >
                          Previous
                        </Button>
                      </ButtonGroup>
                    </>
                  )}

                  {sectionOne?.place_of_birth ? (
                    currentTab == 8 ? (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button variant="primary" onClick={handleClickBack}>
                            Back to list
                          </Button>
                        </ButtonGroup>
                      </>
                    ) : (
                      <>
                        <ButtonGroup
                          className="mt-3 mb-3"
                          style={{ float: "right" }}
                        >
                          <Button
                            variant="primary"
                            onClick={() =>
                              dispatch(updateCurrentTab(+currentTab + 1))
                            }
                          >
                            Next
                          </Button>
                        </ButtonGroup>
                      </>
                    )
                  ) : currentTab == 0 ? (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button
                          variant="primary"
                          onClick={() =>
                            dispatch(updateCurrentTab(+currentTab + 1))
                          }
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </>
                  ) : (
                    <>
                      <ButtonGroup
                        className="mt-3 mb-3"
                        style={{ float: "right" }}
                      >
                        <Button variant="primary" onClick={handleClickBack}>
                          Back to list
                        </Button>
                      </ButtonGroup>
                    </>
                  )}
                </Col>
              </Row>
            </Card>
          </Container>
        </>
      )}
    </>
  );
};
export default HRFormViewSite;
