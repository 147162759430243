import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoltLightning, faDownload } from "@fortawesome/free-solid-svg-icons";

import { styles } from "../HRTableListStyle";

import { getApplicationForm } from "../../../4.Store/ApplicationFormSlice";

const TableAssessmentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    assessmentList,
    currentHRAssessmentPage,
    totalCurrentHRAssessmentPage,
    searchAssessmentList,
    searchKeyWordAssessmentLists,
  } = useSelector((store) => store.HRTableList);

  const [recordsPerPage] = useState(20);
  const indexOfLastRecord = currentHRAssessmentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const handleClick = (_id) => {
    navigate(`/AssessmentForm/${_id}`);
    // console.log(_id);
  };

  const handleDownloadAssessmentBiodata = (_id, jobapplication) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/basic/${jobapplication._id}/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/basic/${jobapplication._id}/${_id}`;

      // Open a new tab linked to the PDF URL
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadAssessmentApplicationForm = (_id, jobapplication) => {
    dispatch(getApplicationForm(_id)).then((data) => {
      const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/full/${jobapplication._id}/${_id}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/full/${jobapplication._id}/${_id}`;

      // Open a new tab linked to the PDF URL
      window.open(pdfUrl, "_blank");
    });
  };

  return (
    <Table>
      <thead style={styles.TableHeader}>
        <tr>
          <th style={styles.ThText}>No</th>
          <th style={styles.ThText}>Name</th>
          <th style={styles.ThText}>Position</th>
          <th style={styles.ThText}>Interview Date</th>
          <th style={styles.ThText}>Interviewer Name</th>
          <th style={styles.ThText}>Status</th>
          <th style={styles.ThIcon}>
            <FontAwesomeIcon icon={faBoltLightning} />
          </th>
        </tr>
      </thead>
      <tbody>
        {assessmentList.map(
          (
            { _id, interviewer, approval, jobapplication, interviewDate },
            i
          ) => (
            <tr
              key={i}
              style={{
                backgroundColor: approval.interviewer_recommendation
                  ? "#ffffff"
                  : "#DDF4FC",
              }}
            >
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {i + 1 + indexOfFirstRecord}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {jobapplication?.sectionOne?.name.toUpperCase()}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {jobapplication?.sectionOne?.position_applied?.label ||
                  jobapplication?.sectionOne?.position_applied.toUpperCase()}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {interviewDate}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {interviewer?.interviewerName.toUpperCase()}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {approval?.interviewer_recommendation.toUpperCase()}
              </td>
              <td className="text-nowrap" style={{ textAlign: "left" }}>
                <span
                  style={{
                    ...styles.TdIconStyle,
                    display:
                      jobapplication?.isUpdatedByCandidate === 1
                        ? "inline"
                        : "none",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() =>
                      handleDownloadAssessmentApplicationForm(
                        _id,
                        jobapplication
                      )
                    }
                    icon={faDownload}
                    title="Print Form"
                    color="#E1AD01"
                  />
                </span>
                <span
                  style={{
                    ...styles.TdIconStyle,
                    display:
                      jobapplication?.isUpdatedByCandidate != 1
                        ? "inline"
                        : "none",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() =>
                      handleDownloadAssessmentBiodata(_id, jobapplication)
                    }
                    icon={faDownload}
                    title="Print Biodata"
                    color="blue"
                  />
                </span>
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};
export default TableAssessmentList;
