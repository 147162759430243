import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationList = ({ nPages, currentPage, handlePagination }) => {
  const pageNumbers = [...Array(nPages).keys()].map((index) => index + 1);

  const nextPage = () => {
    if (currentPage < nPages) handlePagination(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage > 1) handlePagination(currentPage - 1);
  };

  const renderPageNumbers = () => {
    const pagesToShow = 2; // Number of pages to show on each side
    const ellipsis = " ... ";
    const pages = [];

    for (let i = 1; i <= nPages; i++) {
      if (
        i === 1 || // Always show the first page
        i === nPages || // Always show the last page
        (i >= currentPage - pagesToShow && i <= currentPage + pagesToShow) // Show pages around the current page
      ) {
        pages.push(
          <Pagination.Item
            key={i}
            onClick={() => handlePagination(i)}
            className={`${currentPage === i ? "active" : ""}`}
          >
            {i}
          </Pagination.Item>
        );
      } else if (
        i === currentPage - pagesToShow - 1 || // Show the left ellipsis
        i === currentPage + pagesToShow + 1 // Show the right ellipsis
      ) {
        pages.push(<span key={i}>{ellipsis}</span>);
      }
    }

    return pages;
  };

  return (
    <Pagination
      className="pagination justify-content-center"
      style={{ marginBottom: "5px" }}
    >
      <Pagination.Prev onClick={prevPage} />
      {renderPageNumbers()}
      <Pagination.Next onClick={nextPage} />
    </Pagination>
  );
};

export default PaginationList;
