export const PreloaderSubmit = () => {
  return (
    <>
      <div id="preloader">
        <div id="loader"></div>
        <div id="loading-text">Submitting</div>
      </div>
    </>
  );
};

export const PreloaderUpload = () => {
  return (
    <>
      <div id="preloader">
        <div id="loader"></div>
        <div id="loading-text">Uploading</div>
      </div>
    </>
  );
};

export const PreloaderDownload = () => {
  return (
    <>
      <div id="preloader">
        <div id="loader"></div>
        <div id="loading-text">Downloading</div>
      </div>
    </>
  );
};

export const PreloaderSearch = () => {
  return (
    <>
      <div id="preloader">
        <div id="loader"></div>
        <div id="loading-text">Searching</div>
      </div>
    </>
  );
};
