import Swal from "sweetalert2";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card } from "react-bootstrap";

import { styles } from "../1.Pages/ApplicationForm/ApplicationFormStyle";
import SectionOneFull from "../1.Pages/ApplicationForm/SectionOneFull";
import SectionTwo from "../1.Pages/ApplicationForm/SectionTwo";
import SectionThree from "../1.Pages/ApplicationForm/SectionThree";
import SectionFour from "../1.Pages/ApplicationForm/SectionFour";
import SectionFive from "../1.Pages/ApplicationForm/SectionFive";
import SectionSix from "../1.Pages/ApplicationForm/SectionSix";
import SectionSeven from "../1.Pages/ApplicationForm/SectionSeven";
import SectionEight from "../1.Pages/ApplicationForm/SectionEight";

import {
  postApplicationForm,
  postThankyouEmail,
  postHrNotificationUpdateJobApplicationEmail,
} from "../4.Store/ApplicationFormSlice";
import { FormHeaderApplication } from "../2.Common/FormHeader";

const ApplicationFormFullSite = () => {
  const dispatch = useDispatch();

  const {
    _id,
    sectionOne,
    sectionTwo,
    sectionThree,
    sectionFour,
    sectionFive,
    sectionSix,
    sectionSeven,
    sectionEight,
    date,
    date2
  } = useSelector((state) => state.ApplicationForm);

  const {
    position_applied,
    name,
    identification_certification,
    date_of_birth,
    age,
    gender,
    contact_number,
    current_location,
    current_location_other,
    email,
    expected_salary,
    highest_qualification,
    fresh_graduate,
    fresh_graduate_no,
    address,
    race,
    health,
    place_of_birth,
    weight,
    income_tax_number,
    religion,
    dialect,
    height,
    epf_number,
    image,
    resume,
    cover_letter,
    certificate,
    acknowledgement1,
    internship,
    ads_source,
    ads_source_other,
    relocate,
  } = sectionOne;
  const { marital_status, emergency_references } = sectionTwo;
  const {
    english_written_skill,
    english_oral_skill,
    bahasa_written_skill,
    bahasa_oral_skill,
    mandarin_written_skill,
    mandarin_oral_skill,
  } = sectionThree;
  const {
    currentEmployer,
    currentPosition,
    currentTelephone,
    currentAddress,
    currentDuties,
    currentReason,
    currentSalary,
    currentFromDate,
    currentToDate,
    relatives,
    relatives_name,
    relatives_position,
    isHavePreviousEmployement,
    isCurrentWorking,
    currentFromMonth,
    currentFromYear,
    currentToMonth,
    currentToYear,
  } = sectionFour;
  const { references } = sectionFive;
  const {
    date_to_work,
    enquiry_previos_employer,
    bankrupt,
    arrested_indicted_convicted,
  } = sectionSix;
  const { essay, tellUsHr } = sectionSeven;
  const { acknowledgement2 } = sectionEight;

  // check validate tab
  let errors1,
    errors2,
    errors3,
    errors4,
    errors5,
    errors6,
    errors7 = "";
  const checkValidateTab1 = () => {
    if (image == "") {
      errors1 = { section: 1, part: "Profile Picture" };
      return false;
    }

    if (position_applied == "") {
      errors1 = { section: 1, part: "Position Applied" };
      return false;
    }

    if (name == "") {
      errors1 = { section: 1, part: "Full Name" };
      return false;
    }

    if (identification_certification == "") {
      errors1 = { section: 1, part: "Identification Certification" };
      return false;
    }

    if (gender == "") {
      errors1 = { section: 1, part: "Gender" };
      return false;
    }

    if (date_of_birth == "") {
      errors1 = { section: 1, part: "Date of Birth" };
      return false;
    }

    if (contact_number == "") {
      errors1 = { section: 1, part: "Contact Number" };
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email == "") {
      errors1 = { section: 1, part: "Valid Email" };
      return false;
    } else if (!emailRegex.test(email)) {
      errors1 = { section: 1, part: "Valid Email" };
      return false;
    }

    if (highest_qualification == "") {
      errors1 = { section: 1, part: "Highest Qualification" };
      return false;
    }

    if (current_location == "") {
      errors1 = { section: 1, part: "Current Location" };
      return false;
    }

    if (expected_salary == "") {
      errors1 = { section: 1, part: "Expected Salary" };
      return false;
    }

    if (fresh_graduate == "") {
      errors1 = { section: 1, part: "Fresh Graduate" };
      return false;
    }

    if (relocate == "") {
      errors1 = { section: 1, part: "Relocate Outstation" };
      return false;
    }

    if (ads_source == "") {
      errors1 = { section: 1, part: "Position Ads Source" };
      return false;
    }

    if (place_of_birth == "") {
      errors1 = { section: 1, part: "Place of Birth" };
      return false;
    }

    if (height == "") {
      errors1 = { section: 1, part: "Height" };
      return false;
    }

    if (weight == "") {
      errors1 = { section: 1, part: "Weight" };
      return false;
    }

    if (religion == "") {
      errors1 = { section: 1, part: "Religion" };
      return false;
    }

    if (race == "") {
      errors1 = { section: 1, part: "Race" };
      return false;
    }

    if (dialect == "") {
      errors1 = { section: 1, part: "Dialect" };
      return false;
    }

    if (address == "") {
      errors1 = { section: 1, part: "Address" };
      return false;
    }

    if (health == "") {
      errors1 = { section: 1, part: "Health" };
      return false;
    }
  };
  const checkValidateTab2 = () => {
    if (marital_status == "") {
      errors2 = { section: 2, part: "Marital Status" };
      return false;
    }

    if (emergency_references.length == 0) {
      errors2 = { section: 2, part: "Emergency References" };
      return false;
    }
  };
  const checkValidateTab3 = () => {
    if (english_written_skill == "") {
      errors3 = { section: 3, part: "English Written Skill" };
      return false;
    }

    if (english_oral_skill == "") {
      errors3 = { section: 3, part: "English Oral Skill" };
      return false;
    }

    if (bahasa_written_skill == "") {
      errors3 = { section: 3, part: "Bahasa Written Skill" };
      return false;
    }

    if (bahasa_oral_skill == "") {
      errors3 = { section: 3, part: "Bahasa Oral Skill" };
      return false;
    }

    if (mandarin_written_skill == "") {
      errors3 = { section: 3, part: "Mandarin Written Skill" };
      return false;
    }

    if (mandarin_oral_skill == "") {
      errors3 = { section: 3, part: "Mandarin Oral Skill" };
      return false;
    }
  };
  const checkValidateTab4 = () => {
    if (currentEmployer == "") {
      errors4 = { section: 4, part: "Current Employer Name" };
      return false;
    }
    if (currentAddress == "") {
      errors4 = { section: 4, part: "Current Employer Address" };
      return false;
    }
    if (currentTelephone == "") {
      errors4 = { section: 4, part: "Current Employer Contact Number" };
      return false;
    }
    if (currentPosition == "") {
      errors4 = { section: 4, part: "Current Position Held" };
      return false;
    }
    if (currentSalary == "") {
      errors4 = { section: 4, part: "Current Salary" };
      return false;
    }
    if (currentDuties == "") {
      errors4 = { section: 4, part: "Current Duties" };
      return false;
    }
    if (currentReason == "") {
      errors4 = { section: 4, part: "Current Reason(s) for Leaving" };
      return false;
    }
    if (isCurrentWorking == "") {
      errors4 = { section: 4, part: "Currently Working?" };
      return false;
    }

    if (currentFromMonth == "") {
      errors4 = { section: 4, part: "From Month" };
      return false;
    }
    if (currentFromYear == "") {
      errors4 = { section: 4, part: "From Year" };
      return false;
    }
    if (isCurrentWorking == "No" && currentToMonth == "") {
      errors4 = { section: 4, part: "To Month" };
      return false;
    }

    if (isCurrentWorking == "No" && currentToYear == "") {
      errors4 = { section: 4, part: "To Year" };
      return false;
    }

    if (isHavePreviousEmployement == "") {
      errors4 = { section: 4, part: "Previous Employment? " };
      return false;
    }
    if (relatives == "") {
      errors4 = { section: 4, part: "Relatives" };
      return false;
    }
  };
  const checkValidateTab5 = () => {
    if (references.length == 0) {
      errors5 = { section: 5, part: "References" };
      return false;
    }
  };
  const checkValidateTab6 = () => {
    if (date_to_work == "") {
      errors6 = { section: 6, part: "Date To Work" };
      return false;
    }
    if (enquiry_previos_employer == "") {
      errors6 = { section: 6, part: "Enquiry Previous Employer" };
      return false;
    }
    if (bankrupt == "") {
      errors6 = { section: 6, part: "Bankrupt / Debt Repayment" };
      return false;
    }
    if (arrested_indicted_convicted == "") {
      errors6 = {
        section: 6,
        part: "Arrested / Indicted / Convicted",
      };
      return false;
    }
  };
  const checkValidateTab7 = () => {
    if (essay.trim().split(/\s+/).length < 49) {
      errors7 = {
        section: 7,
        part: "Please Enter At Least 150 Words In Part 1",
      };
      return false;
    }
    if (tellUsHr.trim().split(/\s+/).length < 49) {
      errors7 = {
        section: 7,
        part: "Please Enter At Least 100 Words In Part 2",
      };
      return false;
    }
  };

  // error messages
  const errorMessages1 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors1.section} : ${errors1.part}</b>`,
    });
  };
  const errorMessages2 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors2.section} : ${errors2.part}</b>`,
    });
  };
  const errorMessages3 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors3.section} : ${errors3.part}</b>`,
    });
  };
  const errorMessages4 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors4.section} : ${errors4.part}</b>`,
    });
  };
  const errorMessages5 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors5.section} : ${errors5.part}</b>`,
    });
  };
  const errorMessages6 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b>Please Fill In </b><b style="color:red;">Section ${errors6.section} : ${errors6.part}</b>`,
    });
  };
  const errorMessages7 = () => {
    return Swal.fire({
      icon: "error",
      html: `<b style="color:red;">Section ${errors7.section} : ${errors7.part}</b>`,
    });
  };

  const handleComplete = () => {
    if (acknowledgement2) {
      const entity = {
        sectionOne,
        sectionTwo,
        sectionThree,
        sectionFour,
        sectionFive,
        sectionSix,
        sectionSeven,
        sectionEight,
        date,
        date2,
        status: 0,
        isUpdatedByCandidate: 1,
      };

      dispatch(postApplicationForm(entity))
        .unwrap()
        .then((data) => {
          dispatch(postThankyouEmail({ id: data.insertedId }));
          dispatch(
            postHrNotificationUpdateJobApplicationEmail({ id: data.insertedId })
          );
        })
        .then(() => {
          Swal.fire("Thank You For Applying!");
        })
        .then(() => {
          window.location.href = "https://www.hsl.com.my/about/career/";
        })
        .catch((err) => {
          // console.log(err);
          Swal.fire("Error / Masalah", err, "error", "OK");
        });
    } else {
      return Swal.fire({
        icon: "error",
        html: `<b>Please Fill In </b><b style="color:red;">Please tick Declaration!</b>`,
      });
    }
  };

  return (
    <Container fluid>
      <Card style={styles.CardContainer}>
        <FormHeaderApplication />
        <FormWizard stepSize="sm" onComplete={handleComplete}>
          <FormWizard.TabContent title="Biodata">
            <SectionOneFull />
          </FormWizard.TabContent>

          <FormWizard.TabContent
            title="Family"
            isValid={checkValidateTab1()}
            validationError={errorMessages1}
          >
            <SectionTwo />
          </FormWizard.TabContent>

          <FormWizard.TabContent
            title="Language, Skill & Education"
            isValid={checkValidateTab2()}
            validationError={errorMessages2}
          >
            <SectionThree />
          </FormWizard.TabContent>

          <FormWizard.TabContent
            title="Work Experience"
            isValid={checkValidateTab3()}
            validationError={errorMessages3}
          >
            <SectionFour />
          </FormWizard.TabContent>

          <FormWizard.TabContent
            title="References"
            isValid={checkValidateTab4()}
            validationError={errorMessages4}
          >
            <SectionFive />
          </FormWizard.TabContent>

          <FormWizard.TabContent
            title="Other Informations"
            isValid={checkValidateTab5()}
            validationError={errorMessages5}
          >
            <SectionSix />
          </FormWizard.TabContent>

          <FormWizard.TabContent
            title="Writing Skills"
            isValid={checkValidateTab6()}
            validationError={errorMessages6}
          >
            <SectionSeven />
          </FormWizard.TabContent>

          <FormWizard.TabContent
            title="Acknowledgement"
            isValid={checkValidateTab7()}
            validationError={errorMessages7}
          >
            <SectionEight />
          </FormWizard.TabContent>
        </FormWizard>
      </Card>
    </Container>
  );
};
export default ApplicationFormFullSite;
