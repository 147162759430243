import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowClose,
  faPlus,
  faMinus,
  faRotateLeft,
  faRotateRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { triggerBase64Download } from "common-base64-downloader-react";

import "./PDFViewer.css";
import { Button, ButtonGroup } from "react-bootstrap";

let ceiling = 0;

const styles = {
  PageText: { marginRight: "1em", textAlign: "middle !important" },
  BtnGroup: { marginRight: "0.5em" },
  Btn: {
    color: "white",
    marginRight: "2px",
  },
  Icon: {
    cursor: "pointer",
  },
};

const PDFViewer = (props) => {
  // console.log(props);
  const { onClose, fileObject } = props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1.5);

  const { sectionOne } = useSelector((state) => state.ApplicationForm);

  useEffect(() => {
    const controllerHeight =
      document.getElementById("PDFViewController").clientHeight;
    // console.log(document.documentElement.scrollTop);
    document.getElementById("PDFViewer").style.position = "absolute";
    document.getElementById("PDFViewer").style.left = "0%";
    document.getElementById("PDFViewer").style.top =
      controllerHeight + document.documentElement.scrollTop + "px";

    ceiling = controllerHeight + document.documentElement.scrollTop;

    // console.log(document.getElementById("PDFViewer").style);
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      const controllerHeight = 1500;
      // document.getElementById("PDFViewController").clientHeight;
      // console.log(document.documentElement.scrollTop);
      // console.log(document.getElementById("PDFViewController").clientHeight);
      let top = window.pageYOffset || document.documentElement.scrollTop;
      // console.log(top + " " + ceiling);
      if (top < ceiling - controllerHeight) {
        window.scrollTo(0, ceiling - controllerHeight);
      }
    });
  });

  // Zoom In Out Btn
  const zoomIn = () => {
    // console.log("AAAA1");
    setScale(scale * 1.2);
  };
  const zoomOut = () => {
    // console.log("AAAA2");
    setScale(scale / 1.2);
  };

  // Rotate Left Right Btn
  const rotateLeft = () => {
    switch (rotation) {
      case 0:
        setRotation(270);
        break;
      case 90:
        setRotation(0);
        break;
      case 180:
        setRotation(90);
        break;
      case 270:
        setRotation(180);
        break;
      default:
    }
  };
  const rotateRight = () => {
    switch (rotation) {
      case 0:
        setRotation(90);
        break;
      case 90:
        setRotation(180);
        break;
      case 180:
        setRotation(270);
        break;
      case 270:
        setRotation(0);
        break;
      default:
    }
  };

  // First Last Btn
  const gotoFirstPage = () => {
    setPageNumber(1);
  };
  const gotoLastPage = () => {
    setPageNumber(numPages);
  };

  // Previous Next Btn
  const gotoPrevPage = () => {
    if (pageNumber === 1) {
      setPageNumber(1);
    } else {
      setPageNumber(pageNumber - 1);
    }
  };
  const gotoNextPage = () => {
    if (pageNumber === numPages) {
      setPageNumber(1);
    } else {
      setPageNumber(pageNumber + 1);
    }
  };

  // Download Btn
  const handleDownload = () => {
    // console.log(fileObject);
    triggerBase64Download(
      fileObject,
      `${sectionOne?.name}` + " " + "Attachment"
    );
  };

  // Close Btn
  const handleClose = () => {
    onClose && onClose();
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className="DarkScreen" />
      <div id="PDFViewController" className="PDFViewController">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <span style={styles.PageText}>
                  Page {pageNumber} of {numPages}
                </span>

                <ButtonGroup style={styles.BtnGroup}>
                  <Button style={styles.Btn} onClick={zoomIn}>
                    <FontAwesomeIcon style={styles.Icon} icon={faPlus} />
                  </Button>
                  <Button style={styles.Btn} onClick={zoomOut}>
                    <FontAwesomeIcon style={styles.Icon} icon={faMinus} />
                  </Button>
                </ButtonGroup>

                <ButtonGroup style={styles.BtnGroup}>
                  <Button style={styles.Btn} onClick={rotateLeft}>
                    <FontAwesomeIcon style={styles.Icon} icon={faRotateLeft} />
                  </Button>
                  <Button style={styles.Btn} onClick={rotateRight}>
                    <FontAwesomeIcon style={styles.Icon} icon={faRotateRight} />
                  </Button>
                </ButtonGroup>

                <ButtonGroup style={styles.BtnGroup}>
                  <Button style={styles.Btn} onClick={gotoFirstPage}>
                    <FontAwesomeIcon
                      style={styles.Icon}
                      icon={faAngleDoubleLeft}
                    />
                  </Button>
                  <Button style={styles.Btn} onClick={gotoLastPage}>
                    <FontAwesomeIcon
                      style={styles.Icon}
                      icon={faAngleDoubleRight}
                    />
                  </Button>
                </ButtonGroup>

                <ButtonGroup style={styles.BtnGroup}>
                  <Button style={styles.Btn} onClick={gotoPrevPage}>
                    <FontAwesomeIcon style={styles.Icon} icon={faAngleLeft} />
                  </Button>
                  <Button style={styles.Btn} onClick={gotoNextPage}>
                    <FontAwesomeIcon style={styles.Icon} icon={faAngleRight} />
                  </Button>
                </ButtonGroup>

                <ButtonGroup style={styles.BtnGroup}>
                  <Button style={styles.Btn} onClick={handleDownload}>
                    <FontAwesomeIcon style={styles.Icon} icon={faDownload} />
                  </Button>
                </ButtonGroup>
              </div>
              <div>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleClose}
                  icon={faWindowClose}
                  className="fa-2xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="PDFViewer" id="PDFViewer">
        <Document
          file={fileObject}
          pageIndex={pageNumber}
          rotate={rotation}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page scale={scale} pageNumber={pageNumber} />
        </Document>
      </div>
    </>
  );
};
export default PDFViewer;
