import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoltLightning, faDownload } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { styles } from "../HRTableListStyle";

import { getCirculationByCirculationId } from "../../../4.Store/CirculationSlice";

const TableApprovedForInterviewList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    circulationListByStatus,
    currentApproveInterviewPage,
    totalCurrentApproveInterviewPage,
    searchCirculationListByStatus,
    searchKeyWordCirculationListByStatusLists,
  } = useSelector((store) => store.HRTableList);

  const [recordsPerPage] = useState(20);
  const indexOfLastRecord = currentApproveInterviewPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const handleClick = (_id) => {
    navigate(`/HRViewForm/${_id}`);
    // console.log(_id);
  };

  const handleDownloadBiodata = (_id) => {
    dispatch(getCirculationByCirculationId(_id)).then((data) => {
      // console.log(data);
      const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}`;
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadApplicationForm = (_id) => {
    dispatch(getCirculationByCirculationId(_id)).then((data) => {
      // console.log(data);
      const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/full/${data?.payload?.jobAppId}`;
      // const pdfUrl = `http://172.16.150.119:5000/api/pdfs33/pdf/full/${data?.payload?.jobAppId}`;
      window.open(pdfUrl, "_blank");
    });
  };

  return (
    <Table>
      <thead style={styles.TableHeader}>
        <tr>
          <th style={styles.ThText}>No</th>
          <th style={styles.ThText}>Date Applied</th>
          <th style={styles.ThText}>Name</th>
          <th style={styles.ThText}>Position</th>
          <th style={styles.ThText}>Date Approval</th>
          <th style={styles.ThText}>Interview Details</th>
          <th style={styles.ThText}>Supervisor</th>
          <th style={styles.ThText}>Status</th>
          <th style={styles.ThIcon}>
            <FontAwesomeIcon icon={faBoltLightning} />
          </th>
        </tr>
      </thead>
      <tbody>
        {circulationListByStatus.map(
          (
            {
              _id,
              jobapplication,
              circulationStatus,
              svName,
              ivDate,
              ivTime,
              ivMethod,
              ivAMPM,
              approvalDate,
              isInterviewerSet,
            },
            i
          ) => (
            <tr
              key={i}
              style={{
                backgroundColor:
                  isInterviewerSet == 1 || circulationStatus == 130 ? "#ffffff" : "#DDF4FC",
                  // isInterviewerSet === 1
                  //   ? "#ffffff"
                  //   : circulationStatus == 130
                  //   ? "#fdb0aa"
                  //   : "#DDF4FC",
              }}
            >
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {i + 1 + indexOfFirstRecord}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {jobapplication?.date2}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {jobapplication?.sectionOne?.name.toUpperCase()}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {jobapplication?.sectionOne?.position_applied?.label ||
                  jobapplication?.sectionOne?.position_applied.toUpperCase()}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {approvalDate}
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                <ul style={{ textAlign: "left" }}>
                  <li>{ivDate}</li>
                  <li>{ivTime?.label + ivAMPM?.label || ivTime}</li>
                  <li>{ivMethod?.label}</li>
                </ul>
              </td>
              <td style={styles.TdText} onClick={() => handleClick(_id)}>
                {svName && svName.toUpperCase()}
              </td>
              {/* <td style={styles.TdText}>
                {circulationStatus == 130
                  ? "IV CANCEL"
                  : jobapplication?.finalStatus}
              </td> */}
              <td style={ circulationStatus == 130 ? { color: "red" } : styles.TdText }>
                {circulationStatus == 130
                  ? "IV CANCEL"
                  : jobapplication?.finalStatus}
              </td>
              <td className="text-nowrap" style={{ textAlign: "left" }}>
                <span
                  style={{
                    ...styles.TdIconStyle,
                    display:
                      jobapplication?.isUpdatedByCandidate === 1
                        ? "inline"
                        : "none",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => handleDownloadApplicationForm(_id)}
                    icon={faDownload}
                    title="Print Form"
                    color="#E1AD01"
                  />
                </span>
                <span
                  style={{
                    ...styles.TdIconStyle,
                    display:
                      jobapplication?.isUpdatedByCandidate != 1
                        ? "inline"
                        : "none",
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => handleDownloadBiodata(_id)}
                    icon={faDownload}
                    title="Print Biodata"
                    color="blue"
                  />
                </span>
              </td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};
export default TableApprovedForInterviewList;
