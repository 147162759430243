import { useDispatch, useSelector } from "react-redux";
import { Container, Form } from "react-bootstrap";

import { styles } from "./ApplicationFormStyle";

import { updateSectionSeven } from "../../4.Store/ApplicationFormSlice";

const SectionSeven = () => {
  const dispatch = useDispatch();
  const { sectionSeven } = useSelector((state) => state.ApplicationForm);
  const { essay, tellUsHr, essayWordCount, tellUsHrWordCount } = sectionSeven;

  const handleChangeFormEssay = (e) => {
    const entity = {
      ...sectionSeven,
      essay: e.target.value,
      essayWordCount: e.target.value.trim().split(/\s+/).length,
    };
    dispatch(updateSectionSeven(entity));
  };

  const handleChangeFormTellUsHr = (e) => {
    const entity = {
      ...sectionSeven,
      tellUsHr: e.target.value,
      tellUsHrWordCount: e.target.value.trim().split(/\s+/).length,
    };
    dispatch(updateSectionSeven(entity));
  };

  return (
    <Container fluid>
      <Form>
        <h5 className="px-2" style={styles.TextHeader5}>
          (A) Part 1
        </h5>
        <Form.Group className="px-2 py-3">
          <Form.Label
            style={{ fontSize: "15px", textAlign: "start", display: "flex" }}
          >
            In About 150 Words, Write About Yourself And Your Family.&nbsp;
            <span className="star">*</span>
            &nbsp;&nbsp;
          </Form.Label>

          <Form.Control
            name="essay"
            value={essay}
            onChange={handleChangeFormEssay}
            required
            type="text"
            as="textarea"
            rows="10"
          />
        </Form.Group>

        <div style={{ textAlign: "right", marginRight: "15px" }}>
          <span>Word Count: {essayWordCount} </span>
        </div>

        <h5 className="px-2" style={styles.TextHeader5}>
          (B) Part 2
        </h5>
        <Form.Group className="px-2 py-3">
          <Form.Label
            style={{ fontSize: "15px", textAlign: "start", display: "flex" }}
          >
            In About 100 Words, Tell Us How You Would Be A Great Fit For The
            Role By Highlighting & Elaborating On Your Past Experiences,
            Qualifications, As Well As Skills & Areas Of Expertise.&nbsp;
            <span className="star">*</span>
            &nbsp;&nbsp;
          </Form.Label>

          <Form.Control
            name="tellUsHr"
            value={tellUsHr}
            onChange={handleChangeFormTellUsHr}
            required
            type="text"
            as="textarea"
            rows="10"
          />
        </Form.Group>

        <div style={{ textAlign: "right", marginRight: "15px" }}>
          <span>Word Count: {tellUsHrWordCount}</span>
        </div>
      </Form>
    </Container>
  );
};
export default SectionSeven;
