import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../5.Services/apiServices";

const namespace = "HR Table List";

//Application List
export const getHRApplicationListPagination = createAsyncThunk(
  `${namespace}/getHRApplicationListPagination`,
  async (
    { pageNo, pageSize, column, columnDirection },
    { rejectWithValue }
  ) => {
    const api = "Pagination_GetAll";
    const opt = {
      params: {
        pageNo: pageNo,
        pageSize: pageSize,
        column: column,
        columnDirection: columnDirection,
      },
    };
    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getHRApplicationListSearch = createAsyncThunk(
  `${namespace}/getHRApplicationListSearch`,
  async ({ parsedObject, column, columnDirection }, { rejectWithValue }) => {
    const api = "Search_GetAll";
    const opt = {
      params: {
        parsedObject,
        column,
        columnDirection,
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postExcelFileApplicationList = createAsyncThunk(
  `${namespace}/postExcelFileApplicationList`,
  async (body, { rejectWithValue }) => {
    const api = "CreateExcel_ApplicationList";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Internship List
export const getHRInternshipListPagination = createAsyncThunk(
  `${namespace}/Pagination_GetInternship`,
  async (
    { pageNo, pageSize, column, columnDirection },
    { rejectWithValue }
  ) => {
    const api = "Pagination_GetInternship";
    const opt = {
      params: {
        pageNo: pageNo,
        pageSize: pageSize,
        column: column,
        columnDirection: columnDirection,
      },
    };
    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getHRInternshipListSearch = createAsyncThunk(
  `${namespace}/getHRInternshipListSearch`,
  async ({ parsedObject, column, columnDirection }, { rejectWithValue }) => {
    const api = "Search_GetInternship";
    const opt = {
      params: {
        parsedObject,
        column,
        columnDirection,
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Review Recommend List
export const getHRReviewRecListPagination = createAsyncThunk(
  `${namespace}/getHRReviewRecListPagination`,
  async (
    { pageNo, pageSize, column, columnDirection },
    { rejectWithValue }
  ) => {
    const api = "Pagination_GetByStatus";
    const replace = {
      search: "statusnumber",
      replace: 200,
    };
    const opt = {
      replace: replace,
      params: {
        pageNo: pageNo,
        pageSize: pageSize,
        column: column,
        columnDirection: columnDirection,
      },
    };
    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getHRReviewRecListSearch = createAsyncThunk(
  `${namespace}/getHRReviewRecListSearch`,
  async ({ parsedObject, column, columnDirection }, { rejectWithValue }) => {
    const api = "Search_GetByStatus";
    const replace = {
      search: "statusnumber",
      replace: 200,
    };
    const opt = {
      replace: replace,
      params: {
        parsedObject,
        column,
        columnDirection,
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Approved For Interview List
export const getHRApprovedInterviewListPagination = createAsyncThunk(
  `${namespace}/getHRApprovedInterviewListPagination`,
  async (
    { pageNo, pageSize, column, columnDirection },
    { rejectWithValue }
  ) => {
    const api = "Pagination_GetCirculation";
    const opt = {
      params: {
        pageNo: pageNo,
        pageSize: pageSize,
        column: column,
        columnDirection: columnDirection,
      },
    };
    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const approvalDateString = res?.approvalDate?.split(",")[0];
        const dateString = res?.jobapplication?.date?.split(",")[0];
        res.approvalDate = approvalDateString;
        res.jobapplication.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getHRApprovedInterviewListSearch = createAsyncThunk(
  `${namespace}/getHRApprovedInterviewListSearch`,
  async ({ parsedObject, column, columnDirection }, { rejectWithValue }) => {
    const api = "Search_GetCirculation";
    const opt = {
      params: {
        parsedObject,
        column,
        columnDirection,
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const approvalDateString = res?.approvalDate?.split(",")[0];
        const dateString = res?.jobapplication?.date?.split(",")[0];
        res.approvalDate = approvalDateString;
        res.jobapplication.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postExcelFileCirculation = createAsyncThunk(
  `${namespace}/postExcelFileCirculation`,
  async (body, { rejectWithValue }) => {
    const api = "CreateExcel_ApprovedInterviewList";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Assessment List
export const getHRAssessmentListPagination = createAsyncThunk(
  `${namespace}/getHRAssessmentListPagination`,
  async (
    { pageNo, pageSize, column, columnDirection },
    { rejectWithValue }
  ) => {
    const api = "Pagination_GetAssessment";
    const replace = {
      search: "statusnumber",
      replace: 600,
    };
    const opt = {
      replace: replace,
      params: {
        pageNo: pageNo,
        pageSize: pageSize,
        column: column,
        columnDirection: columnDirection,
      },
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getHRAssessmentListSearch = createAsyncThunk(
  `${namespace}/Search_GetAssessment`,
  async ({ parsedObject, column, columnDirection }, { rejectWithValue }) => {
    const api = "Search_GetAssessment";
    const replace = {
      search: "statusnumber",
      replace: 600,
    };
    const opt = {
      replace: replace,
      params: {
        parsedObject,
        column,
        columnDirection,
      },
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const postExcelFileAssessment = createAsyncThunk(
  `${namespace}/postExcelFileAssessment`,
  async (body, { rejectWithValue }) => {
    const api = "CreateExcel_AssessmentList";
    const opt = {
      body: body,
    };

    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Archive List
export const getHRArchiveListPagination = createAsyncThunk(
  `${namespace}/getHRArchiveListPagination`,
  async (page, { rejectWithValue }) => {
    const api = "Pagination_GetByStatus";
    const replace = {
      search: "statusnumber",
      replace: 30,
    };
    const opt = {
      replace: replace,
      params: {
        pageNo: page,
        pageSize: 20, // Set the desired page size
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getHRArchiveListSearch = createAsyncThunk(
  `${namespace}/getHRArchiveListSearch`,
  async (key, { rejectWithValue }) => {
    const api = "Search_GetByStatus";
    const replace = {
      search: "statusnumber",
      replace: 30,
    };
    const opt = {
      replace: replace,
      params: {
        search: key,
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//KIV List
export const getHRKivListPagination = createAsyncThunk(
  `${namespace}/getHRKivListPagination`,
  async (page, { rejectWithValue }) => {
    const api = "Pagination_GetByStatus";
    const replace = {
      search: "statusnumber",
      replace: 50,
    };
    const opt = {
      replace: replace,
      params: {
        pageNo: page,
        pageSize: 20,
      },
    };
    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getHRKivListSearch = createAsyncThunk(
  `${namespace}/getHRKivListSearch`,
  async (key, { rejectWithValue }) => {
    const api = "Search_GetByStatus";
    const replace = {
      search: "statusnumber",
      replace: 50,
    };
    const opt = {
      replace: replace,
      params: {
        search: key,
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

//Duplicate List
export const getHRDuplicateListPagination = createAsyncThunk(
  `${namespace}/getHRDuplicateListPagination`,
  async (page, { rejectWithValue }) => {
    const api = "Pagination_GetByStatus";
    const replace = {
      search: "statusnumber",
      replace: 1,
    };
    const opt = {
      replace: replace,
      params: {
        pageNo: page,
        pageSize: 20, // Set the desired page size
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getHRDuplicateListSearch = createAsyncThunk(
  `${namespace}/getHRDuplicateListSearch`,
  async (key, { rejectWithValue }) => {
    const api = "Search_GetByStatus";
    const replace = {
      search: "statusnumber",
      replace: 1,
    };
    const opt = {
      replace: replace,
      params: {
        search: key,
      },
    };

    try {
      const response = await apiServices(api, opt);
      response.data.data.forEach((res) => {
        const dateString = res?.date?.split(",")[0];
        res.date = dateString;
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  error: false,

  //Application List
  lists: [],
  currentPage: 1,
  totalCurrentPage: 0,
  searchLists: [],
  searchKeyWordLists: "",

  //Internship List
  internshipLists: [],
  currentInternshipPage: 1,
  totalCurrentInternshipPage: 0,
  searchInternshipLists: [],
  searchKeyWordInternshipLists: "",

  //Review Recommend List
  reviewRecommendLists: [],
  currentRevRecPage: 1,
  totalCurrentRevRecPage: 0,
  searchReviewRecommendLists: [],
  searchKeyWordReviewRecommendLists: "",

  //Approved For Interview List
  circulationListByStatus: [],
  currentApproveInterviewPage: 1,
  totalCurrentApproveInterviewPage: 0,
  searchCirculationListByStatus: [],
  searchKeyWordCirculationListByStatusLists: "",

  //Assessment List
  assessmentList: [],
  currentHRAssessmentPage: 1,
  totalCurrentHRAssessmentPage: 0,
  searchAssessmentList: [],
  searchKeyWordAssessmentLists: "",

  //Archive List
  archieveLists: [],
  currentArchievePage: 1,
  totalCurrentArchievePage: 0,
  searchArchieveLists: [],
  searchKeyWordArchieveLists: "",

  //KIV List
  kivLists: [],
  currentKivPage: 1,
  totalCurrentKivPage: 0,
  searchKivLists: [],
  searchKeyWordKivLists: "",

  //Duplicate List
  duplicateLists: [],
  currentDuplicatePage: 1,
  totalCurrentDuplicatePage: 0,
  searchDuplicateLists: [],
  searchKeyWordDuplicateLists: "",
};

const HRTableListSlice = createSlice({
  name: "HR Table List",
  initialState,
  reducers: {
    //Application List
    updateCurrentPage(state, action) {
      return { ...state, currentPage: action.payload };
    },
    updateSearchApplicationList(state, action) {
      // console.log(action.payload);
      localStorage.setItem("searchKeyWordLists", action.payload);
      return { ...state, searchKeyWordLists: action.payload };
    },

    //Internship List
    updateCurrentInternshipPage(state, action) {
      return { ...state, currentInternshipPage: action.payload };
    },
    updateSearchInternshipList(state, action) {
      localStorage.setItem("searchKeyWordInternshipLists", action.payload);
      return { ...state, searchKeyWordInternshipLists: action.payload };
    },

    //Review Recommend List
    updateCurrentRevRecPage(state, action) {
      return { ...state, currentRevRecPage: action.payload };
    },
    updateSearchReviewRecommendList(state, action) {
      localStorage.setItem("searchKeyWordReviewRecommendLists", action.payload);
      return { ...state, searchKeyWordReviewRecommendLists: action.payload };
    },

    //Approved For Interview List
    updateCurrentApproveInterviewPage(state, action) {
      return { ...state, currentApproveInterviewPage: action.payload };
    },
    updateSearchApprovedInterviewList(state, action) {
      localStorage.setItem("searchKeyWordCirculationListByStatusLists", action.payload);
      return {
        ...state,
        searchKeyWordCirculationListByStatusLists: action.payload,
      };
    },

    //Assessment List
    updateCurrentHRAssessmentPage(state, action) {
      return { ...state, currentHRAssessmentPage: action.payload };
    },
    updateSearchAssessmentList(state, action) {
      localStorage.setItem("searchKeyWordAssessmentLists", action.payload);
      return { ...state, searchKeyWordAssessmentLists: action.payload };
    },

    //Archive List
    updateCurrentArchievePage(state, action) {
      return { ...state, currentArchievePage: action.payload };
    },
    updateSearchArchiveList(state, action) {
      return { ...state, searchKeyWordArchieveLists: action.payload };
    },

    //KIV List
    updateCurrentKivPage(state, action) {
      return { ...state, currentKivPage: action.payload };
    },
    updateSearchKivList(state, action) {
      return { ...state, searchKeyWordKivLists: action.payload };
    },

    //Duplicate List
    updateCurrentDuplicatePage(state, action) {
      return { ...state, currentDuplicatePage: action.payload };
    },
    updateSearchDuplicateList(state, action) {
      return { ...state, searchKeyWordDuplicateLists: action.payload };
    },
  },
  extraReducers: {
    //Application List
    [getHRApplicationListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRApplicationListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.lists = action.payload.data;
      state.totalCurrentPage = action.payload.totalPages;
    },
    [getHRApplicationListPagination.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [getHRApplicationListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRApplicationListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.lists = action.payload.data;
      // console.log(state.lists);
    },
    [getHRApplicationListSearch.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postExcelFileApplicationList.pending]: (state, action) => {
      state.loading = true;
    },
    [postExcelFileApplicationList.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postExcelFileApplicationList.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    //Internship List
    [getHRInternshipListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRInternshipListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.internshipLists = action.payload.data;
      state.totalCurrentInternshipPage = action.payload.totalPages;
    },
    [getHRInternshipListPagination.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [getHRInternshipListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRInternshipListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.internshipLists = action.payload.data;
    },
    [getHRInternshipListSearch.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    //Review Recommend List
    [getHRReviewRecListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRReviewRecListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewRecommendLists = action.payload.data;
      state.totalCurrentRevRecPage = action.payload.totalPages;
    },
    [getHRReviewRecListPagination.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [getHRReviewRecListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRReviewRecListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.reviewRecommendLists = action.payload.data;
    },
    [getHRReviewRecListSearch.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    //Approved For Interview List
    [getHRApprovedInterviewListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRApprovedInterviewListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.circulationListByStatus = action.payload.data;
      state.totalCurrentApproveInterviewPage = action.payload.totalPages;
    },
    [getHRApprovedInterviewListPagination.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [getHRApprovedInterviewListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRApprovedInterviewListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      // state.searchCirculationListByStatus = action.payload.data;
      state.circulationListByStatus = action.payload.data;
    },
    [getHRApprovedInterviewListSearch.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postExcelFileCirculation.pending]: (state, action) => {
      state.loading = true;
    },
    [postExcelFileCirculation.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postExcelFileCirculation.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    //Assessment List
    [getHRAssessmentListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRAssessmentListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentList = action.payload.data;
      state.totalCurrentHRAssessmentPage = action.payload.totalPages;
    },
    [getHRAssessmentListPagination.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [getHRAssessmentListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRAssessmentListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.assessmentList = action.payload.data;
      // state.searchAssessmentList = action.payload.data;
    },
    [getHRAssessmentListSearch.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [postExcelFileAssessment.pending]: (state, action) => {
      state.loading = true;
    },
    [postExcelFileAssessment.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postExcelFileAssessment.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    //Archive List
    [getHRArchiveListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRArchiveListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.archieveLists = action.payload.data;
      state.totalCurrentArchievePage = action.payload.totalPages;
    },
    [getHRArchiveListPagination.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [getHRArchiveListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRArchiveListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchArchieveLists = action.payload.data;
    },
    [getHRArchiveListSearch.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    //KIV List
    [getHRKivListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRKivListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.kivLists = action.payload.data;
      state.totalCurrentKivPage = action.payload.totalPages;
    },
    [getHRKivListPagination.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [getHRKivListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRKivListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchKivLists = action.payload.data;
    },
    [getHRKivListSearch.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    //Duplicate List
    [getHRDuplicateListPagination.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRDuplicateListPagination.fulfilled]: (state, action) => {
      state.loading = false;
      state.duplicateLists = action.payload.data;
      state.totalCurrentDuplicatePage = action.payload.totalPages;
    },
    [getHRDuplicateListPagination.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
    [getHRDuplicateListSearch.pending]: (state, action) => {
      state.loading = true;
    },
    [getHRDuplicateListSearch.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchDuplicateLists = action.payload.data;
    },
    [getHRDuplicateListSearch.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
  },
});

export const {
  updateCurrentPage,
  updateSearchApplicationList,
  updateCurrentInternshipPage,
  updateSearchInternshipList,
  updateCurrentRevRecPage,
  updateSearchReviewRecommendList,
  updateCurrentApproveInterviewPage,
  updateSearchApprovedInterviewList,
  updateCurrentHRAssessmentPage,
  updateSearchAssessmentList,
  updateCurrentArchievePage,
  updateSearchArchiveList,
  updateCurrentKivPage,
  updateSearchKivList,
  updateCurrentDuplicatePage,
  updateSearchDuplicateList,
} = HRTableListSlice.actions;
export default HRTableListSlice.reducer;
