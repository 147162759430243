import Swal from "sweetalert2";
import Select from "react-select";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Card, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

import { styles } from "../HRFeedbackStyle";

import {
  getCirculationByJobAppId,
  postCirculation,
  putCirculation,
  postCirculationSvEmail,
  postHrNotifyAfterCirculationEmail,
  updateSvId,
  getSupervisorList,
} from "../../../4.Store/CirculationSlice";
import {
  postCirculationPushNotification,
  postTestNotification,
} from "../../../4.Store/NotificationSlice";

const CirculationList = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const [supervisorDropdown, setSupervisorDropdown] = useState("");
  const [supervisors, setSupervisors] = useState([]);

  const { _id, status, hrRemark, sectionOne } = useSelector(
    (state) => state.ApplicationForm
  );
  const {
    jobAppId,
    svId,
    svRemark,
    ivDate,
    ivTime,
    ivDuration,
    ivMethod,
    circulationList,
    circulationDate,
  } = useSelector((state) => state.Circulation);
  const { dateSent, timeSent } = useSelector((state) => state.Notification);

  useEffect(() => {
    dispatch(getSupervisorList()).then((res) => {
      const arr = [];
      let result = res.payload;

      result.map((supervisor) => {
        const capitalizedSupervisorName = supervisor.name.toUpperCase();

        return arr.push({
          value: supervisor.id,
          label: capitalizedSupervisorName,
          email: supervisor.email,
        });
      });

      arr.sort((a, b) => a.label.localeCompare(b.label));
      setSupervisors(arr);
    });
  }, []);

  const handleDropdownSupervisor = (value) => {
    setSupervisorDropdown(value);
    dispatch(updateSvId(value));
  };

  const handleSubmitCirculation = () => {
    if (svId === undefined) {
      return Swal.fire({
        title: "Please Choose Supervisor",
      });
    }

    const entity = {
      jobAppId: _id,
      hrRemark,
      svId: supervisorDropdown.value,
      svName: supervisorDropdown.label,
      svEmail: supervisorDropdown.email,
      svRemark,
      ivDate,
      ivTime,
      ivDuration,
      ivMethod,
      circulationStatus: 400,
      circulationDate,
      dateUpdatedCirculationStatus: moment().format("yyyy-MM-DD, h:mm:ss a"),
    };
    dispatch(postCirculation(entity))
      .then((res) => {
        console.log(res);
        dispatch(postCirculationSvEmail({ id: res.payload.insertedId }));
        dispatch(
          postHrNotifyAfterCirculationEmail({ id: res.payload.insertedId })
        );
        // const entity = {
        //   jobAppId: _id,
        //   circulationId: res.payload.insertedId,
        //   svId: supervisorDropdown.value,
        //   candidateName: sectionOne.name,
        //   dateSent,
        //   timeSent,
        //   isRead: 0,
        // };
        // console.log(entity);
        // dispatch(postCirculationPushNotification(entity));
        const entity = {
          jobAppId: _id,
          circulationId: res.payload.insertedId,
          svId: supervisorDropdown.value,
          candidateName: sectionOne.name,
          dateSent,
          timeSent,
          isRead: 0,
        };
        dispatch(postTestNotification(entity));
      })
      .then(() => {
        if (param._id) {
          dispatch(getCirculationByJobAppId(param._id));
        }
        if (jobAppId) {
          dispatch(getCirculationByJobAppId(jobAppId));
        }
      })
      .then(() => {
        Swal.fire("Successfully Added");
      });
  };

  const handleRemoveCirculation = (index) => {
    Swal.fire({
      title: "Confirm Delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const entity = {
          _id: index,
          circulationStatus: -400,
          jobAppId: jobAppId ? jobAppId : _id,
        };
        dispatch(putCirculation(entity)).then(() => {
          if (param._id) {
            dispatch(getCirculationByJobAppId(param._id));
          }
          if (jobAppId) {
            dispatch(getCirculationByJobAppId(jobAppId));
          }
        });
      }
    });
  };

  return (
    <Row className="py-2">
      <Col md={12}>
        <Card>
          <Card.Header style={styles.TitleStyle}>
            Circulation Details
          </Card.Header>
          {status == 200 ? (
            <>
              <Row className="py-3 px-3">
                <Col md={"auto"}>
                  <Form.Label>Circulate to:</Form.Label>
                </Col>
                <Col md={10}>
                  <Select
                    name="supervisorDropdown"
                    value={supervisorDropdown || ""}
                    onChange={handleDropdownSupervisor}
                    options={supervisors}
                  />
                </Col>
                <Col md={"auto"}>
                  <Button
                    onClick={handleSubmitCirculation}
                    style={styles.ButtonStyle}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </Col>
              </Row>
              <Row className="py-3 px-3">
                <Table bordered responsive>
                  <thead>
                    <tr style={styles.TableHeader}>
                      <th>No</th>
                      <th>Date</th>
                      <th>Supervisor</th>
                      <th>Status</th>
                      <th style={styles.ThIcon}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {circulationList.map(
                      (
                        { svName, _id, circulationStatus, circulationDate },
                        i
                      ) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{circulationDate}</td>
                          <td>{svName}</td>
                          <td>
                            {circulationStatus == 400 && (
                              <span>Circulation Success</span>
                            )}
                            {circulationStatus == 500 && (
                              <span>Approved by Supervisor</span>
                            )}
                            {circulationStatus == 120 && (
                              <span>Rejected by Supervisor</span>
                            )}
                            {circulationStatus == -400 && <span>Deleted</span>}
                            {circulationStatus == 130 && (
                              <span>Interview Cancel</span>
                            )}
                          </td>
                          <td style={styles.TdIcon}>
                            {circulationStatus == -400 ? (
                              ""
                            ) : (
                              <Button
                                onClick={() => handleRemoveCirculation(_id)}
                                variant="outline-danger"
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </Row>
            </>
          ) : (
            <Form.Label className="mx-2 mb-2 mt-2">N/A</Form.Label>
          )}
        </Card>
      </Col>
    </Row>
  );
};
export default CirculationList;
