import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { styles } from "../HRFeedbackStyle";

import { getApplicationForm } from "../../../4.Store/ApplicationFormSlice";
import {
  updateSvId,
  putCirculation,
  getCirculationByJobAppId,
  getCirculationByCirculationId,
} from "../../../4.Store/CirculationSlice";
import {
  postAssessmentForm,
  putAssessmentForm,
  getAssessmentByJobAppId,
  postAssessmentToSvEmail,
  postInterviewCancelToInterviewerEmail,
} from "../../../4.Store/AssessmentSlice";
import UserService from "../../../5.Services/UserService";
import {
  postAssessmentPushNotification,
  postTestNotificationAssessment,
} from "../../../4.Store/NotificationSlice";

const SetInterviewer = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const [users, setUsers] = useState([]);
  const [loadingDropdown, setLoadingDropdown] = useState(true);
  const [userDropdown, setUserDropdown] = useState("");

  useEffect(() => {
    UserService.getUsers()
      .then((res) => {
        const arr = [];
        let result = res.data;
        result.map((user) => {
          return arr.push({
            value: user.id,
            label: user.lastName + " " + user.firstName,
            email: user?.email,
          });
        });
        setUsers(arr);
      })
      .finally(() => setLoadingDropdown(false));
  }, []);

  const { _id, sectionOne } = useSelector((state) => state.ApplicationForm);
  const { jobAppId, ivDate, ivTime, sectionHrDept, ivAMPM } = useSelector(
    (state) => state.Circulation
  );
  const {
    interviewerList,
    sectionA,
    sectionB,
    sectionC,
    sectionD,
    approval,
    submitInterviewerDate,
  } = useSelector((state) => state.Assessment);
  const {
    svId,
    circulationId,
    assessmentId,
    title,
    body,
    candidateName,
    dateSent,
    timeSent,
    pushToken,
    type,
    device,
    isRead,
  } = useSelector((state) => state.Notification);

  const handleDropdownUser = (value) => {
    setUserDropdown(value);
    dispatch(updateSvId(value));
  };

  const handleClickSubmitInterviewer = () => {
    if (userDropdown.value === undefined) {
      return Swal.fire({
        title: "Please Choose Interviewer",
      });
    }
    const entity = {
      jobAppId: _id,
      circulationId: param._id,
      interviewDate: ivDate,
      interviewTime: ivTime,
      interviewAmPm: ivAMPM,
      sectionA: {
        qualification: sectionHrDept?.qualification,
        qualification_suitability: sectionHrDept?.qualification_suitability,
        experience_relevance: sectionHrDept?.experience_relevance,
        working_years: sectionHrDept?.working_years,
        fresh_graduate_check: sectionHrDept?.fresh_graduate_check,
        professional_affiliate_check:
          sectionHrDept?.professional_affiliate_check,
        professional_affiliate_description:
          sectionHrDept?.professional_affiliate_description,
        remarks: sectionHrDept?.remarks,
      },
      sectionB,
      sectionC,
      sectionD,
      approval,
      interviewer: {
        interviewerId: userDropdown.value,
        interviewerName: userDropdown.label,
        interviewerEmail: userDropdown.email,
      },
      assessmentStatus: 600,
      submitInterviewerDate,
    };
    // console.log(entity);
    dispatch(postAssessmentForm(entity))
      .then((res) => {
        // console.log(res);
        dispatch(postAssessmentToSvEmail({ id: res.payload.insertedId }));
        // dispatch(postHrNotifyAfterSetInterviewerEmail({ id: res.payload.insertedId })); =>HR ask to remove
        // const entity = {
        //   jobAppId: _id,
        //   circulationId: param._id,
        //   assessmentId: res.payload.insertedId,
        //   svId: userDropdown.value,
        //   candidateName: sectionOne.name,
        //   dateSent,
        //   timeSent,
        //   isRead: 0,
        // };
        // // console.log(entity);
        // dispatch(postAssessmentPushNotification(entity));
        const entity = {
          jobAppId: _id,
          circulationId: param._id,
          assessmentId: res.payload.insertedId,
          svId: userDropdown.value,
          candidateName: sectionOne.name,
          dateSent,
          timeSent,
          isRead: 0,
        };
        dispatch(postTestNotificationAssessment(entity));
      })
      .then(() => {
        Swal.fire("Successfully Added");
      })
      .then(() => {
        const entity2 = {
          _id: param._id,
          jobAppId,
          sectionHrDept,
          isInterviewerSet: 1,
        };
        dispatch(putCirculation(entity2));
      })
      .then(() => {
        dispatch(getAssessmentByJobAppId(jobAppId)).then(() => {
          setUserDropdown("");
        });
      })
      .catch((err) => {
        Swal.fire("Error / Masalah", err.response.data, "error", "OK");
      });
  };

  const handleRemoveInterviewer = (index) => {
    Swal.fire({
      title: "Confirm Delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const entity = { _id: index, assessmentStatus: -600 };
        dispatch(putAssessmentForm(entity)).then(() => {
          dispatch(getAssessmentByJobAppId(jobAppId));
        });
      }
    });
  };

  const handleClickCancelIvOne = (index) => {
    Swal.fire({
      title: "Confirm Cancel Interview For This Interviewer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const entity1 = {
          _id: index,
          assessmentStatus: 130,
          jobAppId: jobAppId ? jobAppId : _id,
        };
        dispatch(putAssessmentForm(entity1));

        const entity2 = { id: index };
        dispatch(postInterviewCancelToInterviewerEmail(entity2)); //Email interviewer for iv cancel

        dispatch(getAssessmentByJobAppId(jobAppId)).then((res) => {
          dispatch(getCirculationByJobAppId(param._id));
          dispatch(getCirculationByCirculationId(param._id))
            .then((respond) => {
              dispatch(getApplicationForm(respond.payload.jobAppId));
              dispatch(getAssessmentByJobAppId(respond.payload.jobAppId));
              dispatch(getCirculationByJobAppId(respond.payload.jobAppId));
            })
            .then(() => {
              Swal.fire("Interview Cancel!", "", "success");
            })
            .catch((err) => {
              Swal.fire("Error / Masalah", err.response.data, "error", "OK");
            });
        });
      }
    });
  };

  return (
    <>
      <Row className="py-2">
        <Col md={"auto"}>
          <Form.Label>Set Interviewer:</Form.Label>
        </Col>
        <Col md={10}>
          {loadingDropdown ? (
            <p>Loading Users List For Dropdown...</p>
          ) : (
            <Select
              name="userDropdown"
              value={userDropdown || ""}
              onChange={handleDropdownUser}
              options={users}
            />
          )}
        </Col>
        <Col md={"auto"}>
          <Button
            onClick={handleClickSubmitInterviewer}
            style={styles.ButtonStyle}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>

      <Row className="py-2">
        <Table bordered responsive>
          <thead>
            <tr style={styles.TableHeader}>
              <th>No</th>
              <th>Interviewer</th>
              <th>Status</th>
              <th style={styles.ThIcon}>Action</th>
            </tr>
          </thead>
          <tbody>
            {interviewerList.map(
              ({ interviewer, _id, assessmentStatus }, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{interviewer?.interviewerName}</td>
                  <td>
                    {assessmentStatus == 130 && <span>Interview Cancel</span>}
                    {assessmentStatus == 600 && <span>Interview Confirm</span>}
                  </td>
                  <td className="text-center">
                    {assessmentStatus == 600 && (
                      <>
                        <Button
                          variant="outline-danger"
                          onClick={() => handleClickCancelIvOne(_id)}
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            title="Cancel Interview For This Interviewer"
                          />
                        </Button>
                        &nbsp;&nbsp;
                        <Button
                          variant="outline-danger"
                          onClick={() => handleRemoveInterviewer(_id)}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            title="Remove Interviewer"
                          />
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Row>
    </>
  );
};
export default SetInterviewer;
