import Select from "react-select";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";

import { styles } from "../AssessmentFormStyle";
import { InterviewRateList } from "../../../2.Common/DropdownOptions";

import { updateSectionC } from "../../../4.Store/AssessmentSlice";

const SectionC = () => {
  const dispatch = useDispatch();

  const [tickEnglish, setTickEnglish] = useState(true);
  const [tickBahasa, setTickBahasa] = useState(true);
  const [tickMandarin, setTickMandarin] = useState(true);
  const [tickEnglishWrite, setTickEnglishWrite] = useState(true);
  const [tickBahasaWrite, setTickBahasaWrite] = useState(true);
  const [tickMandarinWrite, setTickMandarinWrite] = useState(true);

  const { sectionC } = useSelector((state) => state.Assessment);
  const {
    language_spoken_english,
    language_writen_english,
    language_spoken_chinese,
    language_writen_chinese,
    language_spoken_bahasa,
    language_writen_bahasa,
    language_spoken_other,
    language_writen_other,
    writing_abilities,
  } = sectionC;

  const handleChangeDropdownWritingAbilities = (value) => {
    const entity = { ...sectionC, writing_abilities: value };
    dispatch(updateSectionC(entity));
    // console.log(entity);
  };

  const handleChangeFormSectionC = (e) => {
    const entity = { ...sectionC, [e.target.name]: e.target.value };
    dispatch(updateSectionC(entity));
    // console.log(entity);
  };

  const tickEnglishSpoken = () => {
    if (tickEnglish) {
      setTickEnglish(false);
      const entity = { ...sectionC, language_spoken_english: "yes" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    } else {
      setTickEnglish(true);
      const entity = { ...sectionC, language_spoken_english: "no" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    }
  };

  const tickBahasaSpoken = () => {
    if (tickBahasa) {
      setTickBahasa(false);
      const entity = { ...sectionC, language_spoken_bahasa: "yes" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    } else {
      setTickBahasa(true);
      const entity = { ...sectionC, language_spoken_bahasa: "no" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    }
  };

  const tickMandarinSpoken = () => {
    if (tickMandarin) {
      setTickMandarin(false);
      const entity = { ...sectionC, language_spoken_chinese: "yes" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    } else {
      setTickMandarin(true);
      const entity = { ...sectionC, language_spoken_chinese: "no" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    }
  };

  const tickEnglishWriten = () => {
    if (tickEnglishWrite) {
      setTickEnglishWrite(false);
      const entity = { ...sectionC, language_writen_english: "yes" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    } else {
      setTickEnglishWrite(true);
      const entity = { ...sectionC, language_writen_english: "no" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    }
  };

  const tickBahasaWriten = () => {
    if (tickBahasaWrite) {
      setTickBahasaWrite(false);
      const entity = { ...sectionC, language_writen_bahasa: "yes" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    } else {
      setTickBahasaWrite(true);
      const entity = { ...sectionC, language_writen_bahasa: "no" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    }
  };

  const tickMandarinWriten = () => {
    if (tickMandarinWrite) {
      setTickMandarinWrite(false);
      const entity = { ...sectionC, language_writen_chinese: "yes" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    } else {
      setTickMandarinWrite(true);
      const entity = { ...sectionC, language_writen_chinese: "no" };
      dispatch(updateSectionC(entity));
      // console.log(entity);
    }
  };

  return (
    <>
      <Form.Label style={styles.TitleStyle} className="mt-2">
        (C) Language and Proficiency (Please Tick Where Applicable)
      </Form.Label>

      <Row className="py-2">
        <Col lg={6} md={12} sm={12}>
          <Form.Label>
            Writing Abilities (Based On Short Essay Before Interview):
          </Form.Label>
          <Select
            name="writing_abilities"
            options={InterviewRateList}
            value={writing_abilities || ""}
            onChange={handleChangeDropdownWritingAbilities}
          />
        </Col>
      </Row>

      <Row className="py-2">
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Language Abilities Spoken:</Form.Label>
          <div className="mb-2">
            <Form.Check
              inline
              type="checkbox"
              label="English"
              value={language_spoken_english}
              name="language_spoken_english"
              onChange={tickEnglishSpoken}
              checked={language_spoken_english == "yes"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Chinese"
              value={language_spoken_chinese}
              name="language_spoken_chinese"
              onChange={tickMandarinSpoken}
              checked={language_spoken_chinese == "yes"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="BM"
              value={language_spoken_bahasa}
              name="language_spoken_bahasa"
              onChange={tickBahasaSpoken}
              checked={language_spoken_bahasa == "yes"}
            />
          </div>
          <Form.Control
            name="language_spoken_other"
            placeholder="Enter Other Language Spoken If Any"
            value={language_spoken_other}
            onChange={handleChangeFormSectionC}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Language Abilities Writen:</Form.Label>
          <div className="mb-2">
            <Form.Check
              inline
              type="checkbox"
              label="English"
              value={language_writen_english}
              name="language_writen_english"
              onChange={tickEnglishWriten}
              checked={language_writen_english == "yes"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="Chinese"
              value={language_writen_chinese}
              name="language_writen_chinese"
              onChange={tickMandarinWriten}
              checked={language_writen_chinese == "yes"}
            />
            <Form.Check
              inline
              type="checkbox"
              label="BM"
              value={language_writen_bahasa}
              name="language_writen_bahasa"
              onChange={tickBahasaWriten}
              checked={language_writen_bahasa == "yes"}
            />
          </div>
          <Form.Control
            name="language_writen_other"
            placeholder="Enter Other Language Written If Any"
            value={language_writen_other || ""}
            onChange={handleChangeFormSectionC}
          />
        </Col>
      </Row>
    </>
  );
};
export default SectionC;
