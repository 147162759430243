import { Card, Container, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

import NavbarMenu from "../2.Common/NavbarMenu";
import SVApplicationList from "../1.Pages/SVTableList/SVApplicationList";
import SVAssessmentList from "../1.Pages/SVTableList/SVAssessmentList";

import { updateCurrentSVTab } from "../4.Store/ApplicationFormSlice";

const SVTableListSite = () => {
  const dispatch = useDispatch();

  const { currentSvTab } = useSelector((state) => state.ApplicationForm);

  const accessToken = localStorage.getItem("access_token");
  const token = jwt_decode(accessToken);

  const isSV = token.realm_access?.roles?.includes("jobapp_sv");

  return (
    <>
      {isSV && (
        <>
          <NavbarMenu />
          <Container fluid>
            <Card className="mt-3 mb-3">
              <Tabs
                defaultActiveKey={currentSvTab}
                id="justify-tab"
                className="mb-3 tabs"
                justify
                onSelect={(k) => dispatch(updateCurrentSVTab(k))}
              >
                <Tab eventKey="0" title="Application List">
                  <SVApplicationList />
                </Tab>
                <Tab eventKey="1" title="Assessment List">
                  <SVAssessmentList />
                </Tab>
              </Tabs>
            </Card>
          </Container>
        </>
      )}
    </>
  );
};
export default SVTableListSite;
