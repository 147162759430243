import Select from "react-select";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Container, Form, Row, Col } from "react-bootstrap";

import { styles } from "./ApplicationFormStyle.js";
import {
  FormLabelSelectFull,
  FormLabelControlTextFull,
  FormLabelControlSalary,
  FormLabelControlText,
  FormLabelSelect,
  FormLabelControlAppendRight,
  FormLabelControlTextNoIcon,
  FormLabelControlTextFullNoIcon,
} from "../../2.Common/RequiredForm.js";
import {
  GenderList,
  HighestQualificationList,
  CurrentLocationList,
  YesNoList,
  AdvertisementSourceList,
  ReligionList,
  RaceList,
  DialectList,
} from "../../2.Common/DropdownOptions.js";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FormLabelControlTextNRNoIcon } from "../../2.Common/NotRequiredForm.js";
import UploadPhoto from "../../2.Common/UploadPhoto.js";
import UploadCoverLetter from "../../2.Common/UploadCoverLetter.js";
import UploadResume from "../../2.Common/UploadResume.js";
import UploadCertificate from "../../2.Common/UploadCertificate.js";
import PDFViewer from "../../2.Common/PDFViewer.jsx";

import {
  updateSectionOne,
  getVacancy,
} from "../../4.Store/ApplicationFormSlice.js";

const SectionOneFull = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [vacancies, setVacancies] = useState([]);
  const [viewPdf, setViewPdf] = useState(undefined);

  const { sectionOne } = useSelector((state) => state.ApplicationForm);
  const {
    image,
    position_applied,
    internship,
    name,
    identification_certification,
    gender,
    date_of_birth,
    email,
    age,
    contact_number,
    place_of_birth,
    height,
    weight,
    religion,
    race,
    dialect,
    address,
    health,
    epf_number,
    income_tax_number,
    highest_qualification,
    expected_salary,
    current_location,
    current_location_other,
    fresh_graduate,
    fresh_graduate_no,
    relocate,
    ads_source,
    ads_source_other,
    cover_letter,
    resume,
    certificate,
    acknowledgement1,
  } = sectionOne;

  useEffect(() => {
    // Put vacancy list into array
    dispatch(getVacancy()).then((res) => {
      const arr = [];
      let result = res.payload;

      result.forEach((vacancy) => {
        arr.push({
          label: vacancy.vacancy,
          value: vacancy._id,
        });
      });
      setVacancies(
        arr.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
      );
    });
  }, [location.search]);

  useEffect(() => {
    var dob = new Date(date_of_birth);
    var cur = new Date();
    var diff = cur - dob; // This is the difference in milliseconds
    var age2 = Math.floor(diff / 31557600000); // Divide by 1000*60*60*24*365.25
    if (age2 < 1 || age2.isNan) {
      age2 = 0;
    }
    var entity = { ...sectionOne, age: age2 };
    dispatch(updateSectionOne(entity));
  }, [date_of_birth]);

  const handlePdfViewer = () => {
    setViewPdf(undefined);
  };

  const handleChangeDropdown = (name, value) => {
    const entity = { ...sectionOne, [name]: value };
    switch (name) {
      case "position_applied":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "gender":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "religion":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "race":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "highest_qualification":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "current_location":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "fresh_graduate":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "relocate":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
      case "ads_source":
        dispatch(updateSectionOne(entity));
        // console.log(entity);
        break;
    }
  };

  const handleChangeForm = (e) => {
    const entity = {
      ...sectionOne,
      [e.target.name]: e.target.value.toUpperCase(),
    };
    dispatch(updateSectionOne(entity));
    // console.log(entity);
  };

  const handleChangeForm2 = (e) => {
    const entity = {
      ...sectionOne,
      [e.target.name]: e.target.value,
    };
    dispatch(updateSectionOne(entity));
    // console.log(entity);
  };

  const FilterDialect = DialectList.filter((v) => {
    if (race !== undefined) {
      return v.branch === race.value;
    } else {
      return false;
    }
  });

  const handleChangeDropdownDialect = (value) => {
    const entity = { ...sectionOne, dialect: value };
    dispatch(updateSectionOne(entity));
    // console.log(entity);
  };

  const handleChangeSalary = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue)) {
      const entity = {
        ...sectionOne,
        expected_salary: newValue,
      };
      dispatch(updateSectionOne(entity));
      // console.log(entity);
    }
  };

  return (
    <>
      {viewPdf && <PDFViewer onClose={handlePdfViewer} fileObject={viewPdf} />}
      {viewPdf === undefined && (
        <Container fluid>
          <Form>
            <UploadPhoto />
            <FormLabelSelectFull
              label="Position Applied"
              name="position_applied"
              value={position_applied}
              options={vacancies}
              handleChange={handleChangeDropdown}
            />
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlText
                  label="Full Name"
                  name="name"
                  value={name}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter Here"
                  icon={faCircleQuestion}
                  tooltip="Fill in full name as per NRIC / Passport"
                  tooltipId="fullname-tooltip"
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlText
                  label="NRIC / Passport No."
                  name="identification_certification"
                  value={identification_certification}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter Here"
                  icon={faCircleQuestion}
                  tooltip="Fill in without dash (-)"
                  tooltipId="nric-tooltip"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Gender"
                  name="gender"
                  value={gender}
                  options={GenderList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlTextNoIcon
                  label="Email"
                  name="email"
                  value={email}
                  type="email"
                  handleChangeForm={handleChangeForm2}
                  placeholder="name@example.com"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlTextNoIcon
                  label="Date Of Birth"
                  name="date_of_birth"
                  value={date_of_birth}
                  handleChangeForm={handleChangeForm2}
                  type="date"
                />
              </Col>
              <Col md={6} sm={12}>
                <Form.Group as={Row} className="px-2 py-3">
                  <Form.Label
                    column
                    sm="4"
                    style={{ fontSize: "15px", textAlign: "left" }}
                  >
                    Age&nbsp;<span className="star">*</span>
                    &nbsp;&nbsp;
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control name="age" value={age || 0} readOnly />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlText
                  label="Contact Number"
                  name="contact_number"
                  value={contact_number}
                  handleChangeForm={handleChangeForm}
                  type="number"
                  placeholder="Enter Here"
                  icon={faCircleQuestion}
                  tooltip="Fill in without dash (-)"
                  tooltipId="contactnum-tooltip"
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlTextNoIcon
                  label="Place Of Birth"
                  name="place_of_birth"
                  value={place_of_birth}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter here"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlAppendRight
                  label="Height"
                  name="height"
                  value={height}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter here"
                  appendLabel="CM"
                  type="number"
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlAppendRight
                  label="Weight"
                  name="weight"
                  value={weight}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter here"
                  appendLabel="KG"
                  type="number"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Religion"
                  name="religion"
                  value={religion}
                  options={ReligionList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Race"
                  name="race"
                  value={race}
                  options={RaceList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <Form.Group
                  as={Row}
                  className="px-2 py-3"
                  style={{ textAlign: "left" }}
                >
                  <Form.Label column sm="4" style={{ fontSize: "15px" }}>
                    Dialect&nbsp;<span className="star">*</span>
                  </Form.Label>
                  <Col sm="8" className="SelectOption">
                    <Select
                      name="dialect"
                      value={dialect || ""}
                      options={FilterDialect}
                      onChange={handleChangeDropdownDialect}
                      placeholder="Please Select"
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <FormLabelControlTextFullNoIcon
              label="Address"
              name="address"
              value={address}
              handleChangeForm={handleChangeForm}
              placeholder="Enter here"
            />
            <FormLabelControlTextFull
              label="Health"
              name="health"
              value={health}
              handleChangeForm={handleChangeForm}
              placeholder="Enter here"
              icon={faCircleQuestion}
              tooltip="State any mental or serious physical illness, disability or handicap and period suffered. State any regular treatment medical taken. Enter NONE if none."
              tooltipId="health-tooltip"
            />
            <Row>
              <Col md={6} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="EPF"
                  name="epf_number"
                  value={epf_number}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter here"
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlTextNRNoIcon
                  label="Income Tax"
                  name="income_tax_number"
                  value={income_tax_number}
                  handleChangeForm={handleChangeForm}
                  placeholder="Enter here"
                />
              </Col>
            </Row>

            <h5 className="px-2" style={styles.TextHeader5}>
              (A) Other Info
            </h5>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Highest Qualification"
                  name="highest_qualification"
                  value={highest_qualification}
                  options={HighestQualificationList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelControlSalary
                  label="Expected Salary"
                  name="expected_salary"
                  value={expected_salary}
                  handleChangeForm={handleChangeSalary}
                  placeholder="Enter here"
                  inputText="RM"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Current Location"
                  name="current_location"
                  value={current_location}
                  options={CurrentLocationList}
                  handleChange={handleChangeDropdown}
                />
                {current_location?.value === "Others" && (
                  <FormLabelControlTextNoIcon
                    label="Please State Location"
                    name="current_location_other"
                    value={current_location_other}
                    handleChangeForm={handleChangeForm}
                  />
                )}
              </Col>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Are You Fresh Graduate?"
                  name="fresh_graduate"
                  value={fresh_graduate}
                  options={YesNoList}
                  handleChange={handleChangeDropdown}
                />
                {fresh_graduate?.value === "No" && (
                  <FormLabelControlAppendRight
                    label="Please State Year(s) of Experience"
                    name="fresh_graduate_no"
                    value={fresh_graduate_no}
                    handleChangeForm={handleChangeForm}
                    appendLabel="Year(s)"
                    type="number"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="Willing To Relocate Outstation Within Sarawak?"
                  name="relocate"
                  value={relocate}
                  options={YesNoList}
                  handleChange={handleChangeDropdown}
                />
              </Col>
              <Col md={6} sm={12}>
                <FormLabelSelect
                  label="How Did You Find Out About The Position?"
                  name="ads_source"
                  value={ads_source}
                  options={AdvertisementSourceList}
                  handleChange={handleChangeDropdown}
                />
                {ads_source?.value === "Other" && (
                  <FormLabelControlTextNoIcon
                    label="Please State Other"
                    name="ads_source_other"
                    value={ads_source_other}
                    handleChangeForm={handleChangeForm}
                  />
                )}
                {ads_source?.value === "Staff Referral" && (
                  <FormLabelControlTextNoIcon
                    label="Please State Staff Name:"
                    name="ads_source_other"
                    value={ads_source_other}
                    handleChangeForm={handleChangeForm}
                  />
                )}
              </Col>
            </Row>

            <h5 className="px-2" style={styles.TextHeader5}>
              (B) Upload Files
            </h5>
            <UploadCoverLetter />
            <UploadResume />
            <UploadCertificate />
          </Form>
        </Container>
      )}
    </>
  );
};
export default SectionOneFull;
