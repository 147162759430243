import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form } from "react-bootstrap";

import {
  updateSuperiorName,
  updateAssignDeptProject,
} from "../../../4.Store/CirculationSlice";

const SVInternshipCreate = () => {
  const dispatch = useDispatch();

  const { superiorName, assignDeptProject } = useSelector(
    (state) => state.Circulation
  );

  const handleChangeSuperiorName = (e) => {
    const entity = { superiorName: e.target.value };
    // console.log(entity);
    dispatch(updateSuperiorName(entity));
  };

  const handleChangeDepartmentProject = (e) => {
    const entity = { assignDeptProject: e.target.value };
    // console.log(entity);
    dispatch(updateAssignDeptProject(entity));
  };

  return (
    <>
      <Row className="my-2 mx-3">
        <Col md={12} sm={12}>
          <Form.Label>
            Superior Name: <span className="star">*</span>
          </Form.Label>
          <Form.Control
            name="superiorName"
            value={superiorName}
            onChange={handleChangeSuperiorName}
            placeholder="Example: Yong Sern Piat"
          />
        </Col>
      </Row>
      <Row className="my-3 mx-3">
        <Col md={12} sm={12}>
          <Form.Label>
            Assign Department or Project: <span className="star">*</span>
          </Form.Label>
          <Form.Control
            name="assignDeptProject"
            value={assignDeptProject}
            onChange={handleChangeDepartmentProject}
            placeholder="Example: IT Department"
          />
        </Col>
      </Row>
    </>
  );
};
export default SVInternshipCreate;
