import React from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Forbidden from "../3.Sites/ForbiddenSite";
import Login from "../1.Pages/Login/Login";

const realm = "hsltower";
const client_id = "jobapp";
const REFRESH_RATE = 3;
let access_token = "";
let parsed_token = {};
let parsed_decoded_header = {};

const doLogin = (username, password) => {
  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("client_id", client_id);
  params.append("username", username);
  params.append("password", password);
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://auth2.hslnet.my/auth/realms/" +
          realm +
          "/protocol/openid-connect/token",
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        // console.log(res);
        parsed_decoded_header = jwt_decode(res.data?.access_token, {
          header: true,
        });
        access_token = res.data?.access_token;
        const parsed_token = jwt_decode(access_token);

        localStorage.setItem("parsed_token", access_token);
        localStorage.setItem("access_token", res.data?.access_token);
        localStorage.setItem("refresh_token", res.data?.refresh_token);
        localStorage.setItem("sv_id", parsed_token?.sub);
        // console.log(parsed_token?.sub);

        resolve();
      })
      .catch((e) => {
        // console.log(e);
        localStorage.setItem("parsed_token", "");
        localStorage.setItem("access_token", "");
        localStorage.setItem("refresh_token", "");
        reject(e);
      });
  });
};

const doLogout = () => {
  localStorage.setItem("refresh_token", "");
  localStorage.setItem("access_token", "");
  localStorage.setItem("parsed_token", "");
  localStorage.setItem("sv_id", "");
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const init = (onAuthenticatedCallback) => {
  onAuthenticatedCallback && onAuthenticatedCallback();
  refreshAccessToken();
  window.setInterval(() => {
    refreshAccessToken();
  }, REFRESH_RATE * 1000);
};
const refreshAccessToken = () => {
  const params = new URLSearchParams();
  let refresh_token = localStorage.getItem("refresh_token");
  if (refresh_token === "") {
    // console.log("Not Login");
    return;
  }
  if (refresh_token === undefined) {
    // console.log("Not Login");
    return;
  }
  params.append("grant_type", "refresh_token");
  params.append("client_id", client_id);
  params.append("refresh_token", refresh_token);

  axios
    .post(
      "https://auth2.hslnet.my/auth/realms/" +
        realm +
        "/protocol/openid-connect/token",
      params,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((res) => {
      // console.log("Token Refresh Successful");
      access_token = res.data?.access_token;
      parsed_decoded_header = jwt_decode(res.data?.access_token, {
        header: true,
      });
      parsed_token = jwt_decode(access_token);

      // console.log(parsed_token);
      // console.log(parsed_token?.exp);
      // console.log(moment(parsed_token?.exp * 1000).format("h:mm:ss"));
      localStorage.setItem("access_token", res.data?.access_token);

      localStorage.setItem("refresh_token", res.data?.refresh_token);
    })
    .catch((e) => {
      localStorage.setItem("access_token", "");
      localStorage.setItem("refresh_token", "");
    });
};
const getUsername = () => {
  access_token = localStorage.getItem("access_token");
  try {
    parsed_token = jwt_decode(access_token);
    // console.log(parsed_token?.preferred_username);
    return parsed_token?.preferred_username;
  } catch {
    return undefined;
  }
};

const getName = () => {
  try {
    access_token = localStorage.getItem("access_token");
    parsed_token = jwt_decode(access_token);
    return parsed_token?.given_name;
  } catch {
    return undefined;
  }
};

const getRoles = () => {
  access_token = localStorage.getItem("access_token");
  try {
    parsed_token = jwt_decode(access_token);
    return parsed_token?.realm_access.roles;
  } catch {
    return undefined;
  }
};

const getUsers = () => {
  const params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("client_id", client_id);
  params.append("username", "jobapp_access");
  params.append("password", "dj2U9Ecqk2f77jmKrzp7");
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://auth2.hslnet.my/auth/realms/" +
          realm +
          "/protocol/openid-connect/token",
        params,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        // console.log(res);
        parsed_decoded_header = jwt_decode(res.data?.access_token, {
          header: true,
        });
        access_token = res.data?.access_token;

        axios
          .get(
            "https://auth2.hslnet.my/auth/admin/realms/hsltower/users?max=5000",
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            // console.log(e);
            reject(e);
          });
      })
      .catch((e) => {
        // console.log(e);
        reject(e);
      });
  });
};

const hasRole = (roles) => {
  return true;
};

const getSID = () => {
  access_token = localStorage.getItem("access_token");
  parsed_token = jwt_decode(access_token);
  return parsed_token?.sid;
};

const getEmail = () => {
  access_token = localStorage.getItem("access_token");
  parsed_token = jwt_decode(access_token);
  return parsed_token?.email;
};

export const RenderOnRole = (props) => {
  return hasRole(props?.roles) ? props.children : <></>;
};

export const ProtectedElement = (props) => {
  return hasRole(props?.roles) ? props.children : <></>;
};

// export const ProtectedRoute = (props) => {
//   return hasRole(props?.roles) ? props.children : <Forbidden />;
// };

export const ProtectedRoute = (props) => {
  let refresh_token = localStorage.getItem("refresh_token");

  return refresh_token ? (
    hasRole(props?.roles) ? (
      props.children
    ) : null
  ) : (
    <Forbidden />
  );
};

export const LoginProtectedRoute = (props) => {
  let refresh_token = localStorage.getItem("refresh_token");
  return refresh_token ? (
    hasRole(props?.roles) ? (
      props.children
    ) : null
  ) : (
    <Login />
  );
};

export const getToken = () => {
  return access_token;
};

const UserService = {
  init,
  doLogin,
  doLogout,
  getUsername,
  getName,
  hasRole,
  getSID,
  getEmail,
  ProtectedElement,
  ProtectedRoute,
  getToken,
  getUsers,
  getRoles,
  LoginProtectedRoute,
};

export default UserService;
