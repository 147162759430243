import { useSelector } from "react-redux";
import { Col, Container, Form, Row, Card } from "react-bootstrap";

import { styles } from "./HRFeedbackStyle";
import CandidateStatus from "./Components/CandidateStatus";
import HRSVRemark from "./Components/HRSVRemark";
import CirculationList from "./Components/CirculationList";
import InterviewDetail from "./Components/InterviewDetail";
import InternshipDetail from "./Components/InternshipDetail";
import HRDept from "./Components/HRDept";
import SetInterviewer from "./Components/SetInterviewer";
import ActionButtons from "./Components/ActionButtons";

const HRFeedback = () => {
  const { sectionOne } = useSelector((state) => state.ApplicationForm);
  const { circulationStatus, superiorName } = useSelector(
    (state) => state.Circulation
  );

  return (
    <Container fluid>
      {/* Candidate Status */}
      <CandidateStatus />

      {/* HR and SV Remark */}
      <HRSVRemark />

      {/* Circulation List */}
      <CirculationList />

      {/* IV / Internship Details */}
      {sectionOne?.internship == "yes" && superiorName && <InternshipDetail />}
      {circulationStatus == 500 && sectionOne?.internship !== "yes" ||
      (circulationStatus == 600 && sectionOne?.internship !== "yes") ? (
        <InterviewDetail />
      ) : (
        <></>
      )}

      {/* Interviewer List */}
      {sectionOne?.internship !== "yes" && (
        <Row className="py-2">
          <Col md={12}>
            <Card>
              <Card.Header style={styles.TitleStyle}>
                Set HR Dept (Section A) and Manage Interviewer
              </Card.Header>
              <Card.Body>
                {circulationStatus == 500 ? (
                  <>
                    <HRDept />
                    <SetInterviewer />
                  </>
                ) : (
                  <Form.Label className="mx-2 mb-2 mt-2">N/A</Form.Label>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* Action Btn */}
      <ActionButtons />
    </Container>
  );
};
export default HRFeedback;
