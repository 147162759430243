import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";

import { styles } from "../AssessmentFormStyle";
import SignatureInterviewer from "../../../2.Common/SignatureInterviewer";

import {
  updateSectionD,
  updateInterviewer,
} from "../../../4.Store/AssessmentSlice";
import {
  updateSectionFour,
  updateSectionOne,
} from "../../../4.Store/ApplicationFormSlice";

const SectionD = () => {
  const dispatch = useDispatch();

  const { sectionD, interviewer, interviewDate } = useSelector(
    (state) => state.Assessment
  );
  const {
    other_comment,
    suggested_placement,
    require_notice,
    recommend_offer,
    proposed_first_day,
    other_term,
    remark,
  } = sectionD;
  const { interviewerName } = interviewer;
  const { sectionOne, sectionFour } = useSelector(
    (state) => state.ApplicationForm
  );
  const { expected_salary } = sectionOne;
  const { currentSalary } = sectionFour;

  const accessToken = localStorage.getItem("access_token");
  const token = jwt_decode(accessToken);
  const isHR = token?.realm_access?.roles?.includes("jobapp_hr");

  const handleChangeFormSectionD = (e) => {
    const entity = { ...sectionD, [e.target.name]: e.target.value };
    dispatch(updateSectionD(entity));
    // console.log(entity);
  };

  const handleChangeCurrentSalary = (e) => {
    const entity = { ...sectionFour, currentSalary: e.target.value };
    dispatch(updateSectionFour(entity));
    // console.log(entity);
  };

  const handleChangeExpectedSalary = (e) => {
    const entity = { ...sectionOne, expected_salary: e.target.value };
    dispatch(updateSectionOne(entity));
    // console.log(entity);
  };

  const handleChangeFormInterviewer = (e) => {
    const entity = { ...interviewer, [e.target.name]: e.target.value };
    dispatch(updateInterviewer(entity));
    // console.log(entity);
  };

  return (
    <>
      <Form.Label style={styles.TitleStyle} className="mt-2">
        (D) Other Comments And Observation
      </Form.Label>

      <Row className="py-2">
        <Col lg={12} md={12} sm={12}>
          <Form.Control
            name="other_comment"
            as="textarea"
            rows="3"
            value={other_comment || ""}
            onChange={handleChangeFormSectionD}
            placeholder="Enter Comments and Observations"
          />
        </Col>
      </Row>

      <Row className="py-2">
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Current Salary</Form.Label>
          <Form.Control
            name="currentSalary"
            value={currentSalary || ""}
            onChange={handleChangeCurrentSalary}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          {isHR ? (
            <>
              <Form.Label>Recommended Offer</Form.Label>
              <Form.Control
                name="recommend_offer"
                value={recommend_offer || ""}
                onChange={handleChangeFormSectionD}
                className="form-control"
              />
            </>
          ) : (
            <>
              <Form.Label>
                Recommended Offer&nbsp;<span className="star">*</span>
              </Form.Label>
              <Form.Control
                name="recommend_offer"
                value={recommend_offer || ""}
                onChange={handleChangeFormSectionD}
                required
                className="form-control"
              />
            </>
          )}
        </Col>
      </Row>

      <Row className="py-2">
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Expected Salary</Form.Label>
          <Form.Control
            name="expected_salary"
            value={expected_salary || ""}
            onChange={handleChangeExpectedSalary}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Any Other Term </Form.Label>
          <Form.Control
            name="other_term"
            value={other_term || ""}
            onChange={handleChangeFormSectionD}
          />
        </Col>
      </Row>

      <Row className="py-2">
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Required Notice From Current Job</Form.Label>
          <Form.Control
            name="require_notice"
            value={require_notice || ""}
            onChange={handleChangeFormSectionD}
          />
        </Col>
        <Col lg={6} md={6} sm={12}>
          {isHR ? (
            <>
              <Form.Label>Proposed / Agreed First Day</Form.Label>
              <Form.Control
                name="proposed_first_day"
                value={proposed_first_day || ""}
                onChange={handleChangeFormSectionD}
                type="date"
                className="form-control"
              />
            </>
          ) : (
            <>
              <Form.Label>
                Proposed / Agreed First Day&nbsp;
                <span className="star">*</span>
              </Form.Label>
              <Form.Control
                name="proposed_first_day"
                value={proposed_first_day || ""}
                onChange={handleChangeFormSectionD}
                type="date"
                required
                className="form-control"
              />
            </>
          )}
        </Col>
      </Row>

      <Row className="py-2">
        <Col lg={6} md={6} sm={12}>
          {isHR ? (
            <>
              <Form.Label>Suggested Placement</Form.Label>
              <Form.Control
                name="suggested_placement"
                value={suggested_placement || ""}
                onChange={handleChangeFormSectionD}
                className="form-control"
              />
            </>
          ) : (
            <>
              <Form.Label>
                Suggested Placement&nbsp;<span className="star">*</span>
              </Form.Label>
              <Form.Control
                name="suggested_placement"
                value={suggested_placement || ""}
                onChange={handleChangeFormSectionD}
                required
                className="form-control"
              />
            </>
          )}
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Label>Any Other Remarks</Form.Label>
          <Form.Control
            name="remark"
            value={remark || ""}
            onChange={handleChangeFormSectionD}
            as="textarea"
            rows="3"
          />
        </Col>
      </Row>

      <Row className="justify-content-md-center justify-content-sm-center mt-3 mb-3">
        <Col md="auto" sm="auto" className="text-center">
          <Form.Label>Interviewer's Signature:</Form.Label>
          <SignatureInterviewer />
        </Col>
      </Row>

      <Row className="justify-content-md-center justify-content-sm-center mt-3 mb-3">
        <Col md="auto" sm="auto" className="text-center">
          <Form.Label>Sign By:</Form.Label>
          <Form.Control
            name="interviewerName"
            value={interviewerName || ""}
            onChange={handleChangeFormInterviewer}
            style={{ textAlign: "center" }}
          />
        </Col>
        <Col md="auto" sm="auto" className="text-center">
          <Form.Label>Date:</Form.Label>
          <Form.Control
            name="interviewDate"
            value={interviewDate || ""}
            type="interviewDate"
            readOnly
            style={{ textAlign: "center" }}
          />
        </Col>
      </Row>
    </>
  );
};
export default SectionD;
