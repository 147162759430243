import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form, Table, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import { styles } from "./ApplicationFormStyle";

import { updateSectionFive } from "../../4.Store/ApplicationFormSlice";
import UserService from "../../5.Services/UserService";

const SectionFive = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const whatrole = UserService.getRoles();
    setRole(whatrole);
  }, []);

  const [role, setRole] = useState();
  const [refName, setRefName] = useState("");
  const [refAddress, setRefAddress] = useState("");
  const [refContactNumber, setRefContactNumber] = useState("");
  const [refOccupation, setRefOccupation] = useState("");
  const [refRelationship, setRefRelationship] = useState("");
  const [refYearsKnown, setRefYearKnown] = useState("");

  const { sectionFive } = useSelector((state) => state.ApplicationForm);
  const { references } = sectionFive;

  const handleClickSaveReference = () => {
    const emptyFields = [];

    if (!refName) {
      emptyFields.push("Name");
    }

    if (!refAddress) {
      emptyFields.push("Address");
    }

    if (!refContactNumber) {
      emptyFields.push("Contact Number");
    }

    if (!refOccupation) {
      emptyFields.push("Occupation");
    }

    if (!refRelationship) {
      emptyFields.push("Relationship");
    }

    if (!refYearsKnown) {
      emptyFields.push("Year(s) Known");
    }

    if (emptyFields.length > 0) {
      Swal.fire(`The Following Fields Are Empty: ${emptyFields.join(", ")}`);
    } else {
      const arr1 = { ...sectionFive };
      let arr2 = [...arr1.references];
      arr2.push({
        reference_name: refName.toUpperCase(),
        reference_address: refAddress.toUpperCase(),
        reference_contactNumber: refContactNumber.replace(/-/g, ""),
        reference_occupation: refOccupation.toUpperCase(),
        reference_relationship: refRelationship.toUpperCase(),
        reference_yearsKnown: refYearsKnown,
      });

      const entity = { ...sectionFive, references: arr2 };
      dispatch(updateSectionFive(entity));
      // console.log(entity);

      setRefName("");
      setRefAddress("");
      setRefContactNumber("");
      setRefOccupation("");
      setRefRelationship("");
      setRefYearKnown("");
    }
  };

  const handleClickRemoveReference = (index) => {
    Swal.fire({
      title: "Confirm Remove Reference?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const arr = { ...sectionFive };
        const arr2 = arr.references.filter((e, i) => i !== index);

        const entity = { ...sectionFive, references: arr2 };
        dispatch(updateSectionFive(entity));
        // console.log(entity);
      }
    });
  };

  return (
    <Container fluid>
      <Form>
        <h5 className="px-2" style={styles.TextHeader5}>
          (A) Reference
        </h5>
        <h6 className="px-2" style={styles.TextHeader6A}>
          • Fill In At Least&nbsp;<span className="star">ONE</span>
          &nbsp;Reference&nbsp;
          <span className="star">*</span>
        </h6>

        <Table>
          <thead>
            <tr>
              <td>No</td>
              <td>Name</td>
              <td>Address</td>
              <td>Contact No.</td>
              <td>Occupation</td>
              <td>Relationship</td>
              <td>Year Known</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-column="No:">#</td>
              <td data-column="Name:">
                <Form.Control
                  name="reference_name"
                  value={refName}
                  onChange={(e) => setRefName(e.target.value)}
                />
              </td>
              <td data-column="Address:">
                <Form.Control
                  name="reference_address"
                  value={refAddress}
                  onChange={(e) => setRefAddress(e.target.value)}
                />
              </td>
              <td data-column="Contact Number:">
                <Form.Control
                  name="reference_contactNumber"
                  value={refContactNumber}
                  onChange={(e) => setRefContactNumber(e.target.value)}
                />
              </td>
              <td data-column="Occupation:">
                <Form.Control
                  name="reference_occupation"
                  value={refOccupation}
                  onChange={(e) => setRefOccupation(e.target.value)}
                />
              </td>
              <td data-column="Relationship:">
                <Form.Control
                  name="reference_relationship"
                  value={refRelationship}
                  onChange={(e) => setRefRelationship(e.target.value)}
                />
              </td>
              <td data-column="Year(s) Known:">
                <Form.Control
                  name="reference_yearsKnown"
                  value={refYearsKnown}
                  onChange={(e) => setRefYearKnown(e.target.value)}
                />
              </td>
              <td data-column="Action:">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleClickSaveReference}
                  style={{ fontWeight: "bold" }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  &nbsp;Save Reference
                </Button>
              </td>
            </tr>
            {references?.map(
              (
                {
                  reference_name,
                  reference_address,
                  reference_contactNumber,
                  reference_occupation,
                  reference_relationship,
                  reference_yearsKnown,
                },
                i
              ) => (
                <tr key={i}>
                  <td data-column="No:">{i + 1}</td>
                  <td data-column="Name:">{reference_name}</td>
                  <td data-column="Address:">{reference_address}</td>
                  <td data-column="Contact Number:">
                    {reference_contactNumber}
                  </td>
                  <td data-column="Occupation:">{reference_occupation}</td>
                  <td data-column="Relationship:">{reference_relationship}</td>
                  <td data-column="Year(s) Known:">{reference_yearsKnown}</td>
                  <td data-column="Action:">
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleClickRemoveReference(i)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Form>
    </Container>
  );
};
export default SectionFive;
