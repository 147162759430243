import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiServices } from "../5.Services/apiServices";
import moment from "moment";

const namespace = "Notification";

// export const postCirculationPushNotification = createAsyncThunk(
//   `${namespace}/postCirculationPushNotification`,
//   async (body, { rejectWithValue }) => {
//     const api = "CreateNotificationCirculation";
//     const replace = {
//       search: "{circulation_id}",
//       replace: body.circulationId,
//       search2: "{sv_id}",
//       replace2: body.svId,
//     };
//     const opt = {
//       replace: replace,
//       body: body,
//     };
//     try {
//       const response = await apiServices(api, opt);
//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err);
//     }
//   }
// );

// export const postAssessmentPushNotification = createAsyncThunk(
//   `${namespace}/postAssessmentPushNotification`,
//   async (body, { rejectWithValue }) => {
//     const api = "CreateNotificationAssessment";
//     const replace = {
//       search: "{assessment_id}",
//       replace: body.assessmentId,
//       search2: "{interviewer_id}",
//       replace2: body.svId,
//     };
//     const opt = {
//       replace: replace,
//       body: body,
//     };
//     try {
//       const response = await apiServices(api, opt);
//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err);
//     }
//   }
// );

export const postTestNotification = createAsyncThunk(
  `${namespace}/postTestNotification`,
  async (body, { rejectWithValue }) => {
    const api = "TestNotification";
    const replace = {
      search: "{circulation_id}",
      replace: body.circulationId,
      search2: "{sv_id}",
      replace2: body.svId,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const postTestNotificationAssessment = createAsyncThunk(
  `${namespace}/postTestNotification`,
  async (body, { rejectWithValue }) => {
    const api = "TestNotificationAssessment";
    const replace = {
      search: "{assessment_id}",
      replace: body.assessmentId,
      search2: "{interviewer_id}",
      replace2: body.svId,
    };
    const opt = {
      replace: replace,
      body: body,
    };
    try {
      const response = await apiServices(api, opt);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  loading: false,
  error: false,
  // Push Notification Message
  svId: "",
  jobAppId: "",
  circulationId: "",
  assessmentId: "",
  title: "",
  body: "",
  candidateName: "",
  dateSent: moment().format("yyyy-MM-DD"),
  timeSent: moment().format("h:mm:ss a"),
  // Push Token
  pushToken: "",
  type: "",
  device: "",
  isRead: 0,
  notificationbyPushTokenLists: [],
};

const NotificationSlice = createSlice({
  name: "Notification",
  initialState,
  reducers: {},
  extraReducers: {
    // [postCirculationPushNotification.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [postCirculationPushNotification.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   // console.log(action.payload);
    // },
    // [postCirculationPushNotification.rejected]: (state, action) => {
    //   state.error = true;
    //   // console.error(action.payload);
    // },

    // [postAssessmentPushNotification.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [postAssessmentPushNotification.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   // console.log(action.payload);
    // },
    // [postAssessmentPushNotification.rejected]: (state, action) => {
    //   state.error = true;
    //   // console.error(action.payload);
    // },

    [postTestNotification.pending]: (state, action) => {
      state.loading = true;
    },
    [postTestNotification.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postTestNotification.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },

    [postTestNotificationAssessment.pending]: (state, action) => {
      state.loading = true;
    },
    [postTestNotificationAssessment.fulfilled]: (state, action) => {
      state.loading = false;
      // console.log(action.payload);
    },
    [postTestNotificationAssessment.rejected]: (state, action) => {
      state.error = true;
      // console.error(action.payload);
    },
  },
});

export const {} = NotificationSlice.actions;
export default NotificationSlice.reducer;
