export const styles = {
  CardContainer: { backgroundColor: "white", margin: "30px" },
  TitleStyle: {
    fontWeight: "bold",
    // color: "#fdedbc",
    // textShadow: `
    //   -1px -1px 0 #000,
    //    1px -1px 0 #000,
    //   -1px  1px 0 #000,
    //    1px  1px 0 #000
    // `,
  },
  ButtonStyle: {
    backgroundColor: "#fdedbc",
    fontWeight: "bold",
    color: "#000",
  },
  ButtonApproveStyle: {
    backgroundColor: "#FBC9C4",
    color: "black",
    fontWeight: "bold",
  },
  ButtonRejectStyle: {
    backgroundColor: "#F43134",
    color: "black",
    fontWeight: "bold",
  },
};
