import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { getHRApplicationListPagination } from "../../4.Store/HRTableListSlice";

const TitleApplicationList = () => {
  const dispatch = useDispatch();

  const [count, setCount] = useState(0);

  const { lists, currentPage } = useSelector((store) => store.HRTableList);
  const { status } = useSelector((state) => state.ApplicationForm);

  const calculateNewCount = () => {
    return lists.filter((item) => item.status !== 100 && item.status !== 200)
      .length;
  };

  // const calculateNewCount = () => {
  //   if (status === 100 || status === 200) {
  //     return 1;
  //   } else {
  //     return lists.filter((item) => item.status !== 100 && item.status !== 200)
  //       .length;
  //   }
  // };

  return (
    <>
      <span>Application List</span>&nbsp;
      <span
        className="badge"
        style={{ border: "1px solid white", backgroundColor: "#5E8ABB" }}
      >
        {calculateNewCount()}
      </span>
    </>
  );
};

export default TitleApplicationList;
