import { useDispatch, useSelector } from "react-redux";
import { Container, Form } from "react-bootstrap";

import {
  FormLabelControlTextFullNoIcon,
  FormLabelSelect,
} from "../../2.Common/RequiredForm";
import { YesNoList } from "../../2.Common/DropdownOptions";

import { updateSectionSix } from "../../4.Store/ApplicationFormSlice";

const SectionSix = () => {
  const dispatch = useDispatch();

  const { sectionSix } = useSelector((state) => state.ApplicationForm);
  const {
    date_to_work,
    enquiry_previos_employer,
    bankrupt,
    arrested_indicted_convicted,
  } = sectionSix;

  const handleChangeForm = (e) => {
    const entity = { ...sectionSix, [e.target.name]: e.target.value };
    dispatch(updateSectionSix(entity));
    // console.log(entity);
  };

  const handleChangeDropdown = (name, value) => {
    const entity = { ...sectionSix, [name]: value };
    switch (name) {
      case "enquiry_previos_employer":
        dispatch(updateSectionSix(entity));
        // console.log(entity);
        break;
      case "bankrupt":
        dispatch(updateSectionSix(entity));
        // console.log(entity);
        break;
      case "arrested_indicted_convicted":
        dispatch(updateSectionSix(entity));
        // console.log(entity);
        break;
    }
  };

  return (
    <Container fluid>
      <Form>
        <FormLabelControlTextFullNoIcon
          label="Date Able To Work"
          name="date_to_work"
          value={date_to_work}
          type="date"
          handleChangeForm={handleChangeForm}
        />
        <FormLabelSelect
          label="May Enquiry Be Made About You To Your Previous Employer?"
          name="enquiry_previos_employer"
          value={enquiry_previos_employer}
          options={YesNoList}
          handleChange={handleChangeDropdown}
        />
        <FormLabelSelect
          label="Are You In Default To Arrears On Repayment Of Any Debts?"
          name="bankrupt"
          value={bankrupt}
          options={YesNoList}
          handleChange={handleChangeDropdown}
        />
        <FormLabelSelect
          label="Have You Ever Been Arrested, Indicted Or Convicted?"
          name="arrested_indicted_convicted"
          value={arrested_indicted_convicted}
          options={YesNoList}
          handleChange={handleChangeDropdown}
        />
      </Form>
    </Container>
  );
};
export default SectionSix;
