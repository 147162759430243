import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faFilter,
  faSquareFull,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, InputGroup, Button, Form } from "react-bootstrap";
import { triggerBase64Download } from "common-base64-downloader-react";

import TableAssessmentList from "./Components/TableAssessmentList";
import PaginationList from "../../2.Common/Pagination";

import {
  getHRAssessmentListSearch,
  getHRAssessmentListPagination,
  updateSearchAssessmentList,
  updateCurrentHRAssessmentPage,
  postExcelFileAssessment,
} from "../../4.Store/HRTableListSlice";
import { getSupervisorList } from "../../4.Store/CirculationSlice";

const AssessmentList = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [showFilter, setShowFilter] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [positionApplied, setPositionApplied] = useState("");
  const [salaryRangeFrom, setSalaryRangeFrom] = useState(0);
  const [salaryRangeTo, setSalaryRangeTo] = useState(0);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [source, setSource] = useState("");
  const [statusApplication, setStatusApplication] = useState("");
  const [supervisors, setSupervisors] = useState([]);
  const [supervisorDropdown, setSupervisorDropdown] = useState("");
  const [column, setColumn] = useState("date");
  const [columnDirection, setColumnDirection] = useState(-1);

  const {
    assessmentList,
    currentHRAssessmentPage,
    totalCurrentHRAssessmentPage,
    searchAssessmentList,
    searchKeyWordAssessmentLists,
  } = useSelector((store) => store.HRTableList);

  const parseSearchInput = (searchText) => {
    const words = searchText.split(" ");
    const searchObject = {};

    let currentKey = "";
    let keyword = "";

    words.forEach((word) => {
      if (word.includes(":")) {
        const [key, value] = word.split(":");
        const cleanKey = key.trim();
        const cleanValue = value.trim();
        currentKey = cleanKey;
        searchObject[currentKey] = searchObject[currentKey]
          ? searchObject[currentKey] + " " + cleanValue
          : cleanValue;
      } else {
        if (currentKey === "") {
          keyword += word + " ";
        } else {
          searchObject[currentKey] += " " + word;
        }
      }
    });

    searchObject["keyword"] = keyword.trim();

    return searchObject;
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const parsedObject = parseSearchInput(searchKeyWordAssessmentLists);
      // setSearchObject(parsedObject);
      if (searchKeyWordAssessmentLists) {
        dispatch(
          getHRAssessmentListSearch({ parsedObject, column, columnDirection })
        );
      } else {
        dispatch(
          getHRAssessmentListPagination({
            pageNo: currentHRAssessmentPage,
            pageSize: 20,
            column,
            columnDirection,
          })
        );
      }
    }
  };

  const setClearKeyword = () => {
    setPositionApplied("");
    setSource("");
    setDateFrom("");
    setDateTo("");
    setSalaryRangeFrom(0);
    setSalaryRangeTo(0);
    setKeyword("");
    setStatusApplication("");

    dispatch(updateSearchAssessmentList(""));
    dispatch(
      getHRAssessmentListPagination({
        pageNo: currentHRAssessmentPage,
        pageSize: 20,
        column,
        columnDirection,
      })
    );
  };

  useEffect(() => {
    dispatch(getSupervisorList()).then((res) => {
      const arr = [];
      let result = res.payload;

      result.map((supervisor) => {
        const capitalizedSupervisorName = supervisor.name.toUpperCase();

        return arr.push({
          value: supervisor.id,
          label: capitalizedSupervisorName,
          email: supervisor.email,
        });
      });

      arr.sort((a, b) => a.label.localeCompare(b.label));

      setSupervisors(arr);
    });
  }, []);

  useEffect(() => {
    if (showFilter) {
      const parsedObject = parseSearchInput(searchKeyWordAssessmentLists);
      setKeyword(parsedObject.keyword);
      setSupervisorDropdown(parsedObject.supervisor);
      setPositionApplied(parsedObject.positionApplied);
      setSource(parsedObject.source);
      setDateFrom(parsedObject.dateFrom);
      setDateTo(parsedObject.dateTo);
      setSalaryRangeFrom(parsedObject.salaryFrom);
      setSalaryRangeTo(parsedObject.salaryTo);
      setStatusApplication(parsedObject.statusApplication);
    }
    // const handleClickOutside = (event) => {
    //   if (formRef.current && !formRef.current.contains(event.target)) {
    //     setShowFilter(false);
    //   }
    // };

    // if (showFilter) {
    //   document.addEventListener("mousedown", handleClickOutside);
    // } else {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // }

    // return () => {
    //   document.removeEventListener("mousedown", handleClickOutside);
    // };
  }, [showFilter]);

  useEffect(() => {
    const fromLSKkeywords = localStorage.getItem(searchKeyWordAssessmentLists);
    if (fromLSKkeywords) {
      dispatch(updateSearchAssessmentList(fromLSKkeywords));
    }

    if (!searchKeyWordAssessmentLists) {
      dispatch(
        getHRAssessmentListPagination({
          pageNo: currentHRAssessmentPage,
          pageSize: 20,
          column,
          columnDirection,
        })
      );
    }
  }, [currentHRAssessmentPage]);

  const handlePagination = (page) => {
    dispatch(updateCurrentHRAssessmentPage(page));
  };

  const handleClickDownloadExcel = () => {
    dispatch(postExcelFileAssessment()).then((res) => {
      const base64EXCEL =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        res.payload;
      triggerBase64Download(
        base64EXCEL,
        "Assessment List" + "_" + new Date().toISOString().slice(0, 10)
      );
    });
  };

  const handleClickFilterSearch = () => {
    const parts = [];

    if (keyword) parts.push(`${keyword}`);
    if (supervisorDropdown) parts.push(`interviewer:${supervisorDropdown}`);
    if (positionApplied) parts.push(`positionApplied:${positionApplied}`);
    if (source) parts.push(`source:${source}`);
    if (dateFrom) parts.push(`dateFrom:${dateFrom}`);
    if (dateTo) parts.push(`dateTo:${dateTo}`);
    if (salaryRangeFrom) parts.push(`salaryFrom:${salaryRangeFrom}`);
    if (salaryRangeTo) parts.push(`salaryTo:${salaryRangeTo}`);
    if (statusApplication) parts.push(`statusApplication:${statusApplication}`);

    const parsedObject = parseSearchInput(parts.join(" "));
    dispatch(
      getHRAssessmentListSearch({ parsedObject, column, columnDirection })
    );

    dispatch(updateSearchAssessmentList(parts.join(" ")));

    setShowFilter(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <InputGroup className="col-4 mb-3">
            <Form.Control
              className="form-control"
              placeholder="Search"
              onChange={({ currentTarget: input }) => {
                dispatch(updateSearchAssessmentList(input.value));
              }}
              onFocus={() => setShowFilter(false)}
              onKeyDown={handleKeyDown}
              value={searchKeyWordAssessmentLists}
            />
            <Button
              variant="outline-secondary"
              onClick={() => setClearKeyword()}
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => setShowFilter(!showFilter)}
            >
              <FontAwesomeIcon icon={faFilter} />
            </Button>

            {showFilter && (
              <form
                ref={formRef}
                className="filter-form position-absolute top-100 start-0 w-50"
                style={{ zIndex: 9999 }}
              >
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="keyword"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Keyword
                    </label>
                    <input
                      type="text"
                      id="keyword"
                      value={keyword}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="position"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Position
                    </label>
                    <input
                      type="text"
                      id="position"
                      placeholder="Enter Position"
                      value={positionApplied}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setPositionApplied(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="source"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      IV
                    </label>
                    <Form.Select
                      id="supervisorDropdown"
                      value={supervisorDropdown}
                      onChange={(e) => setSupervisorDropdown(e.target.value)}
                      className="border-0 border-bottom flex-grow-1"
                    >
                      <option></option>
                      {supervisors.map((option) => (
                        <option key={option.value} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="dateRange"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Date
                    </label>
                    <input
                      type="date"
                      id="dateRange"
                      placeholder="From Date"
                      value={dateFrom}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                    <div style={{ width: "15px" }}></div>
                    <input
                      type="date"
                      id="dateRange"
                      placeholder="To Date"
                      value={dateTo}
                      className="form-control border-0 border-bottom flex-grow-1"
                      onChange={(e) => setDateTo(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
                  <div
                    className="mb-3 d-flex align-items-center"
                    style={{ minWidth: "150px", width: "100%" }}
                  >
                    <label
                      htmlFor="source"
                      className="form-label me-2"
                      style={{ flexBasis: "75px", minWidth: "75px" }}
                    >
                      Status
                    </label>
                    <Form.Select
                      value={statusApplication}
                      onChange={(e) => setStatusApplication(e.target.value)}
                      className="border-0 border-bottom flex-grow-1"
                    >
                      <option value=""></option>
                      <option value="HIRE">HIRE</option>
                      <option value="KIV">KIV</option>
                      <option value="NOTSUITABLE">NOT SUITABLE</option>
                    </Form.Select>
                  </div>
                </div>

                <div className="mb-3 d-flex flex-row-reverse">
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleClickFilterSearch}
                  >
                    Search
                  </button>
                </div>
              </form>
            )}
          </InputGroup>
        </Col>
        <Col md="auto" sm="auto">
          {searchKeyWordAssessmentLists ? (
            <PaginationList
              nPages={1}
              currentPage={1}
              handlePagination={handlePagination}
            />
          ) : (
            <PaginationList
              nPages={totalCurrentHRAssessmentPage}
              currentPage={currentHRAssessmentPage}
              handlePagination={handlePagination}
            />
          )}
        </Col>
      </Row>

      <TableAssessmentList />

      <Row className="justify-content-md-end my-2">
        <Col md="auto" sm="auto">
          <FontAwesomeIcon
            icon={faSquareFull}
            color="#DDF4FC"
            className="fa-2xl"
          />
          &nbsp; TBD
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faDownload} color="blue" />
          &nbsp; Download Biodata Only
        </Col>
        <Col md="auto" sm="auto">
          <FontAwesomeIcon icon={faDownload} color="#E1AD01" />
          &nbsp; Download
        </Col>
      </Row>

      <Row className="justify-content-md-end my-2">
        <Col md="auto" sm="auto">
          <Button
            onClick={handleClickDownloadExcel}
            variant="outline-primary"
            className="mx-3"
          >
            Download Excel File
          </Button>
        </Col>
        <Col md="auto" sm="auto">
          {searchKeyWordAssessmentLists ? (
            <PaginationList
              nPages={1}
              currentPage={1}
              handlePagination={handlePagination}
            />
          ) : (
            <PaginationList
              nPages={totalCurrentHRAssessmentPage}
              currentPage={currentHRAssessmentPage}
              handlePagination={handlePagination}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default AssessmentList;
