import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import SVCandidateStatus from "./Components/SVCandidateStatus";
import SVHRRemark from "./Components/SVHRRemark";
import SVInterviewDetail from "./Components/SVInterviewDetail";
import SVInternshipDetail from "./Components/SVInternshipDetail";
import SVActionButtons from "./Components/SVActionButtons";

const SVFeedback = () => {
  const { sectionOne } = useSelector((state) => state.ApplicationForm);
  const { circulationStatus } = useSelector((state) => state.Circulation);

  return (
    <Container fluid>
      {/* Candidate Status */}
      <SVCandidateStatus />

      {/* HR and SV Remark */}
      <SVHRRemark />

      {/* IV / Internship Details */}
      {circulationStatus == 500 &&
        (sectionOne?.internship == "yes" ? (
          <SVInternshipDetail />
        ) : (
          <SVInterviewDetail />
        ))}

      {/* Action Btn */}
      <SVActionButtons />
    </Container>
  );
};
export default SVFeedback;
