import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Table, Image, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArrowDown,
  faDownload,
  faBoltLightning,
} from "@fortawesome/free-solid-svg-icons";

import "../SVTableList.css";

import { getAssessmentForm } from "../../../4.Store/AssessmentSlice";

const SVTableAssessmentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    assessmentBySvIdLists,
    currentSVAssessmentPage,
    totalCurrentSVAssessmentPage,
    searchAssessmentBySvIdLists,
    searchKeyWordAssessmentBySvIdLists,
  } = useSelector((store) => store.SVTableList);

  const handleClick = (_id) => {
    navigate(`/AssessmentForm/${_id}`);
    // console.log(_id);
  };

  const handleDownloadBiodata = (_id) => {
    dispatch(getAssessmentForm(_id)).then((data) => {
      // const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}/${_id}`;
      const pdfUrl = `http://192.168.5.174:5000/api/pdfs33/pdf/basic/${data?.payload?.jobAppId}`;
      window.open(pdfUrl, "_blank");
    });
  };

  const handleDownloadFullForm = (_id) => {
    dispatch(getAssessmentForm(_id)).then((data) => {
      // console.log(data);
      // const pdfUrl = `https://jobapp.hslnet.my/api/pdfs33/pdf/full/${data?.payload?.jobAppId}/${_id}`;
      const pdfUrl = `http://192.168.5.174:5000/api/pdfs33/pdf/full/${data?.payload?.jobAppId}`;
      window.open(pdfUrl, "_blank");
    });
  };

  return (
    <Container className="TableContainer" fluid>
      <Table>
        <thead className="TableHeader">
          <tr>
            <th className="ThIcon">Profile</th>
            <th className="ThText">Name</th>
            <th className="ThText">Position</th>
            <th className="ThText">RM</th>
            <th className="ThText">Interview Date</th>
            <th className="ThText">Interview Time</th>
            <th className="ThText">Status</th>
            <th className="ThIcon">
              <FontAwesomeIcon icon={faBoltLightning} title="Download" />
            </th>
          </tr>
        </thead>
        {searchKeyWordAssessmentBySvIdLists ? (
          <tbody>
            {searchAssessmentBySvIdLists.map(
              (
                { _id, jobapplication, interviewDate, interviewTime, approval },
                i
              ) => (
                <tr key={i}>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    <Image
                      src={jobapplication?.sectionOne?.image}
                      className="ProfileImage"
                    />
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.name.toUpperCase()}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.position_applied?.label ||
                      jobapplication?.sectionOne?.position_applied.toUpperCase()}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.expected_salary}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {interviewDate}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {interviewTime?.label || interviewTime}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {approval?.interviewer_recommendation || "N/A"}
                  </td>
                  <td className="TdIcon">
                    {jobapplication?.isUpdatedByCandidate == 1 ? (
                      <FontAwesomeIcon
                        type="button"
                        onClick={() =>
                          handleDownloadFullForm(_id, jobapplication)
                        }
                        icon={faFileArrowDown}
                        title="Print Form"
                        color="#E1AD01"
                        className="fa-xl"
                      />
                    ) : (
                      <FontAwesomeIcon
                        type="button"
                        onClick={() =>
                          handleDownloadBiodata(_id, jobapplication)
                        }
                        icon={faDownload}
                        title="Print Biodata"
                        color="black"
                      />
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        ) : (
          <tbody>
            {assessmentBySvIdLists.map(
              (
                { _id, jobapplication, interviewDate, interviewTime, approval },
                i
              ) => (
                <tr key={i}>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    <Image
                      src={jobapplication?.sectionOne?.image}
                      className="ProfileImage"
                    />
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.name.toUpperCase()}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.position_applied?.label ||
                      jobapplication?.sectionOne?.position_applied.toUpperCase()}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {jobapplication?.sectionOne?.expected_salary}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {interviewDate}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {interviewTime?.label || interviewTime}
                  </td>
                  <td className="TdText" onClick={() => handleClick(_id)}>
                    {approval?.interviewer_recommendation || "N/A"}
                  </td>
                  <td className="TdIcon">
                    {jobapplication?.isUpdatedByCandidate == 1 ? (
                      <FontAwesomeIcon
                        type="button"
                        onClick={() =>
                          handleDownloadFullForm(_id, jobapplication)
                        }
                        icon={faFileArrowDown}
                        title="Print Form"
                        color="#E1AD01"
                        className="fa-xl"
                      />
                    ) : (
                      <FontAwesomeIcon
                        type="button"
                        onClick={() =>
                          handleDownloadBiodata(_id, jobapplication)
                        }
                        icon={faDownload}
                        title="Print Biodata"
                        color="black"
                      />
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        )}
      </Table>
    </Container>
  );
};
export default SVTableAssessmentList;
